import { useAgreementList } from "@features/agreements/agreements.queries";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { useBruker } from "@features/bruker/queries";

import { Product } from "./queries";

export const getProductByCode = (products: Product[] | undefined, code: AvtaleProduktID) => {
    if (!products) return;
    return products?.find((product) => product.productCode === code);
};

export const useCustomerNumberCheck = () => {
    const { data } = useAgreementList();

    if (data?.agreements.length) {
        const hasNiceCustomerNumber = data.agreements.some((agreement) => agreement.coreSystem === "NICE");
        return hasNiceCustomerNumber;
    }
    return false;
};

export const useIsManaged = () => {
    const { bruker } = useBruker();

    const isManaged = !!bruker?.radgiverKontaktInfo?.beskrivelse;

    return isManaged;
};
