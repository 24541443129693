import { Link } from "react-router-dom";
import { AgreementDetails } from "src/model/gen";

interface Props {
    avtale: AgreementDetails;
}

export const PrefornyelseAntallKanEndres = ({ avtale }: Props) => {
    return (
        <>
            Ny pris og avtaledokument er synlig 35 dager før fornyelsesdato Hvis du ønsker å endre antall ansatte så kan
            du gjøre det{" "}
            <Link to={`/ansatte-antall/endre-antall/${avtale.id}`} className="jkl-link">
                her
            </Link>
            . Vær oppmerksom på at dette kan føre til ny pris og faktura.
        </>
    );
};
