import { useEffect, useState } from "react";
import { Typography } from "@components/Typography";
import { ClaimCard } from "@features/claims/components/ClaimCard";
import { ClaimsFAQ } from "@features/claims/components/ClaimsFAQ/ClaimsFAQ";
import { track, Trackingkey } from "src/tracking";
import type { ClaimOverview } from "src/model/gen";

import styles from "./ClaimsOverview.module.scss";
interface Props {
    data: Array<ClaimOverview>;
}

export const ClaimsOverview = ({ data }: Props) => {
    const [onGoingClaims] = useState(data.filter((d) => d.status !== "Avsluttet"));
    const [closedClaims] = useState(data.filter((d) => d.status === "Avsluttet"));

    useEffect(() => {
        track({
            hendelse: Trackingkey.SeListe,
            type: "Åpne skadesaker",
            antall: onGoingClaims.length,
            liste: onGoingClaims.map((d) => d.productCode)
        });

        track({
            hendelse: Trackingkey.SeListe,
            type: "Lukkede skadesaker",
            antall: closedClaims.length,
            liste: closedClaims.map((d) => d.productCode)
        });
    }, [onGoingClaims, closedClaims]);

    return (
        <>
            <section className={styles.ongoingClaims}>
                <Typography component="h2" variant="heading-2">
                    Pågående skadesaker ({onGoingClaims.length})
                </Typography>
                {onGoingClaims.length ? (
                    onGoingClaims.map((claim) => <ClaimCard key={claim.claimNumber} {...claim} />)
                ) : (
                    <Typography component="p">Du har ingen pågående skadesaker.</Typography>
                )}
            </section>
            <section className={styles.closedClaims}>
                <Typography component="h2" variant="heading-2">
                    Tidligere saker ({closedClaims.length})
                </Typography>
                {closedClaims.length ? (
                    closedClaims.map((claim) => <ClaimCard key={claim.claimNumber} {...claim} />)
                ) : (
                    <Typography component="p">Ingen ferdigbehandlede skadesaker.</Typography>
                )}
            </section>

            <section className={styles.claimsFAQSection}>
                <Typography component="h2" variant="heading-3">
                    Vanlige spørsmål om skadesaker
                </Typography>
                <ClaimsFAQ />
            </section>
        </>
    );
};
