import { useEffect, useState } from "react";
import { AgreementDetails } from "src/model/gen";
import { track, Trackingkey } from "src/tracking";

import { FeedbackProps } from "@components/GlobalFeedback";
import { StegProps } from "@components/SkjemaFlyt";
import { Typography } from "@components/Typography";
import { PrimaryButton } from "@fremtind/jkl-button-react";
import { ModalInstance } from "@fremtind/jkl-modal-react";
import { RadioButton, RadioButtonGroup } from "@fremtind/jkl-radio-button-react";
import "../AgreementChanges.scss";

interface Props extends StegProps {
    avtaleDetaljer: AgreementDetails;
    feedbackProps?: FeedbackProps;
    dialogRef?: ModalInstance;
}

export const Kvittering = (props: Props) => {
    useEffect(() => {
        props.onFerdig();
    }, [props]);
    const [selectedValue, setSelectedValue] = useState("");

    function resetForm() {
        props.onFerdig(true);
        props.dialogRef.current.hide();
        track({
            hendelse: Trackingkey.Skjemaflyt,
            flytnavn: "Endre avtale",
            handling: "ferdig",
            stegnavn: "Kvittering",
            avtale: props.avtaleDetaljer.productCode
        });
    }

    function trackFeedbackResponse(response: string) {
        track({
            hendelse: Trackingkey.TilbakemeldingJaNei,
            spørsmål: "Fikk du gjort endringene du ønsket?",
            svar: response
        });
    }

    return (
        <section className="agreement-changes-form__receipt">
            <Typography className={"agreement-changes-form__heading jkl-spacing-l--bottom"} variant="heading-3">
                Forespørsel sendt
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
                <Typography variant="body">
                    Endringene er sendt til rådgiveren din, og du har fått en kvittering på e-post. Du vil oppdateres
                    fortløpende.
                </Typography>

                <RadioButtonGroup
                    className=" agreement-changes-form__receipt__feedback"
                    legend="Fikk du gjort endringene du ønsket?"
                    name="tilbakemelding"
                    value={selectedValue}
                    onChange={(e) => {
                        setSelectedValue(e.target.value);
                        trackFeedbackResponse(e.target.value);
                    }}
                >
                    <RadioButton value={"Ja"}>Ja</RadioButton>
                    <RadioButton value={"Nei"}>Nei</RadioButton>
                </RadioButtonGroup>

                <PrimaryButton style={{ maxWidth: "fit-content" }} onClick={resetForm}>
                    Ferdig
                </PrimaryButton>
            </div>
        </section>
    );
};
