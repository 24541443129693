import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { queries, components } from "@features/navneliste";
import { Side, SideHeader, SideInnhold } from "../components/Side";
import { Steg, SkjemaFlyt, SkjemaFlytProps, FormTrackingMeta } from "../components/SkjemaFlyt";
import { Typography } from "../components/Typography";
import { track, Trackingkey } from "../tracking";

const { ChooseForsikring, ChooseInsured, MoreInfo, Receipt } = components.Utmelding;
const { queryKey, useMedlemList, useMedlemOppdateringStatus, useOppdaterMedlemmer } = queries.medlem;

type StegProps = components.Utmelding.StegProps;
type FormState = components.Utmelding.FormState;

const stegListe: Steg<StegProps>[] = [
    {
        stegnavn: "velg-medlem",
        element: (props) => (
            <ChooseInsured
                textContent={{ lead: "Du kan velge ett medlem", listInstruction: "Velg et medlem" }}
                searchFieldProps={{
                    placeholder: "Navn eller f.nr på medlem"
                }}
                backLinkTo="/medlemmer"
                {...props}
            />
        )
    },
    {
        stegnavn: "velg-forsikring",
        element: (props) => <ChooseForsikring backLinkTo="/medlemmer" {...props} />
    },
    {
        stegnavn: "mer-info",
        element: (props) => (
            <MoreInfo
                oversiktLink="/medlemmer"
                meldUtButtonProps={{
                    track: [
                        {
                            hendelse: Trackingkey.Knappetrykk,
                            knappeId: "meld-ut-mer-info-meld-ut-knapp"
                        }
                    ]
                }}
                {...props}
            />
        )
    },
    {
        stegnavn: "oppsummering",
        element: (props) => <Receipt oversiktLink="/medlemmer" {...props} />
    }
];

function MedlemUtmeldingSkjemaFlyt(
    props: Omit<StegProps, "onFrem" | "onTilbake" | "onAvbryt" | "onFerdig" | "onRestart"> & SkjemaFlytProps<StegProps>
) {
    return <SkjemaFlyt {...props} />;
}

export function MedlemUtmelding() {
    const queryClient = useQueryClient();
    const formMethods = useForm<FormState>();

    const [meta, setMeta] = useState<FormTrackingMeta>();

    const { indeks } = useParams<"indeks">();
    const insuredIndeks = formMethods.watch("insuredIndeks");
    const medlemIndeks = indeks || insuredIndeks;

    const mutation = useOppdaterMedlemmer();
    const medlemmerQuery = useMedlemList();
    const oppdateringStatusQuery = useMedlemOppdateringStatus(medlemIndeks);

    useEffect(() => {
        if (indeks) {
            formMethods.setValue("insuredIndeks", indeks);
        }
    }, [formMethods, indeks]);

    return (
        <Side>
            <SideHeader title="Meld ut medlem" />
            <SideInnhold
                loadingText="Henter liste over medlemmer"
                query={medlemmerQuery}
                forceLoader={medlemmerQuery.isFetching}
                footerProps={{ feedbackProps: { formFlowName: meta?.flowName, formStepName: meta?.stepName } }}
            >
                {medlemmerQuery.data?.entity.medlemmer.length ? (
                    <MedlemUtmeldingSkjemaFlyt
                        flytnavn="meld-ut-medlem"
                        formMethods={formMethods}
                        setFormTrackingMeta={setMeta}
                        stegListe={stegListe.slice(indeks ? 1 : 0)}
                        insuredList={medlemmerQuery.data.entity.medlemmer}
                        availableAvtaler={
                            oppdateringStatusQuery.isFetching ? undefined : oppdateringStatusQuery.data?.avtaler
                        }
                        mutation={mutation}
                        onMutationSuccess={(data) => {
                            if (!data.feilkoder.length) {
                                queryClient.setQueryData(queryKey.list(), {
                                    entity: data.entity.oversiktDto
                                });
                            }

                            track({
                                hendelse: Trackingkey.Endre,
                                handling: "utmelding",
                                type: "meld ut medlem"
                            });
                        }}
                    />
                ) : (
                    <Typography>Ingen medlemmer er meldt inn enda...</Typography>
                )}
            </SideInnhold>
        </Side>
    );
}
