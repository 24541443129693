import { Suspense } from "react";
import { Await, defer, LoaderFunctionArgs, Navigate, redirect, useLoaderData } from "react-router-dom";
import { Side, SideInnhold } from "src/components/Side";
import { queryClient } from "src/common/queryClient";
import { setValgtOrganisasjon } from "@features/bruker/queries";
import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";
import "@features/agreements/views/AgreementsOverview/AgreementsOverviewComposer.scss";
import { Loader } from "@fremtind/jkl-loader-react";
import { getAvailableProducts, Product } from "../../../../components/KjopForsikring";
import { Bruker } from "../../../../model/bruker.typer";
import { UventetFeil } from "../../../../components/Feil";
import { registerTrackingProps, track, Trackingkey } from "../../../../tracking";
import { Flag, useFeatureflag } from "../../../../common/featureflag";

export const loader = async ({ params }: LoaderFunctionArgs) => {
    if (!params.organisasjonsnummer) {
        return redirect("/hjem");
    }

    const bruker = await queryClient.ensureQueryData<Bruker>({ queryKey: brukerQueryKey, queryFn: fetchBruker });
    const availableproductslist = await getAvailableProducts();

    async function trackOrganization() {
        track({
            hendelse: Trackingkey.OrganisasjonsBytte,
            gammelOrganisasjon: bruker.gjeldendeOrganisasjonsnummer || "",
            nyOrganisasjon: params.organisasjonsnummer!,
            kanKjopeProdukter: availableproductslist
                ? availableproductslist.map((product: Product) => product.productCode)
                : []
        });

        Promise.resolve();
    }

    const setChosenOrganisationPromise = setValgtOrganisasjon({ organisasjonsnummer: params.organisasjonsnummer });

    setChosenOrganisationPromise.then((data) => {
        registerTrackingProps({
            gjeldendeOrganisasjon: params.organisasjonsnummer
        });

        queryClient.clear();

        queryClient.setQueryData(brukerQueryKey, data);
        trackOrganization();
    });

    return defer({ setChosenOrganisationPromise });
};

export function ByttOrganisasjon() {
    const loaderData = useLoaderData() as { setChosenOrganisationPromise: Promise<unknown> };
    const tilgangsbasenIsUpgrading = useFeatureflag(Flag.FEILSIDE_OPPGRADERING_TILGANGSBASEN_NEDE);

    return (
        <Side>
            <SideInnhold hideFooter={tilgangsbasenIsUpgrading}>
                <Suspense fallback={<Loader textDescription="Henter forside" />}>
                    <Await
                        resolve={loaderData.setChosenOrganisationPromise}
                        errorElement={<UventetFeil errorToLog={null} />}
                    >
                        <Navigate to="/hjem" replace />
                    </Await>
                </Suspense>
            </SideInnhold>
        </Side>
    );
}
