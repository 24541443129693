import { useState } from "react";
import { ExpandSection } from "@fremtind/jkl-expand-button-react";
import { ListItem, OrderedList } from "@fremtind/jkl-list-react";
import { Link } from "@fremtind/jkl-core";
import { Typography } from "../../../../components/Typography";

interface Props {
    closedText?: string;
    openText?: string;
    className?: string;
}

export function RRHExpandableLovkrav({ closedText = "Les mer", openText = "Skjul tekst", className }: Props) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <ExpandSection
            title={isExpanded ? openText : closedText}
            onClick={(_e, expanded) => {
                setIsExpanded(expanded);
            }}
            className={className}
        >
            <Typography variant="heading-3" className="jkl-spacing-xs--bottom">
                For bedrifter
            </Typography>

            <Typography className="jkl-spacing-l--bottom">
                Reelle rettighetshavere for bedrifter er personer som, alene eller sammen med nær familie, eier eller
                kontrollerer mer enn 25 % av eierandelene eller stemmene i bedriften. Nær familie defineres her som
                foreldre, ektefelle, registrert partner, samboer og barn, samt barns ektefelle eller registrert
                partner/samboer. I eierskapsprosenten skal det tas hensyn til både direkte og indirekte rettighetshavere
                (rettighetshavere gjennom flere ledd).
            </Typography>

            <Typography>Kontrollerer defineres i loven som at man:</Typography>

            <OrderedList className="jkl-spacing-xl--bottom">
                <ListItem>
                    Eier mer enn 25 prosent av eierandelene i den juridiske personen eller sammenslutningen.
                </ListItem>
                <ListItem>
                    På grunn av innehav av aksjer, andeler eller medlemskap kontrollerer mer enn 25 prosent av det
                    totale antallet stemmer i den juridiske personen eller sammenslutningen.
                </ListItem>
                <ListItem>
                    Har rett til å utpeke eller avsette mer enn halvparten av den juridiske personens eller
                    sammenslutningens styremedlemmer eller tilsvarende.
                </ListItem>
                <ListItem>
                    På grunn av avtale med eiere, medlemmer, den juridiske personen eller sammenslutningen, vedtekter
                    eller tilsvarende, kan utøve kontroll i samsvar med bokstav a, b eller c.
                </ListItem>
                <ListItem>På annen måte utøver kontroll over den juridiske personen eller sammenslutningen.</ListItem>
            </OrderedList>

            <Typography variant="heading-3" className="jkl-spacing-xs--bottom">
                For stiftelser
            </Typography>

            <Typography>
                Når du skal identifisere reelle rettighetshavere i stiftelser, gjelder ifølge loven:
            </Typography>

            <OrderedList className="jkl-spacing-xl--bottom">
                <ListItem>
                    Dersom en fysisk person er styremedlem eller daglig leder i stiftelsen, er vedkommende reell
                    rettighetshaver.
                </ListItem>
                <ListItem>
                    Dersom en fysisk person uttrykkelig er angitt til å utpeke et flertall av styremedlemmene i
                    stiftelsen, er vedkommende reell rettighetshaver. Dersom en juridisk person uttrykkelig er angitt
                    til å utpeke et flertall av styremedlemmene i stiftelsen, er reelle rettighetshavere i den juridiske
                    personen også reelle rettighetshavere i stiftelsen.
                </ListItem>
                <ListItem>
                    Dersom en fysisk person har avgitt grunnkapital til stiftelsen, er vedkommende reell
                    rettighetshaver. Dersom andre enn fysiske personer har avgitt grunnkapital til stiftelsen, er reelle
                    rettighetshavere i avgiveren også reelle rettighetshavere i stiftelsen.
                </ListItem>
                <ListItem>
                    Dersom en fysisk person er tilstått særlige rettigheter i medhold av stiftelsesloven § 9 første ledd
                    bokstav d, er vedkommende reell rettighetshaver. Dersom andre enn fysiske personer er tilstått
                    særlige rettigheter i medhold av stiftelsesloven § 9 første ledd bokstav d, er reelle
                    rettighetshavere i den som er tilstått særlige rettigheter, også reelle rettighetshavere i
                    stiftelsen.
                </ListItem>
                <ListItem>
                    Dersom en fysisk person uttrykkelig er angitt som destinatar, er vedkommende reell rettighetshaver.
                    Dersom andre enn fysiske personer uttrykkelig er angitt som destinatar, er reelle rettighetshavere i
                    destinataren også reelle rettighetshavere i stiftelsen.
                </ListItem>
            </OrderedList>

            <Typography variant="heading-3" className="jkl-spacing-xs--bottom">
                For lag og foreninger
            </Typography>

            <Typography className="jkl-spacing-l--bottom">
                For kunder som ikke har eierandeler, for eksempel lag og foreninger, vil det sjelden finnes reelle
                rettighetshavere og det vil derfor ikke være aktuelt å rapportere inn noen personer som reelle
                rettighetshavere. Dersom det likevel finnes en person som eier eller kontrollerer midlene, må denne
                personen registreres hos oss.
            </Typography>

            <Typography>
                Du kan lese mer om reelle rettighetshavere i{" "}
                <Link
                    href="https://lovdata.no/lov/2018-06-01-23/%C2%A714"
                    target="_blank"
                    rel="noreferrer noopener"
                    external
                >
                    hvitvaskingslovens §14
                </Link>{" "}
                hos Lovdata.
            </Typography>
        </ExpandSection>
    );
}
