import { format, isAfter, isValid, parse, subYears } from "date-fns";
import { AvtaleProduktID } from "../features/agreements/avtale.model";
import { isParisAvtale } from "../features/agreements/avtale.utils";
import { SammendragContent } from "./common.typer";

export function asDate(date: string | Date) {
    if (typeof date === "string") {
        if (isValid(parse(date, "dd.MM.yyyy", new Date()))) {
            // datepicker format
            return parse(date, "dd.MM.yyyy", new Date());
        }

        // backend format
        return parse(date, "yyyy-MM-dd", new Date());
    } else {
        return date;
    }
}

export const toNorwegianDateFormat = (source: Date | string | undefined, fullYear?: boolean) => {
    if (!source) {
        return null;
    }

    return format(asDate(source), `dd.MM.${fullYear ? "yyyy" : "yy"}`);
};

export const toBackendDateFormat = (source: Date | string) => {
    return format(asDate(source), "yyyy-MM-dd");
};

export const toNorwegianBooleanFormat = (source: string) => {
    return source === "true" ? "Ja" : "Nei";
};

export const getFodselsdatoFromFodselsnummer = (source: string) => {
    let fodselsdato = parse(source.substring(0, 6), "ddMMyy", new Date());

    if (isAfter(fodselsdato, new Date())) {
        fodselsdato = subYears(fodselsdato, 100);
    }

    return fodselsdato;
};

export const toFormattedOrganisasjonsnummer = (source: string) => {
    if (source) {
        return source.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ");
    }
    return "";
};

export const toCapitalizedLowerCase = (source: string, severalWords = false) => {
    return severalWords
        ? source.toLowerCase().replace(/(?:^|[\s-])\w/g, (match) => {
              return match.toUpperCase();
          })
        : source.charAt(0).toUpperCase() + source.slice(1).toLowerCase();
};

// Soft hyphen: brukes i string literals hvor vi har lange ord, for å indikere hvor vi ønsker at ordet skal knekke
export const shy = String.fromCharCode(173);

export const toSammendragContent = (
    title: string,
    content?: string | number | object,
    Node?: () => JSX.Element
): SammendragContent => {
    return { title, content, Node };
};

export const stringIsNotUndefined = (arg: string | undefined): arg is string => {
    return arg !== undefined;
};

export const flertall = (input: string, antall: number, flertall = "er") => {
    if (antall !== 0) {
        return (input += flertall);
    }

    return input;
};

export const formatAvtalenummer = (avtalenummer: string, produktkode: AvtaleProduktID) => {
    // PARIS avtaler inneholder årstall, men skal vises uten
    if (isParisAvtale(produktkode)) {
        return avtalenummer.substring(4);
    }

    return avtalenummer;
};
