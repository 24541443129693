import "./TTPOversikt.scss";

import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jklS } from "src/common/styles";

import { css } from "@emotion/react";
import { Accordion as JklAccordion } from "@fremtind/jkl-accordion-react";
import { formatFodselsnummer } from "@fremtind/jkl-formatters-util";
import { InfoMessageBox } from "@fremtind/jkl-message-box-react";
import { usePreviousValue } from "@fremtind/jkl-react-hooks";
import { Tab, TabList, TabPanel, Tabs } from "@fremtind/jkl-tabs-react";

import { toFormattedOrganisasjonsnummer, toNorwegianDateFormat } from "../../../../common/formatting";
import { PrimaryLinkButton, SecondaryLinkButton } from "../../../../components/Button";
import { SearchField } from "../../../../components/SearchField";
import { Typography } from "../../../../components/Typography";
import { track, Trackingkey } from "../../../../tracking";
import { TilgangerTredjePart } from "../../models";
import { TTPAccordionItem } from "./TTPAccordionItem";

const searchFilter = (keywords: string[]) => (match: string) => {
    if (!match) return true;

    return keywords.some((keyword) =>
        match
            .split(" ")
            .filter(Boolean)
            .some((matchPart) => keyword.toLowerCase().includes(matchPart.toLowerCase()))
    );
};

interface ContentProps {
    children: ReactNode;
}

const Content = ({ children }: ContentProps) => {
    return <div>{children}</div>;
};

const Lead = (props: { children: string }) => <Typography className="ttp-oversikt__lead">{props.children}</Typography>;

const ListHeader = (props: { children: string }) => (
    <Typography variant="heading-1" className="jkl-spacing-l--bottom">
        {props.children}
    </Typography>
);

const Accordion = (props: { children: ReactNode }) => <JklAccordion>{props.children}</JklAccordion>;

interface TopContentProps {
    emptyListContent?: ReactNode;
    itemCount?: number;
    leadText: string;
    searchPlaceholder: string;
    match: string;
    setMatch: React.Dispatch<React.SetStateAction<string>>;
    children: ReactNode;
    activeView: "bedrifter" | "bedriftsansvarlige";
    filteredItemsLength: number;
}

const TopContent = ({ setMatch, ...props }: TopContentProps) => {
    useEffect(() => {
        return () => {
            setMatch("");
        };
    }, [setMatch]);

    const wrapperStyles = css`
        display: flex;
        justify-content: space-between;
        margin-top: ${jklS(24)};
    `;

    const buttonWrapperStyles = css`
        ${wrapperStyles};
        justify-content: flex-start;
        margin-bottom: ${jklS(32)};
        flex-wrap: wrap;
        gap: ${jklS(16)};
    `;

    return (
        <div>
            <div css={wrapperStyles}>
                <div>
                    {props.itemCount === 0 ? props.emptyListContent : <Lead>{props.leadText}</Lead>}
                    {props.activeView === "bedriftsansvarlige" && (
                        <div css={buttonWrapperStyles}>
                            <SecondaryLinkButton to="ny-bedriftsansvarlig" lenkeId="ny tilgang bedriftsansvarlig">
                                Ny bedriftsansvarlig
                            </SecondaryLinkButton>
                            <SecondaryLinkButton to="fjern-bedriftsansvarlig" lenkeId="fjern tilgang bedriftsansvarlig">
                                Fjern bedriftsansvarlig
                            </SecondaryLinkButton>
                        </div>
                    )}
                    <SearchField
                        className="ttp-oversikt__søk"
                        match={props.match}
                        setMatch={setMatch}
                        matchedCount={props.filteredItemsLength}
                        placeholder={props.searchPlaceholder}
                        onBlur={() => {
                            if (props.match !== "") {
                                track({
                                    hendelse: Trackingkey.TredjepartSok,
                                    visning: props.activeView,
                                    treff: props.filteredItemsLength
                                });
                            }
                        }}
                        dataTestautoid="ttp-search-field"
                    />
                </div>
            </div>

            <div>{props.children}</div>
        </div>
    );
};

type TilgjengeligeView = "bedrifter" | "bedriftsansvarlige";
const viewTabMap: TilgjengeligeView[] = ["bedrifter", "bedriftsansvarlige"];

const lagreTabClick = (fraVisning: TilgjengeligeView, tilVisning: TilgjengeligeView) => {
    track({
        hendelse: Trackingkey.TredjepartVisning,
        fraVisning,
        tilVisning
    });
};

export const TTPOversikt = ({ tilganger: { bedrifter, kamer } }: { tilganger: TilgangerTredjePart }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [activeTabIndex, setActiveTabIndex] = useState<number>(
        !Number.isNaN(Number(searchParams.get("tabIndex"))) ? Number(searchParams.get("tabIndex")) : 0
    );

    const previousTab = usePreviousValue(activeTabIndex);

    useEffect(() => {
        if (previousTab !== undefined && previousTab !== activeTabIndex) {
            lagreTabClick(viewTabMap[previousTab ?? 0], viewTabMap[activeTabIndex]);
        }
    }, [activeTabIndex, previousTab]);

    const [match, setMatch] = useState("");

    const matchedBedrifter = bedrifter.filter(({ bedrift }) =>
        searchFilter([
            bedrift.navn,
            bedrift.organisasjonsnummer,
            toFormattedOrganisasjonsnummer(bedrift.organisasjonsnummer)
        ])(match)
    );

    const matchedKamer = kamer.filter(({ kam }) =>
        searchFilter([kam.fornavn, kam.etternavn, kam.fodselsnummer, toNorwegianDateFormat(kam.fodselsdato)!])(match)
    );

    /** Dette er gyldig JS >:(. Burde tenke på hvordan dette kan løses på komponent nivå i Jøkul. */
    useEffect(() => {
        const tabIndex = searchParams.get("tabIndex");

        if (tabIndex) {
            const tab = document.querySelectorAll('[role="tab"]')[Number(tabIndex)] as HTMLButtonElement;

            tab.click();
        }
    }, [searchParams]);

    track({
        hendelse: Trackingkey.SeTilganger,
        type: "annen organisasjon",
        personer: kamer.length,
        organisasjoner: bedrifter.length
    });

    return (
        <Tabs
            className="ttp-oversikt"
            onChange={(e) => {
                setActiveTabIndex;
                track({
                    hendelse: Trackingkey.Tab,
                    tab: ["Bedrifter", "Bedriftsansvarlige"][e],
                    tilgjengeligeTabs: ["Bedrifter", "Bedriftsansvarlige"]
                });
            }}
        >
            <TabList className="ttp-oversikt__tablist" aria-label="Velg visning">
                <Tab>Bedrifter</Tab>
                <Tab>Bedriftsansvarlige</Tab>
            </TabList>
            <TabPanel>
                <Content>
                    <TopContent
                        activeView="bedrifter"
                        itemCount={kamer.length}
                        filteredItemsLength={matchedBedrifter.length}
                        emptyListContent={
                            <InfoMessageBox
                                title="Ingen bedriftsansvarlige."
                                css={css`
                                    margin-bottom: ${jklS(40)};
                                    margin-top: ${jklS(16)};
                                `}
                            >
                                Du har ikke opprettet noen bedriftsansvarlige. Det kan du gjøre ved å trykke på
                                bedriftsansvarlige på fanen over.
                            </InfoMessageBox>
                        }
                        leadText="Her ser du bedriftene som har gitt dere tilgang og du kan gi og fjerne tilganger til
                                registrerte bedriftsansvarlige."
                        searchPlaceholder="Navn eller org.nr på bedrift"
                        {...{ match, setMatch }}
                    >
                        <ListHeader>Bedrifter</ListHeader>
                    </TopContent>

                    <Accordion>
                        {matchedBedrifter.length ? (
                            matchedBedrifter.map((bedrift) => (
                                <TTPAccordionItem
                                    key={bedrift.bedrift.organisasjonsnummer}
                                    listHeader={{
                                        title: "Bedriftsansvarlige",
                                        action: () =>
                                            navigate(`gi-tilgang-bedrift/${bedrift.bedrift.organisasjonsnummer}`)
                                    }}
                                    titleProps={{
                                        title: bedrift.bedrift.navn,
                                        subtitle: toFormattedOrganisasjonsnummer(bedrift.bedrift.organisasjonsnummer),
                                        endText: `${bedrift.kamer.length} bedriftsansvarlig${
                                            bedrift.kamer.length === 1 ? "" : "e"
                                        }`,
                                        itemCount: bedrift.kamer.length
                                    }}
                                    listItems={bedrift.kamer.map((kam) => ({
                                        bedrift,
                                        kam,
                                        visning: "bedrift"
                                    }))}
                                />
                            ))
                        ) : (
                            <Typography>Ingen bedrifter passer søket ditt</Typography>
                        )}
                    </Accordion>
                </Content>
            </TabPanel>

            <TabPanel>
                <Content>
                    {kamer.length === 0 ? (
                        <>
                            <Typography
                                css={css`
                                    margin-bottom: ${jklS(40)};
                                    margin-top: ${jklS(24)};
                                `}
                            >
                                Du har ikke registrert noen bedriftsansvarlige. Velg{" "}
                                <span style={{ fontStyle: "italic" }}>Ny bedriftsansvarlig</span> for å legge til en ny.
                            </Typography>
                            <PrimaryLinkButton to="ny-bedriftsansvarlig">Ny bedriftsansvarlig</PrimaryLinkButton>
                        </>
                    ) : (
                        <>
                            <TopContent
                                activeView="bedriftsansvarlige"
                                leadText="Her ser du registrerte bedriftsansvarlige. Du kan registrere nye eller fjerne bedriftsansvarlige og styre hvilke bedrifter de skal ha tilgang til."
                                searchPlaceholder="Navn eller fødselsdato"
                                filteredItemsLength={matchedKamer.length}
                                {...{ match, setMatch }}
                            >
                                <div className="ttp-oversikt__header--bedriftsansvarlige">
                                    <ListHeader>Bedriftsansvarlige</ListHeader>
                                </div>
                            </TopContent>

                            <Accordion>
                                {matchedKamer.length ? (
                                    matchedKamer.map((kam) => (
                                        <TTPAccordionItem
                                            key={kam.kam.fodselsnummer}
                                            listHeader={{
                                                title: "Bedrifter",
                                                action: () =>
                                                    navigate(`gi-tilgang-bedriftsansvarlig/${kam.kam.fodselsnummer}`)
                                            }}
                                            titleProps={{
                                                title: `${kam.kam.fornavn} ${kam.kam.etternavn}`,
                                                subtitle: formatFodselsnummer(kam.kam.fodselsnummer),
                                                endText: `${kam.bedrifter.length} bedrift${
                                                    kam.bedrifter.length === 1 ? "" : "er"
                                                }`,
                                                itemCount: kam.bedrifter.length
                                            }}
                                            listItems={Array.from(kam.bedrifter).map((bedrift) => ({
                                                bedrift,
                                                kam,
                                                visning: "bedriftsansvarlig"
                                            }))}
                                        />
                                    ))
                                ) : (
                                    <Typography>Ingen bedriftsansvarlige passer søket ditt</Typography>
                                )}
                            </Accordion>
                        </>
                    )}
                </Content>
            </TabPanel>
        </Tabs>
    );
};
