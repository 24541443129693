import { ChangeEventHandler, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox } from "@fremtind/jkl-checkbox-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { RadioButton } from "@fremtind/jkl-radio-button-react";
import { FieldGroup, FieldGroupProps } from "@fremtind/jkl-input-group-react";
import { FieldError, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { searchByRegex } from "src/common/utils";
import { Select } from "@fremtind/jkl-select-react";
import { Typography } from "../Typography";
import { SkjemaFooter } from "../SkjemaFooter";
import { SearchField } from "../SearchField";
import "./ChooseFromList.scss";

interface OptionLabelProps {
    label: string;
    extraLabel?: string;
}

interface Option extends OptionLabelProps {
    value: string;
}

interface BaseProps {
    title: string;
    subtitle?: string | ReactNode;
    instruction: string;
    options: Option[];
    children: ReactNode;
    onSubmit: (data: ChooseFromListFormData) => void;
    error?: {
        title: string;
        body: string;
    };
    withSearch?: {
        placeholder: string;
        noMatchText: string;
    };
    noOptionsContent?: ReactNode;
    required?: string;
}

export interface ChooseFromListProps extends BaseProps {
    multiple?: boolean;
    selectAll?: boolean;
    labelprops?: FieldGroupProps["labelProps"];
}

export interface ChooseFromListWithSelectProps extends BaseProps {
    asSelect: true;
}

export interface ChooseFromListFormData {
    checked: string | string[] | null;
}

const showAsSelect = (d: ChooseFromListProps | ChooseFromListWithSelectProps): d is ChooseFromListWithSelectProps => {
    if ((d as ChooseFromListWithSelectProps).asSelect) {
        return true;
    }

    return false;
};

const OptionContent = ({ label, extraLabel }: OptionLabelProps) => (
    <>
        <Typography component="span">{label}</Typography>
        {extraLabel && (
            <Typography component="span" className="jkl-spacing-xs--left">
                {extraLabel}
            </Typography>
        )}
    </>
);

const getFirstError = (errors: FieldError | FieldError[] | undefined) => {
    if (Array.isArray(errors)) {
        return errors[0];
    }

    return errors;
};

export function ChooseFromList(props: ChooseFromListProps | ChooseFromListWithSelectProps) {
    const { register, handleSubmit, watch, reset, formState, setValue } = useForm<ChooseFromListFormData>({
        defaultValues: {
            checked: props.options.length === 1 ? [props.options[0].value] : []
        }
    });

    const [matchedOptions, setMatchedOptions] = useState<string[]>([]);
    const [match, setMatch] = useState("");

    const checked = watch("checked");

    useEffect(() => {
        if (props.options.length === 1) {
            reset({ checked: [props.options[0].value] });
        }

        setMatchedOptions(props.options.map((option) => option.value));
    }, [props.options, reset]);

    const formattedOptions = useMemo(() => {
        const sortedOptions = props.options.sort((a, b) => {
            return a.label.localeCompare(b.label);
        });
        return sortedOptions.map((option) => {
            return {
                ...option,
                label: `${option.label} ${option.extraLabel}`
            };
        });
    }, [props.options]);

    const debouncedFn = useRef(
        debounce((match: string) => {
            setMatchedOptions(
                props.options
                    .filter(({ label, extraLabel = "", value }) => {
                        return checked && !checked.includes(value) && searchByRegex([label, extraLabel, value], match);
                    })
                    .map((option) => option.value)
            );
        }, 100)
    );

    const onSelectAll: ChangeEventHandler<HTMLInputElement> = (e) => {
        let alreadyCheckedOptions = checked;

        if (!Array.isArray(alreadyCheckedOptions)) {
            if (alreadyCheckedOptions === null) {
                alreadyCheckedOptions = [];
            }

            if (typeof alreadyCheckedOptions === "string") {
                alreadyCheckedOptions = [alreadyCheckedOptions];
            }
        }

        if (e.target.checked) {
            setValue("checked", [...new Set([...matchedOptions, ...alreadyCheckedOptions])]);
        } else {
            setValue("checked", []);
        }
    };

    const handleMatchChange = (match: string) => {
        setMatch(match);

        debouncedFn.current?.cancel();

        debouncedFn.current?.(match);
    };

    return (
        <form onSubmit={handleSubmit(props.onSubmit)} className="choose-from-list">
            <div className="jkl-spacing-xl--bottom">
                <Typography variant="heading-3" className="jkl-spacing-xs--bottom">
                    {props.title}
                </Typography>
                {props.subtitle && <Typography>{props.subtitle}</Typography>}
            </div>

            {!props.options.length && props.noOptionsContent ? (
                props.noOptionsContent
            ) : (
                <>
                    {showAsSelect(props) ? (
                        <>
                            <Select
                                searchable={!!props.withSearch && handleSearch}
                                label={props.instruction}
                                data-testautoid="choose-from-select-search"
                                items={formattedOptions}
                                errorLabel={getFirstError(formState.errors.checked)?.message}
                                width="466px"
                                {...register("checked", { required: props.required })}
                            />
                        </>
                    ) : (
                        <>
                            {props.withSearch && (
                                <SearchField
                                    match={match}
                                    setMatch={handleMatchChange}
                                    className="choose-from-list__search"
                                    placeholder={props.withSearch.placeholder}
                                    matchedCount={matchedOptions.length}
                                    dataTestautoid="choose-from-list-search"
                                />
                            )}

                            <FieldGroup
                                labelProps={props.labelprops ?? { srOnly: true }}
                                legend={props.instruction}
                                errorLabel={getFirstError(formState.errors.checked)?.message}
                                className={`choose-from-list__options ${
                                    props.multiple && props.selectAll ? "choose-from-list__options--select-all" : ""
                                }`}
                            >
                                {props.multiple && props.selectAll && (
                                    <Checkbox
                                        value="yes"
                                        name="select-all"
                                        onChange={onSelectAll}
                                        checked={matchedOptions.every((matchOption) => {
                                            if (Array.isArray(checked)) {
                                                return checked.includes(matchOption);
                                            }

                                            return false;
                                        })}
                                    >
                                        Velg alle
                                    </Checkbox>
                                )}
                                {props.options.map((optionProps) => {
                                    const hidden =
                                        !matchedOptions.includes(optionProps.value) &&
                                        !checked?.includes(optionProps.value);

                                    return (
                                        <div hidden={hidden} key={optionProps.value}>
                                            {props.multiple ? (
                                                <Checkbox
                                                    {...register("checked", { required: props.required })}
                                                    hidden={hidden}
                                                    value={optionProps.value}
                                                >
                                                    <OptionContent
                                                        {...{
                                                            label: optionProps.label,
                                                            extraLabel: optionProps.extraLabel
                                                        }}
                                                    />
                                                </Checkbox>
                                            ) : (
                                                <RadioButton
                                                    value={optionProps.value}
                                                    hidden={hidden}
                                                    {...register("checked", { required: props.required })}
                                                    label={
                                                        <OptionContent
                                                            {...{
                                                                label: optionProps.label,
                                                                extraLabel: optionProps.extraLabel
                                                            }}
                                                        />
                                                    }
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </FieldGroup>

                            {!matchedOptions.length && (
                                <Typography className="jkl-spacing-l--top">{props.withSearch?.noMatchText}</Typography>
                            )}
                        </>
                    )}

                    {props.error && (
                        <ErrorMessageBox className="jkl-spacing-l--top" title={props.error.title}>
                            {props.error.body}
                        </ErrorMessageBox>
                    )}

                    <SkjemaFooter>{props.children}</SkjemaFooter>
                </>
            )}
        </form>
    );
}

function handleSearch(value: string, searchItem: string | Option) {
    const strip = (string: string) => string.toLowerCase().replace(/\s/g, "");

    const strippedValue = strip(value);

    if (typeof searchItem === "string") {
        const strippedItem = strip(searchItem);
        return strippedItem.includes(strippedValue);
    }

    const strippedLabel = strip(searchItem.label);
    return strippedLabel.includes(strippedValue);
}
