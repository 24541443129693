import { Side, SideHeader, SideInnhold } from "src/components/Side";
import { Typography } from "@components/Typography";

export function Administrerforsikring() {
    return (
        <Side>
            <SideHeader title="Administrer forsikring" />
            <SideInnhold className="bm-getinsurance">
                <section className="bm-getinsurance__selfservice jkl-spacing-64--bottom">
                    <Typography variant="heading-2" className="jkl-spacing-l--bottom">
                        Administrer forsikring..
                    </Typography>
                </section>
            </SideInnhold>
        </Side>
    );
}
