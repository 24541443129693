import "./App.scss";

import React, { StrictMode } from "react";
import { RequestHandler } from "msw";
import { setupWorker } from "msw/browser";
import { createRoot } from "react-dom/client";

import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    RouterProvider,
    useLocation,
    useNavigationType
} from "react-router-dom";

import { initTabListener } from "@fremtind/jkl-core";
import setupLocatorUI from "@locator/runtime";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { queryClient } from "./common/queryClient";
import { ThemeContextProvider } from "./context/ThemeContext";
import { ScreenProvider } from "./context/UseScreenContext";
import { Theme } from "./model/theme.typer";
import { routeConfig } from "./sider";

if (import.meta.env.DEV === true) {
    Sentry.init({
        dsn: "https://85fc665c58967b5f6c101cd377af5204@o4506218797006848.ingest.sentry.io/4506585773834240",
        environment: "development",
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            })
        ],
        tracesSampleRate: 0.1,
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/]
    });
    setupLocatorUI();

    // mock responses
    // const handlers: RequestHandler<any>[] = [];
    const handlers: RequestHandler[] = [];
    const worker = setupWorker(...handlers);
    worker.start();
}

if (import.meta.env.PROD === true) {
    Sentry.init({
        dsn: "https://85fc665c58967b5f6c101cd377af5204@o4506218797006848.ingest.sentry.io/4506585773834240",
        environment: "production",
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            }),
            Sentry.replayIntegration()
        ],
        tracesSampleRate: 0.1,
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        replaysSessionSampleRate: 0.15,
        replaysOnErrorSampleRate: 1.0
    });
}

initTabListener();
//const root = createRoot(document.getElementById("root") as HTMLElement);
//let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const el = document.getElementById("root");
if (el === null) throw new Error("Root container missing in index.html");

const root = createRoot(el);

const router = createBrowserRouter(routeConfig, { basename: "/bedrift" });

export const start = (theme: Theme) =>
    root.render(
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <ScreenProvider>
                    <ThemeContextProvider value={theme}>
                        <RouterProvider router={router} />
                    </ThemeContextProvider>
                </ScreenProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </StrictMode>
    );
