import React, { createContext, useCallback, useContext, useReducer } from "react";
import { nanoid } from "nanoid";
import { AnimatePresence, motion } from "framer-motion";
import {
    ErrorMessageBox,
    InfoMessageBox,
    MessageBoxProps,
    SuccessMessageBox,
    WarningMessageBox
} from "@fremtind/jkl-message-box-react";
import type { Severity, ToastType } from "./toast.d";

const messageBoxMap: Record<Severity, React.FC<MessageBoxProps>> = {
    info: InfoMessageBox,
    success: SuccessMessageBox,
    error: ErrorMessageBox,
    warning: WarningMessageBox
};

interface ContextToast extends ToastType {
    id: string;
}

interface ToastContextType {
    toasts: ContextToast[];
    addToast: (toast: Omit<ToastType, "visible">) => void;
    closeToast: (id: string) => void;
}

const ToastContext = createContext<ToastContextType | null>(null);

interface AddToastAction {
    type: "ADD_TOAST";
    toast: ContextToast;
}

interface CloseToastAction {
    type: "CLOSE_TOAST";
    id: string;
}

type Action = AddToastAction | CloseToastAction;

type State = ContextToast[];

const toastReducer = (state: State, action: Action) => {
    switch (action.type) {
        case "ADD_TOAST":
            return [...state, action.toast];

        case "CLOSE_TOAST":
            return state.filter((s) => s.id !== action.id);
    }
};

export const ToastContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [toasts, dispatch] = useReducer(toastReducer, []);

    const addToast = useCallback(
        (toast: Omit<ToastType, "visible">) => {
            dispatch({
                type: "ADD_TOAST",
                toast: {
                    ...toast,
                    visible: true,
                    id: nanoid()
                }
            });
        },
        [dispatch]
    );

    const closeToast = useCallback(
        (id: string) => {
            dispatch({ type: "CLOSE_TOAST", id });
        },
        [dispatch]
    );

    return (
        <ToastContext.Provider value={{ toasts, addToast, closeToast }}>
            {children}
            <div className="toast-track">
                <ul>
                    <AnimatePresence>
                        {toasts.map(({ id, severity, ...tProps }) => {
                            const MessageBox = messageBoxMap[severity];

                            return (
                                <motion.li
                                    key={id}
                                    initial={{ y: -10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: 10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                    layout
                                >
                                    <MessageBox
                                        key={id}
                                        {...tProps}
                                        role={severity === "error" ? "alert" : "status"}
                                        data-testid="bm-toast"
                                        dismissAction={{
                                            handleDismiss: () => {
                                                closeToast(id);
                                            },
                                            buttonTitle: "Lukk melding"
                                        }}
                                    />
                                </motion.li>
                            );
                        })}
                    </AnimatePresence>
                </ul>
            </div>
        </ToastContext.Provider>
    );
};

const useToastContext = () => {
    const context = useContext(ToastContext);

    if (context === null) {
        throw new Error("Could not find Toast Context. Toast hooks must be used within a ToastContextProvider");
    }

    return context;
};

export const useToast = () => {
    const { addToast } = useToastContext()!;

    return addToast;
};
