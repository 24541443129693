import { useEffect } from "react";
import { captureException } from "@sentry/react";
import { IHttpError } from "src/model/http.typer";
import classnames from "classnames";
import { PrimaryButton } from "../../Button";
import "./UventetFeil.scss";
import { Trackingkey } from "../../../tracking/tracking.model";

interface UventetFeilProps {
    errorToLog: IHttpError | null;
    illustrasjon?: boolean;
}

export function UventetFeil({ errorToLog, illustrasjon = true }: UventetFeilProps) {
    useEffect(() => {
        if (errorToLog) {
            captureException(errorToLog);
        }
    }, [errorToLog]);

    const refreshPage = () => {
        location.reload();
    };

    const componentClassName = classnames("feilside", {
        "feilside--uten-illustrasjon": !illustrasjon
    });

    return (
        <div className={componentClassName}>
            <div className="feilside__informasjon">
                <h2 className="jkl-title">
                    Splæsj!
                    <br />
                    Vi har gjort en feil.
                </h2>
                <p className="jkl-body feilside__informasjon--hjelpe-tekst">
                    Du kan prøve på nytt eller håpe på at vi har ryddet opp til neste gang du kommer på besøk.
                </p>

                <div className="feilside__informasjon--handlinger">
                    <PrimaryButton
                        onClick={refreshPage}
                        dataTestautoid="uventetfeil-prov-pa-nytt"
                        track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "uventetfeil-prov-pa-nytt" }}
                    >
                        Prøv på nytt
                    </PrimaryButton>
                </div>
            </div>

            <div className="feilside__illustrasjon">
                <img alt="Barn i sølepytt" src="static/images/barn_i_solepytt.jpg" />
            </div>
        </div>
    );
}
