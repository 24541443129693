import { ReactNode } from "react";
import "./SideFooter.scss";
import { FeedbackProps, GlobalFeedback } from "@components/GlobalFeedback";

export type FooterProps = {
    feedback?: boolean;
    feedbackProps?: FeedbackProps;
    children?: ReactNode;
};

export const SideFooter = ({ feedback = true, feedbackProps, children }: FooterProps) => {
    if (feedback) {
        return (
            <div className="side-footer">
                {feedback && <GlobalFeedback {...feedbackProps} />}
                {children}
            </div>
        );
    }

    return null;
};
