import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ToastContextProvider } from "@components/Toast";
import { NumberBasedAgreementDto, NumberedValue } from "src/model/gen";
import { useEndreAntall } from "../../antallsliste.queries";
import { Endre } from "./Endre";
import { Kvittering } from "./Kvittering";
import { Oppsummering } from "./Oppsummering";
import { Flow, type FlowProps, type Step, type StepFunctions } from "@components/SkjemaFlyt/Flow";

export type StepProps = StepFunctions & {
    mutation: ReturnType<typeof useEndreAntall>;
    updateEndringerState: (id: string, gruppenummer: string) => (state: ChangeState) => void;
    endringerState: Record<string, EndringState>;
};

export interface GruppeTilEndring {
    groupId: string;
    gruppenavn?: string;
    antall: number;
    newNumber: number | undefined;
    numberedValue: NumberedValue;
}

export interface AvtaleTilEndring {
    id: string;
    avtalenummer: string;
    avtalenavn: string;
    productCode: string;
    totaltAntall: number;
    grupper: Array<GruppeTilEndring>;
    antallType: "AARSVERK" | "ANSATTE";
    maksNyttAntall?: number;
}

export type FieldValues = {
    avtaler: Array<AvtaleTilEndring>;
};

type Props = {
    avtaler: NumberBasedAgreementDto[];
};

export type ChangeState = "IDLE" | "PENDING" | "RESOLVED" | "REJECTED";
export type EndringState = Record<string, ChangeState>;

export function EndreAntall({ avtaler }: Props) {
    const { indeks } = useParams<"indeks">();

    const [endringerState, setEndringerState] = useState<Record<string, EndringState>>({});

    const updateEndringerState = (id: string, gruppenummer: string) => (state: ChangeState) => {
        setEndringerState((endringer) => ({
            ...endringer,
            [id]: {
                ...endringer[id],
                [gruppenummer]: state
            }
        }));
    };

    const avtalerTilEndring = avtaler
        .filter((avtale) => avtale.changeable)
        .filter((avtale) => (indeks ? avtale.id.toString() === indeks : true))
        .reduce<Array<AvtaleTilEndring>>((avtalerTilEndring, avtale) => {
            const nyAvtale: AvtaleTilEndring = {
                avtalenummer: avtale.agreementId.toString(),
                id: avtale.id.toString(),
                avtalenavn: avtale.productName,
                grupper: [],
                productCode: avtale.productCode,
                totaltAntall: avtale.numberedValue.value,
                antallType: avtale.productCode === "Z01" ? "AARSVERK" : "ANSATTE",
                maksNyttAntall: avtale.numberedValue.maxValue
            };

            nyAvtale.grupper = avtale.numberBasedAgreementGroup.map<GruppeTilEndring>((gruppe) => ({
                groupId: gruppe.groupId,
                gruppenavn: gruppe.groupName,
                antall: gruppe.numberedValue.value,
                newNumber: undefined,
                numberedValue: gruppe.numberedValue
            }));

            return [...avtalerTilEndring, nyAvtale];
        }, []);

    const mutation = useEndreAntall();

    const defaultValues = {
        avtaler: avtalerTilEndring
    };

    const form = useForm<FieldValues>({
        defaultValues: defaultValues
    });

    const stegListe: Step<StepProps>[] = [
        {
            stepName: "Nytt antall",
            stepDescription: "Du trenger bare å fylle inn i de feltene du vil endre på.",
            element: Endre
        },
        { stepName: "Oppsummering", stepDescription: "Ser alt riktig ut?", element: Oppsummering },
        { stepName: "Kvittering", element: Kvittering }
    ];

    const valuesToSummarize = [
        form
            .getValues()
            .avtaler.map((avtale) => `${avtale.avtalenavn}: ${avtale.grupper.map((gruppe) => gruppe.newNumber)}`)
            .flat(),
        "Endringen trer i kraft umiddelbart"
    ];

    console.log(valuesToSummarize);

    return (
        <div className="endre-antall-flyt__wrapper">
            <ToastContextProvider>
                <CustomSkjemaFlyt
                    flowName="ansatt-endre-antall"
                    formMethods={form}
                    defaultValues={defaultValues}
                    steps={stegListe}
                    mutation={mutation}
                    updateEndringerState={updateEndringerState}
                    endringerState={endringerState}
                />
            </ToastContextProvider>
        </div>
    );
}

function CustomSkjemaFlyt(
    props: FlowProps<StepProps> & {
        flowName: string;
        steps: Array<Step<StepProps>>;
        mutation: ReturnType<typeof useEndreAntall>;
        updateEndringerState: (avtalenummer: string, gruppenummer: string) => (state: ChangeState) => void;
        endringerState: Record<string, EndringState>;
        defaultValues: FieldValues;
    }
) {
    return <Flow {...props} />;
}
