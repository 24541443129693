import "./Tilbakemelding.scss";
import { ComponentProps } from "react";
import classNames from "classnames";

import { Feedback } from "@fremtind/jkl-feedback-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";

import { httpPost } from "../../common/api";
import { useSendEmail } from "../../common/queries";
import { isDefined } from "../../common/utils";
import { useBruker } from "../../features/bruker/queries";
import { track, Trackingkey } from "../../tracking";

const sendTibakemelding = ({ rating, kommentar }: { rating: number; kommentar: string }) =>
    // send error to sentry for further investigation:

    httpPost(
        "tilbakemelding",
        JSON.stringify({
            rating,
            kommentar
        })
    ).then(() => {
        if (rating === 1) {
            captureException(new Error(`Tilbakemelding med rating 1`));
        }
    });

const createTilbakemeldingText = (kommentar: string, meta?: FeedbackMeta & { distributor: string }) => {
    if (meta) {
        return `${kommentar}

${Object.entries(meta)
    .filter(([, value]) => isDefined(value))
    .map(([key, value]) => key + ": " + value)
    .join("\n")}`;
    }

    return kommentar;
};

type KontaktInfo = {
    email: string;
    phone?: string;
};

type FeedbackProps = ComponentProps<typeof Feedback>;

interface FeedbackMetaBase {
    side: string;
    produktkode?: string;
}

export type FeedbackMeta = Record<string, string | undefined> & FeedbackMetaBase;

interface Props extends Omit<FeedbackProps, "onSubmit" | "type" | "options"> {
    className?: string;
    tracking: {
        side: string;
        formFlowName?: string;
        formStepName?: string;
        hendelse: Trackingkey.TilbakemeldingScore;
        spørsmål: string;
        meta?: Record<string, any>;
    };
    options?: Array<{ label: string; value: number; textAreaLabel?: string }>;
    type?: "smiley" | "radio";
    withContact?: boolean;
    withSpacing?: boolean;
    onSubmit?: () => void;
    meta?: FeedbackMeta;
}

export const Tilbakemelding = ({
    tracking,
    type = "smiley",
    options = [
        { label: "Ikke fornøyd i det hele tatt", value: 1 },
        { label: "Ikke fornøyd", value: 2 },
        { label: "Hverken fornøyd eller misfornøyd", value: 3 },
        { label: "Fornøyd", value: 4 },
        { label: "Veldig fornøyd", value: 5 }
    ],
    withContact = true,
    meta,
    ...props
}: Props) => {
    const tilbakemeldingMutation = useMutation({ mutationFn: sendTibakemelding });
    const kontaktInfoMutation = useSendEmail();
    const { valgtOrganisasjon } = useBruker();

    const onTilbakemeldingSubmit = (feedback: {
        feedbackValue: number | string | Array<string | number>;
        message?: string;
    }) => {
        if (typeof feedback?.feedbackValue !== "number") {
            throw Error("En rating fra en tilbakemelding burde alltid være et nummer. Her har det skjedd noe feil.");
        }

        // Finn ut hvilken organisasjon er bruker på
        const host = window.location.host;
        let currentOrg = "";
        if (host.includes("dnbforsikring")) {
            currentOrg = "DNB";
        } else if (host.includes("sparebank1")) {
            currentOrg = "Sparebank1";
        } else {
            currentOrg = "Fremtind";
        }

        // Ratings skal sendes til Mixpanel & Sentry
        if (Number(feedback.feedbackValue)) {
            track({
                hendelse: tracking.hendelse,
                score: feedback.feedbackValue,
                organisasjon: currentOrg,
                spørsmål: tracking.spørsmål,
                kommentar: feedback.message,
                ...tracking.meta
            });
        }

        if (feedback.message && feedback.message.trim().length !== 0) {
            tilbakemeldingMutation.mutate({
                rating: feedback.feedbackValue,
                kommentar: createTilbakemeldingText(
                    feedback.message,
                    meta
                        ? { ...meta, distributor: currentOrg, organisasjon: valgtOrganisasjon?.organisasjonsnummer }
                        : undefined
                )
            });
        }
    };

    const onKontaktinfoSubmit = (kontaktInfo: KontaktInfo) => {
        if (!kontaktInfo.email) return;

        const body = `E-post: ${kontaktInfo.email}\n${kontaktInfo.phone && `Tlf: ${kontaktInfo.phone}`}`;

        kontaktInfoMutation.mutate({ emne: "Brukertest", body });
    };

    const classes = classNames(
        "fbm-tilbakemelding",
        {
            "fbm-tilbakemelding--luftig": props.withSpacing
        },
        props.className
    );

    if (kontaktInfoMutation.error) {
        return (
            <section className="fbm-tilbakemelding-error">
                <ErrorMessageBox title="Tilbakemelding ble ikke sendt">
                    Vi kunne ikke sende tilbakemeldingen nå. Prøv en gang til eller kom tilbake senere. Lurer du på noe
                    annet ta kontakt med din rådgiver.
                </ErrorMessageBox>
            </section>
        );
    } else {
        return (
            <Feedback
                className={classes}
                addOnQuestion={{
                    label: "Tips oss gjerne om hva vi kan forbedre"
                }}
                type={type}
                options={options}
                {...props}
                contactQuestion={withContact ? { onSubmit: onKontaktinfoSubmit, withPhone: true } : undefined}
                onSubmit={onTilbakemeldingSubmit}
            />
        );
    }
};
