import { SecondaryButton } from "@fremtind/jkl-button-react";
import { Kvittering as KvitteringBase } from "../../../components/Kvittering";
import { SecondaryLinkButton } from "../../../components/Button";
import { SigneringStegProps } from ".";

export const Kvittering = ({ onFerdig, tilbud }: SigneringStegProps) => {
    function resetForm() {
        onFerdig(true);
    }

    return (
        <KvitteringBase
            heading="Flott!"
            positives={["Gruppelivsforsikring er signert og bestilt."]}
            message={{
                title: "Behandlingstid",
                body: "Du vil få e-post når avtalen er etablert, det vil normalt ta ca. fem dager. Ved flytting av forsikring fra et annet selskap kan det ta opptil to måneder.",
                variant: "info"
            }}
        >
            {tilbud.filter((tilbud) => !tilbud.signert).length ? (
                <SecondaryButton onClick={resetForm}>Ferdig</SecondaryButton>
            ) : (
                <SecondaryLinkButton to="/hjem">Ferdig</SecondaryLinkButton>
            )}
        </KvitteringBase>
    );
};
