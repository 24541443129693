import type { TaskResponseDto } from "src/model/gen";
import { TaskCard } from "@features/tasks/components/TaskCard/TaskCard";
import { Typography } from "@components/Typography";

import styles from "./AgreementTasksSection.module.scss";

interface Props {
    agreementTasks: TaskResponseDto[];
}

export const AgreementTasksSection = ({ agreementTasks }: Props) => {
    return (
        <div className={styles.tasks}>
            <Typography variant="heading-2">Aktuelt</Typography>
            <div className={styles.taskList}>
                {agreementTasks.map((task, index) => (
                    <TaskCard key={index} task={task} />
                ))}
            </div>
        </div>
    );
};
