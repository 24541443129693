import {
    SkeletonAnimation,
    SkeletonTable,
    SkeletonTableHeader,
    SkeletonElement,
    SkeletonTableRow
} from "@fremtind/jkl-loader-react";

export const VehiclesLoader = () => {
    return (
        <SkeletonAnimation
            role="none presentation" // Kun for eksempelet, ellers blir det mange alerts p� siden
        >
            <SkeletonTable>
                <SkeletonTableHeader>
                    <div style={{ width: 215 }}>
                        <SkeletonElement width={115} height={16} />
                    </div>
                    <div style={{ width: 115 }}>
                        <SkeletonElement width={115} height={16} />
                    </div>
                </SkeletonTableHeader>
                {Array.from(Array(5)).map((_, index) => (
                    <SkeletonTableRow key={index}>
                        <div style={{ width: 215 }}>
                            <SkeletonElement width={index % 2 ? 115 : 185} height={19} />
                        </div>
                        <div style={{ width: 115 }}>
                            <SkeletonElement width={24} height={24} />
                        </div>
                    </SkeletonTableRow>
                ))}
            </SkeletonTable>
        </SkeletonAnimation>
    );
};
