import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { track, Trackingkey } from "../../../../tracking";
import { InvoiceContent, InvoiceDetails, InvoiceDocuments, PaymentDetails } from "../../components/InvoiceDetails";
import { StatusTag } from "../../components/StatusTag";
import { useInvoiceDetails } from "../../invoice.queries";

export const InvoiceDetailsComposer = () => {
    const params = useParams<"invoiceId">();
    const { data } = useInvoiceDetails(params.invoiceId!);

    const invoice = data?.entity.invoiceDto;
    const invoiceLines = data?.entity.invoiceLines ?? [];

    const tableRef = useRef<HTMLTableElement>(null);
    const [maxHeight, setMaxHeight] = useState<string | number>("auto");

    useEffect(() => {
        if (invoice) {
            track({
                hendelse: Trackingkey.SeFaktura,
                fakturanummer: invoice.fakturaId,
                betalingsfrist: invoice.betalingsFrist,
                betalingstype: invoice.ehf ? "EHF" : invoice.betalingsType,
                type: invoice.invoiceEventTypeText,
                status: invoice.status,
                coreSystem: invoice.fagsystem,
                harDokumenter: invoice.hasDocuments
            });
        }
    }, [invoice]);

    useLayoutEffect(() => {
        const height = tableRef.current?.getBoundingClientRect().height ?? "auto";
        setMaxHeight(height);
    }, []);

    if (!invoice) {
        return null;
    }

    return (
        <div className="bm-invoice-details">
            <section className="bm-invoice-details__header">
                <StatusTag
                    status={invoice.status}
                    dueDate={invoice.betalingsFrist}
                    invoiceEventType={invoice.invoiceEventType}
                    className="bm-invoice-details__header__invoice-status"
                />
                <PaymentDetails invoice={invoice} ref={tableRef} />
                <InvoiceDetails invoice={invoice} maxHeight={maxHeight} />
            </section>
            <section className="bm-invoice-details__content">
                <InvoiceDocuments invoice={invoice} documents={data.entity.documents} />
                {!!invoiceLines.length && (
                    <InvoiceContent
                        invoiceLines={invoiceLines}
                        invoice={invoice}
                        includedInvoices={data.entity.includedInvoices}
                    />
                )}
            </section>
        </div>
    );
};
