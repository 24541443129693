import { http } from "@features/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type EndreAvtaledetaljerResponse = {
    phone?: string;
    email?: string;
    message: string;
    id: string;
};

const requestEndreAvtaledetaljer = async ({ id, message, phone, email }: EndreAvtaledetaljerResponse) => {
    const payload = { message, phone, email };
    const res = await http.postRaw(`/crm/${id}`, payload);
    return res;
};

export const useEndreAvtaledetaljer = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: requestEndreAvtaledetaljer,
        onSuccess: () => {
            queryClient.invalidateQueries;
        }
    });
};
