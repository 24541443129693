import { forwardRef } from "react";
import { useScreen } from "@fremtind/jkl-react-hooks";
import { Table, TableBody, TableCell, TableRow } from "@fremtind/jkl-table-react";
import { formatDate, formatKontonummer, formatValuta } from "@fremtind/jkl-formatters-util";
import { isValid } from "date-fns";
import { asDate } from "src/common/formatting";
import { CopyToClipboard } from ".";
import { InvoiceDto } from "../../../../model/gen";

interface Props {
    invoice: InvoiceDto;
}

const NUMBERS_ONLY_REGEX = /[^0-9]/g;

export const PaymentDetails = forwardRef<HTMLTableElement, Props>(({ invoice }, ref) => {
    const { belop, betalTilKontoNr, kid, betalingsFrist, status } = invoice;
    const dueDate = asDate(betalingsFrist || "");
    const { isSmallDevice } = useScreen();

    const amountToPay = status === "ANNULLERT" || status === "BETALT" ? formatValuta(0) : formatValuta(belop);

    return (
        <Table className="bm-invoice-details__header__table" ref={ref}>
            <TableBody>
                <TableRow>
                    <TableCell scope="row">Beløp å betale</TableCell>
                    <TableCell>
                        <div>
                            <CopyToClipboard content={belop.toString()} actionLabel="Beløp å betale">
                                {amountToPay}
                            </CopyToClipboard>
                        </div>
                    </TableCell>
                </TableRow>
                {/* Kontonummer kan være undefined på fakturaer fra prolife. */}
                {!!betalTilKontoNr && (
                    <TableRow>
                        <TableCell scope="row">Kontonummer</TableCell>
                        <TableCell>
                            <div>
                                <CopyToClipboard
                                    content={betalTilKontoNr?.replace(NUMBERS_ONLY_REGEX, "")}
                                    actionLabel="Kontonummer"
                                >
                                    {formatKontonummer(betalTilKontoNr)}
                                </CopyToClipboard>
                            </div>
                        </TableCell>
                    </TableRow>
                )}
                <TableRow>
                    <TableCell scope="row">KID-nummer</TableCell>
                    <TableCell>
                        <div>
                            <CopyToClipboard actionLabel="KID-nummer" content={kid}>
                                {kid}
                            </CopyToClipboard>
                        </div>
                    </TableCell>
                </TableRow>
                {/* Betalingsfrist kan være undefined på fakturaer fra prolife. */}
                {isValid(dueDate) && (
                    <TableRow>
                        <TableCell scope="row">Betalingsfrist</TableCell>
                        <TableCell>
                            <div className="bm-invoice-details__header__table__no-copy-cell">
                                {isSmallDevice && <span>Betalingsfrist</span>}
                                {formatDate(dueDate)}
                            </div>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
});

PaymentDetails.displayName = "PaymentDetails";
