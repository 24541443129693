import { useCallback } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { ExpandableTableRow, ExpandableTableRowController, TableCell } from "@fremtind/jkl-table-react";
import { model } from "@features/navneliste";
import { formatFodselsnummer } from "@fremtind/jkl-formatters-util";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { Typography } from "src/components/Typography";
import { useScreen } from "src/context/UseScreenContext";
import { toNorwegianDateFormat } from "src/common/formatting";
import { checkMark, xMark } from "src/components/Status/Status";
import { KundeFeilmelding } from "src/model/feilkoder.object";
import { InsuredTableDetails } from "./InsuredTableDetails";

interface Props {
    insured: model.Person;
    isOpen: boolean;
    headers: model.ListHeaderCell[];
    tableColumnWidths: Array<number | undefined>;
    meldUtButtonProps: {
        to: (insured: model.Person) => string;
        tracking: () => void;
    };
    meldInnButtonProps: {
        to: (insured: model.Person) => string;
        tracking: () => void;
    };
    showAgreementNumber: boolean;
    agreementNumbersByProductCode: Record<AvtaleProduktID, Array<string>>;
    handleToggle: (isOpen: boolean) => void;
    useDetails: (
        indeks: string,
        enabled: boolean
    ) => UseQueryResult<{ entity: model.Detaljer; feilmelding?: KundeFeilmelding }>;
}
export function InsuredTableRow({
    insured,
    headers,
    isOpen,
    tableColumnWidths,
    showAgreementNumber,
    agreementNumbersByProductCode,
    handleToggle,
    useDetails,
    meldUtButtonProps,
    meldInnButtonProps
}: Props) {
    const { isSmallDevice } = useScreen();

    const getCells = useCallback(
        (insured: model.Person) =>
            headers.map((avtaleProdukt) => {
                const avtale = insured.avtaler.find((avtale: any) => avtale.produktkode === avtaleProdukt.produktkode);

                if (avtale) {
                    const status = avtale.innmeldingStatus;
                    const utmeldtDato = avtale.utmeldtDato;
                    const innmeldtDato = avtale.innmeldtDato;

                    switch (status) {
                        case "INNMELDT":
                            return { avtaleProdukt: avtaleProdukt, value: checkMark, status };
                        case "UNDER_INNMELDING":
                            return {
                                avtaleProdukt: avtaleProdukt,
                                value: (
                                    <div>
                                        <Typography>Under innmelding</Typography>
                                        {innmeldtDato && (
                                            <Typography component="i" variant="small">
                                                Meldes inn: {toNorwegianDateFormat(new Date(innmeldtDato))}
                                            </Typography>
                                        )}
                                    </div>
                                ),
                                status
                            };
                        case "UNDER_UTMELDING":
                            return {
                                avtaleProdukt: avtaleProdukt,
                                value: (
                                    <div>
                                        <Typography>Under utmelding</Typography>
                                        {utmeldtDato && (
                                            <Typography component="i" variant="small">
                                                Meldes ut: {toNorwegianDateFormat(new Date(utmeldtDato))}
                                            </Typography>
                                        )}
                                    </div>
                                ),
                                status
                            };
                        default:
                            return { avtaleProdukt: avtaleProdukt, value: xMark, status };
                    }
                } else {
                    return { avtaleProdukt: avtaleProdukt, value: xMark, status: "" };
                }
            }),
        [headers]
    );

    const cells = getCells(insured);

    return (
        <ExpandableTableRow
            key={insured.indeks}
            expandedChildren={
                <InsuredTableDetails
                    {...{
                        insured,
                        isOpen,
                        headers,
                        tableColumnWidths,
                        useDetails,
                        meldUtButtonProps,
                        meldInnButtonProps,
                        kanMeldesInn: cells.some((cell) => !["UNDER_INNMELDING", "INNMELDT"].includes(cell.status))
                    }}
                />
            }
            onToggle={handleToggle}
        >
            <TableCell data-th="Navn">
                {insured.fornavn} {insured.etternavn}
                <br />
                <Typography component="span" variant="small">
                    {formatFodselsnummer(insured.fodselsnummer)}
                </Typography>
            </TableCell>
            {cells.map((cell, cellIndex) => (
                <TableCell
                    key={insured.indeks + cellIndex}
                    data-th={`${headers[cellIndex].avtaleNavn} ${
                        showAgreementNumber
                            ? `(${agreementNumbersByProductCode[headers[cellIndex].produktkode as AvtaleProduktID].join(
                                  ","
                              )})`
                            : ""
                    }`}
                >
                    {cell.value}
                </TableCell>
            ))}
            <ExpandableTableRowController>{isSmallDevice ? "Se detaljer" : null}</ExpandableTableRowController>
        </ExpandableTableRow>
    );
}
