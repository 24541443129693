import { PrimaryButton } from "@fremtind/jkl-button-react";
import { Link } from "@fremtind/jkl-core";
import { useFormContext } from "react-hook-form";
import { SecondaryLinkButton } from "../../../../components/Button";
import { EndreSykelonnStegProps, EndreSykelonnFormState } from ".";
import { LenkeId, useExternalLinks } from "../../../../common/lenker";
import { ChooseFromList } from "../../../../components/ChooseFromList";

export const VelgAnsatt = ({ ansatte, onFrem, onAvbryt }: EndreSykelonnStegProps) => {
    const { setValue } = useFormContext<EndreSykelonnFormState>();

    const { links } = useExternalLinks();

    const pensjonsUrl = links.find((link) => link.lenkeId === LenkeId.pensjon);

    return (
        <ChooseFromList
            options={ansatte.map((ansatt) => ({
                label: `${ansatt.fornavn} ${ansatt.etternavn}`,
                value: ansatt.indeks,
                extraLabel: `(${ansatt.fodselsnummer})`
            }))}
            multiple
            instruction="Velg ansatte"
            title="Velg ansatte"
            subtitle={
                <>
                    Endringen gjelder kun for Sykelønn. Vil du endre lønn for pensjonsproduktene dine må du gjøre det
                    hos{" "}
                    {pensjonsUrl ? (
                        <Link external href={pensjonsUrl.href} target="_blank" rel="noreferrer noopener">
                            kundeportal pensjon
                        </Link>
                    ) : (
                        // denne skal ikke inntreffe, men kjekt med fallback
                        "kundeportal pensjon"
                    )}
                </>
            }
            withSearch={{
                noMatchText: "Ingen ansatte passer søket ditt",
                placeholder: "Navn eller fødselsnummer"
            }}
            onSubmit={({ checked }) => {
                if (!checked || !Array.isArray(checked) || !checked.length) {
                    return;
                }

                setValue("ansatteIndekser", checked);

                onFrem();
            }}
            required="Du må velge en ansatt"
        >
            <PrimaryButton>Neste</PrimaryButton>
            <SecondaryLinkButton to="/ansatte-navn" onClick={() => onAvbryt()}>
                Avbryt
            </SecondaryLinkButton>
        </ChooseFromList>
    );
};
