import { useEffect, useState } from "react";

import type { Product } from "src/common/products/product.model";
import type { AvtaleProduktID } from "../avtale.model";

const products = [
    "610",
    "M05",
    "M07",
    "M14",
    "P40",
    "T10",
    "T20",
    "V05",
    "V06",
    "V07",
    "V09",
    "V20",
    "V30",
    "V40",
    "V50",
    "V56",
    "Y06",
    "Y16",
    "Y19",
    "Y24",
    "Y25",
    "Y36",
    "Y40",
    "Y44",
    "Y45",
    "Y47",
    "Y48",
    "Y49",
    "Y50",
    "Y60",
    "Z01",
    "Z02",
    "Z06",
    "Z10",
    "Z11",
    "Z12",
    "Lagring"
];

interface ImportedProduct {
    productCode: string;
    product: Product;
}

interface ImportResult {
    default: Product;
}

const importProducts = async () => {
    const importedProducts = await Promise.all(
        products.map(
            (productCode) =>
                new Promise<ImportedProduct>((resolve) => {
                    import(`@/common/products/${productCode}.tsx`).then((product: ImportResult) => {
                        resolve({ productCode, product: product.default });
                    });
                })
        )
    );

    return importedProducts.reduce<Record<string, Product>>((acc, { productCode, product }) => {
        acc[productCode] = product;
        return acc;
    }, {});
};

export const useAgreementInfo = (produktkode: AvtaleProduktID | undefined | string): Product | undefined => {
    const [richText, setRichText] = useState<Product>();
    useEffect(() => {
        (async () => {
            try {
                if (!produktkode) {
                    return;
                }

                const importedProducts = await importProducts();

                const richTextObj = importedProducts[produktkode];

                setRichText(richTextObj);
            } catch (e) {
                // is fine, I Promise()
            }
        })();
    }, [produktkode]);

    return richText;
};
