import { useState } from "react";
import { Accordion } from "@fremtind/jkl-accordion-react";
import { ExpandButton } from "@fremtind/jkl-expand-button-react";
import { useAnimatedHeight } from "@fremtind/jkl-react-hooks";
import { Typography } from "@components/Typography";
import { components } from "@features/faq";
import { faq } from "../../../faq/faq.object";
import "./InvoiceOverviewFaq.scss";

export const InvoiceOverviewFaq = () => {
    const invoiceFaqQuestions = faq.filter((f) => f.category === "Faktura");
    const [faqIsExpanded, setFaqIsExpanded] = useState(false);
    const [animationRef] = useAnimatedHeight<HTMLDivElement>(faqIsExpanded);

    if (!invoiceFaqQuestions) {
        return null;
    }

    const firstFaqQuestions = invoiceFaqQuestions.slice(0, 3);
    const extraFaqQuestions = invoiceFaqQuestions.slice(3);

    return (
        <div className="invoice-overview__faq">
            <Accordion className="faqlist" data-expanded={faqIsExpanded}>
                <Typography component="h2" variant="heading-3" className="faqlist__heading jkl-spacing-24--bottom">
                    Spørsmål og svar
                </Typography>
                {firstFaqQuestions.map((item) => (
                    <components.FaqItem key={item.question} item={item} />
                ))}
                <div ref={animationRef}>
                    {extraFaqQuestions.map((item) => (
                        <components.FaqItem key={item.question} item={item} />
                    ))}
                </div>
            </Accordion>
            <ExpandButton
                onClick={() => {
                    setFaqIsExpanded((e) => !e);
                }}
                isExpanded={faqIsExpanded}
                className="invoice-overview__faq-expand-button"
            >
                {faqIsExpanded ? "Skjul flere spørsmål" : "Vis flere spørsmål"}
            </ExpandButton>
        </div>
    );
};
