import { NavCard } from "@fremtind/jkl-card-react";
import { Trackingkey, track } from "src/tracking";
import "./HomePageCTA.scss";

export const HomePageCTA: React.FC<{ shouldShow: string | undefined; title?: string; asListItem?: boolean }> = ({
    shouldShow,
    title,
    asListItem
}) => {
    if (!shouldShow) return null;

    const NavCardToFeed = (
        <NavCard
            className="cta-banner"
            title={title ? title : "Kjøp reiseforsikring"}
            tag={{ type: "info", text: "Nyhet!" }}
            image={{
                src: "/bedrift/static/images/inngangkjop.png",
                alt: "Illustrasjon av en person som kjøper reiseforsikring"
            }}
            href="kjop/reise"
            description="Et verdifullt personalgode som dekker ansatte og familiene deres."
            onClick={() => {
                track({
                    hendelse: Trackingkey.LenkeTrykk,
                    lenkeId: "forside kjøp reise"
                });
            }}
        />
    );

    return asListItem ? <li>{NavCardToFeed}</li> : <>{NavCardToFeed}</>;
};
