import "./SearchField.scss";
import classNames from "classnames";
import { CloseIcon, SearchIcon } from "@fremtind/jkl-icons-react";
import { TextInput } from "../TextInput";
import { TextInputProps } from "../TextInput";

export interface SearchFieldProps extends Omit<TextInputProps, "label"> {
    match: string;
    setMatch: (match: string) => void;
    matchedCount: number;
    label?: string;
    placeholder?: string;
}

export const SearchField = ({
    match,
    setMatch,
    matchedCount,
    placeholder = "Søkeord",
    label = "Søk i listen",
    ...props
}: SearchFieldProps) => {
    const classes = classNames("search-field", props.className);

    return (
        <>
            <TextInput
                label={label}
                placeholder={placeholder}
                {...props}
                action={
                    match
                        ? { icon: <CloseIcon />, label: "tøm søkefeltet", onClick: () => setMatch("") }
                        : { icon: <SearchIcon />, label: "utfør søk", onClick: () => undefined }
                }
                className={classes}
                value={match}
                onChange={(event) => setMatch(event.target.value)}
            />

            <span className="jkl-sr-only" aria-live="polite">
                {matchedCount === 0 ? "Ingen treff" : `${matchedCount} treff`}
            </span>
        </>
    );
};
