import "./InvoiceDetails.scss";

import React from "react";
import { Await, LoaderFunction, useLoaderData } from "react-router-dom";
import { queryClient } from "src/common/queryClient";
import { Bruker } from "src/model/bruker.typer";

import { Side, SideInnhold } from "@components/Side";
import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";
import { invoiceDetailsQuery } from "@features/invoice/invoice.queries";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { InvoiceDetailsDto } from "../../../../model/gen";
import { EntityResponse } from "../../../core/http";
import { InvoiceDetailsComposer } from "./InvoiceDetailsComposer";
import { InvoiceDetailsHeaderComposer } from "./InvoiceDetailsHeaderComposer";
import { InvoiceDetailsLoader } from "./InvoiceDetailsLoader";

export const loader: LoaderFunction = async ({ params }) => {
    const bruker = await queryClient.ensureQueryData<Bruker>({ queryKey: brukerQueryKey, queryFn: fetchBruker });

    if (bruker?.gjeldendeOrganisasjonsnummer && params.invoiceId) {
        // enn så lenge returnerer data 200 OK med feilmelding, så det må valideres lokalt
        // https://fremtind.atlassian.net/browse/FBM-2767
        const dataFetcher = invoiceDetailsQuery(bruker.gjeldendeOrganisasjonsnummer, params.invoiceId);
        const dataPromiseWithRejection = new Promise((resolve, reject) => {
            dataFetcher.then((data) => {
                if (data.feilmelding) {
                    return reject(data);
                }

                return resolve(data);
            });
        });

        return {
            invoice: dataPromiseWithRejection
        };
    }

    return Promise.resolve();
};

const ErrorBoundary = () => {
    return (
        <ErrorMessageBox title="Oi, litt tekniske problemer her">
            Vi klarer ikke hente fakturadetaljene dine. Prøv en gang til eller kom tilbake senere.
        </ErrorMessageBox>
    );
};

export function InvoiceDetails() {
    const { invoice } = useLoaderData() as { invoice: EntityResponse<InvoiceDetailsDto> };

    return (
        <Side>
            <React.Suspense fallback={<InvoiceDetailsHeaderComposer isLoading />}>
                <Await resolve={invoice}>
                    <InvoiceDetailsHeaderComposer />
                </Await>
            </React.Suspense>
            <SideInnhold>
                <React.Suspense fallback={<InvoiceDetailsLoader />}>
                    <Await resolve={invoice} errorElement={<ErrorBoundary />}>
                        <InvoiceDetailsComposer />
                    </Await>
                </React.Suspense>
            </SideInnhold>
        </Side>
    );
}
