import { Loader } from "@fremtind/jkl-loader-react";
import { SecondaryLinkButton, SecondaryButton } from "../../../../components/Button";
import { Rolle } from "../../../../model/Rolle";
import { getRolleFromTilgjengeligeTilganger } from "../../../../common/rolle";
import { Kvittering } from "../../../../components/Kvittering";
import { useGetTilganger } from "../../queries";
import { Trackingkey } from "../../../../tracking/tracking.model";

interface FormData {
    organisasjonsnummer: string;
    navn: string;
    roller: Rolle[];
}

interface Props {
    goBack: any;
    values: FormData;
}

export const KvitteringBedrift = ({ goBack, values }: Props) => {
    const tilgangerQuery = useGetTilganger();
    const roller = Array.isArray(values.roller) ? values.roller : [values.roller];

    if (!tilgangerQuery.data) {
        return <Loader textDescription="henter tilganger" />;
    }

    return (
        <section className="gi-tilganger-side">
            <Kvittering
                heading={`Flott! ${values.navn} kan nå se og endre:`}
                positives={
                    roller
                        .map(
                            (rolle) =>
                                getRolleFromTilgjengeligeTilganger(
                                    rolle,
                                    tilgangerQuery.data.tilgjengeligeOrganisasjonsTilganger
                                )?.label
                        )
                        .filter((val) => typeof val === "string") as string[]
                }
            >
                <SecondaryLinkButton to="/tilganger" data-testid="gi-tilgang-kvittering-hjem">
                    Ferdig
                </SecondaryLinkButton>
                <SecondaryButton
                    track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "gi-tilgang-kvittering-restart" }}
                    dataTestautoid="gi-tilgang-kvittering-restart"
                    data-testid="gi-tilgang-kvittering-restart"
                    onClick={goBack}
                >
                    Ny tilgang
                </SecondaryButton>
            </Kvittering>
        </section>
    );
};
