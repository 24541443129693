import { useMutation } from "@tanstack/react-query";
import { httpPost } from "./api";

type SendEmailArgs = {
    emne: string;
    body: string;
};

const sendEmail = (args: SendEmailArgs) => httpPost("enkeltepost", JSON.stringify(args));

export const useSendEmail = () => useMutation({ mutationFn: sendEmail });
