import React from "react";
import { Await, Navigate, redirect, useLoaderData, useNavigate } from "react-router-dom";
import { Loader } from "@fremtind/jkl-loader-react";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { SecondaryButton } from "@components/Button";
import { brukerQueryKey, fetchBruker, useBruker } from "@features/bruker/queries";
import { Bruker } from "src/model/bruker.typer";
import { isRadgiver } from "src/common/rolle";
import { Organisasjon } from "src/model/gen";
import { queryClient } from "src/common/queryClient";
import { Trackingkey } from "src/tracking";
import { organizationQuery, useOrganizations } from "../../organisasjon.queries";
import { OrganizationSelector } from "../../components/OrganizationSelector";
import "./VelgOrganisasjon.scss";

export const loader = async () => {
    const bruker = await queryClient.ensureQueryData<Bruker>({
        queryKey: brukerQueryKey,
        queryFn: () => fetchBruker()
    });

    if (bruker.radgiver) {
        return redirect("/hjem");
    }

    if (bruker) {
        return {
            organizations: organizationQuery()
        };
    }

    return Promise.resolve();
};

export const VelgOrganisasjon = () => {
    const { organizations } = useLoaderData() as { organizations: Organisasjon[] };
    const { bruker } = useBruker();

    const navigate = useNavigate();
    const { data } = useOrganizations();
    return (
        <Side className="velg-organisasjon">
            <React.Suspense fallback={<Loader textDescription="Henter bedrifter du har tilgang til" />}>
                <Await resolve={organizations}>
                    {data && data.length !== 1 ? (
                        <>
                            <SideHeader
                                title="Velg bedrift eller organisasjon"
                                toolbar={
                                    <>
                                        {!isRadgiver(bruker) && (
                                            <SecondaryButton
                                                track={{
                                                    hendelse: Trackingkey.Knappetrykk,
                                                    knappeId: "loggut-desktop"
                                                }}
                                                onClick={() => navigate("/loggut")}
                                                dataTestautoid="header-loggut-desktop"
                                            >
                                                Logg ut
                                            </SecondaryButton>
                                        )}
                                    </>
                                }
                                hideBreadcrumbs
                            />
                            <SideInnhold>
                                <OrganizationSelector />
                            </SideInnhold>
                        </>
                    ) : (
                        data && <Navigate to={`/bytt-organisasjon/${data[0].organisasjonsnummer}`} replace />
                    )}
                </Await>
            </React.Suspense>
        </Side>
    );
};
