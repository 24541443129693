import { rem } from "./utils";

const spacing2 = rem(2);
const spacing4 = rem(4);
const spacing8 = rem(8);
const spacing12 = rem(12);
const spacing16 = rem(16);
const spacing24 = rem(24);
const spacing32 = rem(32);
const spacing40 = rem(40);
const spacing64 = rem(64);
const spacing104 = rem(104);
const spacing168 = rem(168);

export const spacing = {
    2: spacing2,
    4: spacing4,
    8: spacing8,
    12: spacing12,
    16: spacing16,
    24: spacing24,
    32: spacing32,
    40: spacing40,
    64: spacing64,
    104: spacing104,
    168: spacing168
};

export const jklS = (s: keyof typeof spacing) => spacing[s];
