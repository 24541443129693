import { Side, SideHeader, SideInnhold } from "src/components/Side";
import { NavLink } from "@fremtind/jkl-core";
import { Typography } from "@components/Typography";
import { useAvailableProducts } from "@features/kjop/queries";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { Trackingkey, track } from "src/tracking";
import { useExternalLinks, LenkeId } from "src/common/lenker";
import { Loader } from "@fremtind/jkl-loader-react";
import "./KjopForsikring.scss";
import { HomePageCTA as HomePageCTAY44 } from "@features/kjop/reise";
import { HomePageCTA as HomePageCTAV05 } from "@features/kjop/bil";
import { useCustomerNumberCheck, useIsManaged } from "@features/kjop/utils";

export function KjopForsikring() {
    const { products, isLoading } = useAvailableProducts();

    const { getLinkByLinkId } = useExternalLinks();

    const getLink = (lenke: LenkeId, label: string) => {
        const link = getLinkByLinkId(lenke);
        if (!link) {
            return null;
        }
        return { ...link, label: label };
    };

    const AnsatteLinks = {
        name: "Ansatte",
        links: [
            getLink(LenkeId.annensykdom, "Annen sykdom"),
            getLink(LenkeId.fritidsulykke, "Fritidsulykke"),
            getLink(LenkeId.gruppelivsforsikring, "Gruppelivsforsikring"),
            getLink(LenkeId.behandlingsforsikring, "Helse/behandling"),
            getLink(LenkeId.kompanjongforsikring, "Kompanjong"),
            getLink(LenkeId.nokkelpersonforsikring, "Nøkkelperson"),
            getLink(LenkeId.reisenaering, "Reiseforsikring"),
            getLink(LenkeId.sykelonn, "Sykelønn"),
            getLink(LenkeId.ulykkebarn, "Ulykke barn"),
            getLink(LenkeId.yrkesskadeforsikring, "Yrkesskade")
        ].sort((a, b) => (a?.label && b?.label ? a?.label.localeCompare(b?.label) : 0))
    };

    const KjoretoyLinks = {
        name: "Kjøretøy",
        links: [
            getLink(LenkeId.arbeidsmaskin, "Arbeidsmaskin"),
            getLink(LenkeId.bilnaering, "Bil"),
            getLink(LenkeId.bussforsikring, "Buss"),
            getLink(LenkeId.batforsikring, "Båt"),
            getLink(LenkeId.lastebilforsikring, "Lastebil"),
            getLink(LenkeId.proveskilt, "Prøveskilt"),
            getLink(LenkeId.tilhengerforsikring, "Tilhenger"),
            getLink(LenkeId.arbeidsmaskin, "Traktor")
        ].sort((a, b) => (a?.label && b?.label ? a?.label.localeCompare(b?.label) : 0))
    };
    const DriftOgEiendomLinks = {
        name: "Drift og eiendom",
        links: [
            getLink(LenkeId.ansvarsforsikring, "Ansvar"),
            getLink(LenkeId.driftstapforsikring, "Avbrudd/driftstap"),
            getLink(LenkeId.boligbyggforsikring, "Boligbygg"),
            getLink(LenkeId.boretslagsameieforsikring, "Borettslag/sameie"),
            getLink(LenkeId.cyberforsikring, "Cyber"),
            getLink(LenkeId.kriminalitetsforsikring, "Kriminalitet/underslag"),
            getLink(LenkeId.eiendelsforsikring, "Løsøre/eiendeler"),
            getLink(LenkeId.naeringsbygg, "Næringsbygg"),
            getLink(LenkeId.prosjektforsikring, "Prosjekt/entreprenør"),
            getLink(LenkeId.spesialforsikring, "Spesial/verdisak"),
            getLink(LenkeId.transportforsikring, "Transport")
        ].sort((a, b) => (a?.label && b?.label ? a?.label.localeCompare(b?.label) : 0))
    };

    const LandbrukLinks = {
        name: "Landbruk",
        links: [
            getLink(LenkeId.avlingsskade, "Avling/innsatsmidler"),
            getLink(LenkeId.bygninglandbruk, "Bygning landbruk"),
            getLink(LenkeId.losorelandbruk, "Løsøre, avling for landbruk"),
            getLink(LenkeId.produksjonlandbruk, "Produksjon landbruk"),
            getLink(LenkeId.produksjondyrlandbruk, "Produksjonsdyr landbruk"),
            getLink(LenkeId.ulykkebonde, "Ulykke bonde/familie")
        ].sort((a, b) => (a?.label && b?.label ? a?.label.localeCompare(b?.label) : 0))
    };

    const insuranceLinks = [AnsatteLinks, KjoretoyLinks, DriftOgEiendomLinks, LandbrukLinks].sort((a, b) =>
        a.name.localeCompare(b.name)
    );

    const Y44Object = products?.find((product) => product.productCode === AvtaleProduktID.REISE_NERING);

    // Ikke vis innganger dersom kunden ikke har et gyldig kundenummer i NICE.
    const hasNiceCustomerNumber = useCustomerNumberCheck();

    // Ikke vis innganger for meglede kunder.
    const isManaged = useIsManaged();

    const shouldShowSelfService = (hasNiceCustomerNumber || Y44Object?.flowPath) && !isManaged;

    return (
        <Side>
            <SideHeader title="Kjøp forsikring" />
            <SideInnhold className="bm-getinsurance">
                {isLoading ? (
                    <Loader textDescription={"Henter dine tilbud"} />
                ) : (
                    shouldShowSelfService && (
                        <section className="bm-getinsurance__selfservice jkl-spacing-64--bottom">
                            <Typography variant="heading-2" className="jkl-spacing-l--bottom">
                                Kjøp forsikring selv
                            </Typography>
                            <div className="bm-getinsurance__selfservice__cards">
                                {Y44Object?.flowPath && (
                                    <HomePageCTAY44 shouldShow={Y44Object.flowPath} title="Reiseforsikring" />
                                )}
                                <HomePageCTAV05
                                    shouldShow={hasNiceCustomerNumber ? "/kjop/bil" : undefined}
                                    title="Bilforsikring"
                                />
                            </div>
                        </section>
                    )
                )}

                <section className="bm-getinsurance">
                    <Typography variant="heading-2" className="jkl-spacing-l--bottom">
                        Bestill gjennom rådgiver
                    </Typography>
                    <div className="bm-getinsurance__lists">
                        {insuranceLinks.map(
                            (category) =>
                                category.links.find((link) => link?.href) && (
                                    <div key={category.name}>
                                        <Typography
                                            component="h3"
                                            variant="heading-4"
                                            className="jkl-spacing-l--bottom"
                                        >
                                            {category.name}
                                        </Typography>
                                        <ul className="bm-getinsurance__lists__list">
                                            {category.links.map(
                                                (link, index) =>
                                                    link && (
                                                        <li key={index}>
                                                            <NavLink
                                                                href={link.href}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                data-testautoid={link.dataTestautoid}
                                                                data-lenkeid={link.lenkeId}
                                                                onClick={() =>
                                                                    track({
                                                                        hendelse: Trackingkey.LenkeTrykk,
                                                                        lenkeId: link.lenkeId ?? ""
                                                                    })
                                                                }
                                                            >
                                                                {link.label}
                                                            </NavLink>
                                                        </li>
                                                    )
                                            )}
                                        </ul>
                                    </div>
                                )
                        )}
                    </div>
                </section>
            </SideInnhold>
        </Side>
    );
}
