import { AgreementField, type AgreementDetails } from "src/model/gen";
import { useParams } from "react-router-dom";
import { Typography } from "@components/Typography";
import { Trackingkey, track } from "src/tracking";
import { toNorwegianDateFormat } from "src/common/formatting";

import styles from "./AgreementDetailSection.module.scss";
import { AgreementDetailInstance } from "./AgreementDetailInstance/AgreementDetailInstance";

type DetaljerProps = {
    agreementDetails: AgreementDetails;
};

export function AgreementDetailSection({ agreementDetails }: Readonly<DetaljerProps>) {
    const showAgreementPDF = agreementDetails.coreSystem !== "PARIS" && agreementDetails.coreSystem !== "PROLIFE";

    return (
        <div className={styles.topDetails}>
            <Typography variant="heading-2" component="h2">
                Detaljer
            </Typography>
            <dl className={styles.root}>
                {transformDetails(agreementDetails)
                    .concat(agreementDetails.additionalInfo ?? [])
                    .map(({ description, value, type }) => (
                        <AgreementDetailInstance
                            key={description}
                            description={description}
                            value={value}
                            type={type}
                        />
                    ))}
                {showAgreementPDF && (
                    <div className={styles.detail}>
                        <dt>Avtaledokument</dt>
                        <PdfLink />
                    </div>
                )}
            </dl>
        </div>
    );
}

function PdfLink() {
    const { agreementId } = useParams();

    return (
        <a
            className="jkl-link jkl-spacing-l--top"
            href={`/bedrift/api/dokumenter/avtale/pdf/${agreementId}`}
            download
            onClick={() => {
                track({
                    hendelse: Trackingkey.LastNed,
                    enkelt: false,
                    dokumenttype: "Avtaledokument"
                });
            }}
        >
            Last ned pdf
        </a>
    );
}

function transformDetails(detaljer: AgreementDetails): Array<AgreementField> {
    return [
        {
            description: "Avtalenummer",
            value: detaljer.agreementId.toString(),
            type: "STRING"
        },
        {
            description: "Årlig pris",
            value: detaljer.annualPremium.toString(),
            type: "CURRENCY"
        },
        {
            description: "Avtaleperiode",
            value: `${toNorwegianDateFormat(detaljer.startDate)} - ${toNorwegianDateFormat(detaljer.endDate)}`,
            type: "STRING"
        }
    ];
}
