import {
    // AgreementOverview as GenAvtaleDetaljer,
    AgreementDetails as GenAvtaleDetaljer,
    AgreementDetails as AvtaleDetaljerDetaljer,
    Vehicle as GenKjoretoy,
    AgreementOverview
} from "../../model/gen";

export enum AvtaleGruppeDesc {
    ANSATT = "Ansatte",
    KJORETOY = "Kjøretøy",
    DRIFT_OG_EIENDOM = "Drift og eiendom",
    FORENING_FORBUND = "Forening og forbund",
    UNKNOWN = "Ukjent"
}

export enum AvtaleProduktID {
    ANNEN_SYKDOM = "Z11",
    ANSVAR = "Y40",
    BEHANDLINGSFORSIKRING = "Z10",
    BIL_NAERING = "V05",
    EIENDELER = "Y24",
    FLATE_ARBEIDSMASKIN = "V41",
    FLATE_BIL = "V06",
    FLATE_LASTEBIL = "V07",
    ARSPROVEKJENNEMERKE = "V09",
    FLATE_TILHENGER = "V51",
    FORBUNDSGRUPPELIV = "616",
    FORENINGSGRUPPELIV = "615",
    LASTEBIL = "V20",
    NÆRINGSBYGG = "Y19",
    PERSONALGRUPPELIV = "610",
    REISE_NERING = "Y44",
    SYKELONN = "Z12",
    YRKESSKADE = "Z01",
    KOLLEKTIV_ULYKKE_BEDRIFT = "Z02",
    KOLLEKTIV_ULYKKE_BARN = "Z06",
    BYGNING_LANDBRUK = "T10",
    LOSORE_AVLING_FOR_LANDBRUK = "T20",
    DRIFTSTAP = "Y25",
    PROSJEKT_KONTRAKTSARBEIDER = "Y16",
    SMA_ELEKTRISKE_KJORETOY = "M14",
    BIL = "M05",
    LOFAVOR_BIL = "M07",
    ULYKKE = "P40",
    BUSS = "V30",
    TRAKTOR_ARBEIDSMASKIN_NERING = "V40",
    TILHENGER_NERING = "V50",
    NERINGSBAT = "V56",
    BOLIGBYGG_BORETTSLAG_SAMEIE = "Y06",
    KRIMINALITETSFORSIKRING = "Y36",
    TRANSPORT_OMSETNING_VARER = "Y45",
    TRANSPORT_TIDSFORSIKRING = "Y47",
    TRANSPORT_EGNE_VARER_BILER = "Y48",
    TRANSPORT_ENGANGSFORSIKRING = "Y49",
    CYBER = "Y50",
    SPESIALFORSIKRING_NERING = "Y60"
}

export enum AvtalePrispavirkendeFaktorVerdiType {
    STRING = "STRING",
    DATE = "DATE",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN"
}

export enum AvtaleNavn {
    BIL_NERING = "Bil næring"
}

export interface AvtalePeriode {
    start: string;
    slutt: string;
}

export const AvtaleProduktIDMedInnmelding = {
    REISE_NERING: AvtaleProduktID.REISE_NERING,
    BEHANDLINGSFORSIKRING: AvtaleProduktID.BEHANDLINGSFORSIKRING,
    ANNEN_SYKDOM: AvtaleProduktID.ANNEN_SYKDOM,
    SYKELONN: AvtaleProduktID.SYKELONN,
    PERSONALGRUPPELIV: AvtaleProduktID.PERSONALGRUPPELIV
};

export type Avtale = AgreementOverview & { produktId: AvtaleProduktID };

export type AvtaleDetaljerResponse = GenAvtaleDetaljer & { produktId: AvtaleProduktID };

export type AvtaleDetaljer = AvtaleDetaljerDetaljer;

export type Kjoretoy = GenKjoretoy;
