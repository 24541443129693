import { formatNumber } from "@fremtind/jkl-formatters-util";
import { InfoBlock, NavCard } from "@fremtind/jkl-card-react";
import { toNorwegianDateFormat } from "src/common/formatting";
import { NumberBasedAgreementDto } from "src/model/gen";
import "./AntallCard.scss";

interface Props {
    avtale: NumberBasedAgreementDto;
}

export const AntallCard = ({ avtale }: Props) => {
    const groupCount = avtale.numberBasedAgreementGroup.length;
    const employeeCount = avtale.numberedValue.value;

    return (
        <NavCard
            className="antall-card"
            title={avtale.productName}
            tag={
                avtale.changeable
                    ? { type: "info", text: "Du kan endre" }
                    : { type: "warning", text: "Kontakt rådgiver for endring" }
            }
            description={`${formatNumber(groupCount)} ${groupCount < 2 ? "objekt" : "objekter"}. ${formatNumber(
                employeeCount
            )} 
                        ${
                            avtale?.numberBasedAgreementGroup[0]?.numberedValue?.unit
                                ? avtale?.numberBasedAgreementGroup[0]?.numberedValue?.unit
                                : "ansatte"
                        }`}
            href={avtale.changeable ? `ansatte-antall/endre-antall/${avtale.id}` : "kontakt-oss"}
        >
            <InfoBlock>
                <div className="antall-card__footer">
                    <span>
                        Avtale
                        <br />
                        {avtale.agreementId}
                    </span>
                    <span>
                        Sist endret
                        <br />
                        {toNorwegianDateFormat(avtale.lastChangedDate)}
                    </span>
                </div>
            </InfoBlock>
        </NavCard>
    );
};
