import { AccordionItem } from "@fremtind/jkl-accordion-react";
import { model, utils } from "@features/faq";
import "./FaqItem.scss";
import { track, Trackingkey } from "../../../../tracking";

interface Props {
    item: model.FaqSchemaItem;
}

export function FaqItem({ item }: Props) {
    const parsedFaq = utils.useFaqItem(item);

    if (!parsedFaq) {
        return null;
    }

    return (
        <AccordionItem
            title={parsedFaq.heading}
            className="faq-item"
            onClick={() => {
                track({
                    hendelse: Trackingkey.Utvid,
                    kategori: item.category,
                    id: parsedFaq.heading
                });
            }}
        >
            {parsedFaq.content}
        </AccordionItem>
    );
}
