import { add, sub, startOfDay, endOfDay } from "date-fns";
import { MinMaxDate } from "./navneliste.model";

export const DEFAULT_INNMELDING_DATO: MinMaxDate = {
    min: startOfDay(sub(new Date(), { days: 30 })),
    max: endOfDay(add(new Date(), { days: 30 }))
};

export const DEFAULT_UTMELDING_DATO: MinMaxDate = {
    min: startOfDay(sub(new Date(), { days: 30 })),
    max: endOfDay(add(new Date(), { days: 30 }))
};
