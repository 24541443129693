import { http } from "@features/core";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "src/common/queryClient";
import type { ClaimOverview } from "src/model/gen";
//Mockdata for local testing:
//import { claimsOverviewMockData } from "./claims.mockdata";

const claimsQueryKey = {
    overview: [`claims-overview`]
};

export const getClaims = async () => {
    return http.get<ClaimOverview>(`/claims/overview`);
    //Mockdata for local testing:
    //return claimsOverviewMockData as ClaimDto[];
};

export const claimsListQuery = () => {
    return queryClient.fetchQuery({ queryKey: claimsQueryKey.overview, queryFn: getClaims });
};

export function useClaimsList() {
    const query = useQuery({
        queryKey: claimsQueryKey.overview,
        queryFn: getClaims,
        staleTime: Infinity,
        enabled: location.pathname !== "/feil"
    });

    return {
        query,
        data: query.data
    };
}
