import Cookie from "js-cookie";
import { sanitizeLoginUrl } from "src/common/utils";
import { feilkoder } from "src/model/feilkoder.object";

export interface EntityResponse<T> {
    entity: T;
    feilkoder: string[];
}

interface KundeFeilmelding {
    title: string;
    message: string;
}

export function apiRoute(route: string) {
    // const root = packageJson.homepage;
    const root = "/bedrift";
    const api = route.startsWith("/") ? "/api" : "/api/";
    return `${root}${api}${route}`;
}

const getHeaders = () => ({
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-XSRF-TOKEN": getXsrfToken()
});

const getDefaultArgs: () => RequestInit = () => ({ headers: getHeaders(), credentials: "include" });

/**
 * Resolve a title and a message based on an error code returned from the BM API.
 * @param errorCode Example: "forsikring.bm.avtale.nice.nede"
 */
function getFeilmelding(errorCode: string) {
    const feilmelding = errorCode.split(".").reduce<Record<string, any>>((p, c) => (p && p[c]) || null, feilkoder);

    if (feilmelding) {
        return feilmelding as KundeFeilmelding;
    }

    // hvis den klarer ikke å få feilmelding return default feilmelding.
    return feilkoder["default"] as KundeFeilmelding;
}

function getXsrfToken() {
    return String(Cookie.get("XSRF-TOKEN"));
}

/**
 * Fetch wrapper which handles the Response object returned from fetch().
 */
/*
async function fetcher<T>(request: string, method: string): Promise<T> {
    let res = await fetch(request);
    if (method === "POST") {
         res = await fetch(request, { method: "POST" });
    }
   
    if (!res.ok) {
        return handleError(res);
    }

    return handleSuccess(res);
}*/

async function fetcher<T>(request: Request): Promise<T> {
    const res = await fetch(request);

    if (!res.ok) {
        return handleError(res);
    }

    return handleSuccess(res);
}

/**
 * Takes care of reading the body of a request, ensuring that it's not a false positive response.
 */
async function handleSuccess(res: Response) {
    if (res.headers.get("content-type")?.includes("application/json")) {
        const body = await res.json();

        if (body.httpResponseStatus && body.requestId) {
            throw {
                status: res.status,
                ok: false,
                timestamp: Date.now(),
                body: body
            };
        } else {
            return Promise.resolve(body);
        }
    } else {
        return Promise.resolve();
    }
}

/**
 * Creates the error to throw if a response was not ok.
 */
async function handleError(res: Response) {
    let body;
    if (res.headers.get("content-type")?.includes("application/json")) {
        body = await res.json();

        if (res.status === 401) {
            const loginCompleteUrl = body["login-completeurl"] ?? "";

            if (loginCompleteUrl) {
                const url = sanitizeLoginUrl(new URL(loginCompleteUrl));

                window.location.href = url.toString();
            }
        }
    } else {
        body = await res.text();
    }

    return Promise.reject({
        status: res.status,
        ok: false,
        body
    });
}

//export async function get<T>(endpoint: string) {

export async function get<T>(endpoint: string): Promise<T> {
    const res = await fetch(apiRoute(endpoint), { method: "GET" });

    if (!res.ok) {
        return handleError(res);
    }

    return handleSuccess(res);
}

export async function getEntity<T>(endpoint: string) {
    const { entity, feilkoder } = await get<EntityResponse<T>>(endpoint);
    let feilmelding;
    if (feilkoder.length) {
        feilmelding = getFeilmelding(feilkoder[0]);
    }
    return { entity, feilmelding };
}

export async function post<T>(endpoint: string, body?: object): Promise<T> {
    /*  fetch('https://mywebsite.example/endpoint/', {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    firstParam: 'yourValue',
    secondParam: 'yourOtherValue',
  })
})*/

    const res = await fetch(apiRoute(endpoint), {
        method: "POST",
        body: body ? JSON.stringify(body) : undefined,
        ...getDefaultArgs()
    });

    if (!res.ok) {
        return handleError(res);
    }

    return handleSuccess(res);

    //return fetcher<T>(

    /* new Request(apiRoute(endpoint), {
            method: "POST",
            body: body ? JSON.stringify(body) : undefined,
            ...getDefaultArgs()
        })
    );*/
}

/**
 * Use when you need to get the raw response object from a fetch call. Eg. if you need to call response.blob()
 */
export async function postRaw(endpoint: string, body: object): Promise<Response> {
    const res = await fetch(apiRoute(endpoint), {
        method: "POST",
        body: JSON.stringify(body),
        ...getDefaultArgs()
    });

    if (!res.ok) {
        return Promise.reject(res);
    }

    return res;
}

export function put<T>(endpoint: string, body?: object): Promise<T> {
    return fetcher<T>(
        new Request(apiRoute(endpoint), {
            method: "PUT",
            body: body ? JSON.stringify(body) : undefined,
            ...getDefaultArgs()
        })
    );
}

export async function del<T>(endpoint: string, body?: object): Promise<T> {
    /*return fetcher<T>(
        new Request(apiRoute(endpoint), {
            method: "DELETE",
            body: body ? JSON.stringify(body) : undefined,
            ...getDefaultArgs()
        })
    );*/

    const res = await fetch(apiRoute(endpoint), {
        method: "DELETE",
        body: body ? JSON.stringify(body) : undefined,
        ...getDefaultArgs()
    });
    if (!res.ok) {
        return handleError(res);
    }

    return handleSuccess(res);
}
