import React, { useLayoutEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
    ErrorMessageBox,
    InfoMessageBox,
    MessageBoxProps,
    SuccessMessageBox,
    WarningMessageBox
} from "@fremtind/jkl-message-box-react";
import { Severity } from "./toast.d";
import "./Toast.scss";

const messageBoxMap: Record<Severity, React.FC<MessageBoxProps>> = {
    info: InfoMessageBox,
    success: SuccessMessageBox,
    error: ErrorMessageBox,
    warning: WarningMessageBox
};

interface ToastProps extends React.ComponentProps<typeof InfoMessageBox> {
    open: boolean;
    severity: Severity;
    onClose: () => void;
}

export function Toast({ open, severity, onClose, ...props }: ToastProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [rect, setRect] = useState<DOMRect>();

    useLayoutEffect(() => {
        setRect(ref.current?.getBoundingClientRect());
    }, [open]);

    const MessageBox = messageBoxMap[severity];

    return (
        <AnimatePresence initial={false}>
            <>
                {open && (
                    <motion.div
                        ref={ref}
                        className="toast"
                        initial={{ translateY: 300 }}
                        exit={{ translateY: 300 }}
                        animate={{ translateY: 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ translateX: -(rect?.width || 0) / 2 }}
                    >
                        <MessageBox
                            {...props}
                            role={severity === "error" ? "alert" : "status"}
                            data-testid="bm-toast"
                            dismissAction={{
                                handleDismiss: () => {
                                    onClose();
                                },
                                buttonTitle: "Lukk melding"
                            }}
                        />
                    </motion.div>
                )}
            </>
        </AnimatePresence>
    );
}
