import { HTMLProps } from "react";
import classNames from "classnames";
import "./Skeleton.scss";

interface SkeletonAnimationProps extends HTMLProps<HTMLDivElement> {}

export const SkeletonAnimation = ({ className, children }: SkeletonAnimationProps) => {
    const classes = classNames("skeleton-animation", className);

    return <div className={classes}>{children}</div>;
};

interface SkeletonElementProps extends Pick<HTMLProps<HTMLDivElement>, "style"> {
    shape?: "rect" | "circle";
    width: number;
    height: number;
}

export const SkeletonElement = ({ shape = "rect", width, height, style }: SkeletonElementProps) => {
    const classes = classNames("skeleton-element", `skeleton-element--${shape}`);

    return <div style={{ width, height, ...style }} className={classes}></div>;
};

export const SkeletonInput = () => {
    return (
        <div className="skeleton-input">
            <SkeletonElement width={115} height={16} />
            <SkeletonElement width={316} height={48} />
        </div>
    );
};

export const SkeletonButton = ({ width }: { width: number }) => {
    return <SkeletonElement width={width} height={40} style={{ borderRadius: "99px" }} />;
};

interface SkeletonTableProps {
    columns: number;
    rows: number;
}

export const SkeletonTable = (props: SkeletonTableProps) => {
    return (
        <div>
            <div className="skeleton-table skeleton-table__header">
                {Array.from(Array(props.columns)).map((_val, index) => (
                    <div key={index}>
                        <SkeletonElement width={60} height={16} />
                    </div>
                ))}
            </div>
            {Array.from(Array(props.rows)).map((_val, rowIndex) => (
                <div key={rowIndex} className="skeleton-table skeleton-table__row">
                    {Array.from(Array(props.columns)).map((_val, columnIndex) => (
                        <div key={columnIndex}>
                            <SkeletonElement width={rowIndex % 2 ? 120 : 180} height={19} />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
