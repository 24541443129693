import { agreementsListQuery, vehicleListQuery } from "@features/agreements/agreements.queries";
import { asAvtaleProduktId, isFlateAvtale } from "@features/agreements/avtale.utils";
import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";
import { queryClient } from "src/common/queryClient";
import { Bruker } from "src/model/bruker.typer";
import { Vehicle } from "src/model/gen";

interface VehicleList {
    agreementIndex: string;
    agreementNumber: string;
    vehicles: Array<Vehicle>;
}

export type VehicleListResponse = Array<VehicleList>;

export const loader = async () => {
    const bruker = await queryClient.ensureQueryData<Bruker>({ queryKey: brukerQueryKey, queryFn: fetchBruker });

    const gjeldendeOrganisasjonsnummer = bruker.gjeldendeOrganisasjonsnummer;

    if (gjeldendeOrganisasjonsnummer) {
        const agreementList = await agreementsListQuery(gjeldendeOrganisasjonsnummer);
        const flateAgreements = agreementList.agreements.filter((agreement) =>
            isFlateAvtale(asAvtaleProduktId(agreement.productCode))
        );

        const flateAgreementsVehicles: VehicleListResponse = await Promise.all(
            flateAgreements.map((agreement) => {
                return new Promise<VehicleList>((resolve) => {
                    vehicleListQuery(gjeldendeOrganisasjonsnummer, agreement.id).then((data) => {
                        resolve({
                            agreementIndex: agreement.id,
                            agreementNumber: agreement.agreementId,
                            vehicles: data
                        });
                    });
                });
            })
        );

        return {
            vehiclesResponse: flateAgreementsVehicles
        };
    }

    return Promise.resolve();
};
