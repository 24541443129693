import { BrukerDto } from "./gen";

export interface Bruker extends BrukerDto {
    roller: BrukerRolle[];
}

export type Organisasjon = {
    organisasjonsnummer: string;
    navn: string;
};

export enum BrukerRolle {
    ADMINISTRATOR = "ROLE_ADMINISTRATOR", // Administrator
    FORENING = "ROLE_FORENING", // Forening/forbunds-tilgang
    PERSONAL = "ROLE_PERSONAL", // Ansatt-tilgang
    SKADE = "ROLE_SKADE", // Drift, eiendom og kjøretøy-tilgang
    ADMINISTRATOR_DNB = "ROLE_ADMINISTRATOR_DNB", // Administrator i DNB
    FORSIKRING_DNB = "ROLE_FORSIKRING_DNB", // Drift, eiendom og kjøretøy-tilgang DNB
    RADGIVER_KUNDETILGANGER = "ROLE_RADGIVER_KUNDETILGANGER",
    RADGIVER = "ROLE_RADGIVER",
    RADGIVER_DNB = "ROLE_RADGIVER_DNB",
    RADGIVER_KONSESJON_SKADE = "ROLE_RADGIVER_KONSESJON_SKADE",
    RADGIVER_KONSESJON_LIV = "ROLE_RADGIVER_KONSESJON_LIV",
    ANY = "__ANY__" // brukes for å sjekke om en bruker har roller i det hele tatt
}
