interface IHttpErrorBody {
    timestamp: any;
    httpResponseStatus: number;
    clientErrorId?: string;
    traceId?: string;
    requestId?: string;
    [key: string]: any;
}

export interface IHttpError {
    status: number;
    ok: boolean;
    body: IHttpErrorBody;
    timestamp: number;
    redirect?: string;
}

export enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

export enum FetchState {
    IDLE = "idle",
    PENDING = "pending",
    RESOLVED = "resolved",
    REJECTED = "rejected"
}

export type FetchStatus = "idle" | "pending" | "resolved" | "rejected";

export enum Status {
    BAD_REQUEST = 400,
    NOT_FOUND = 404
}
