import React, { useMemo } from "react";
import { Trackingkey } from "src/tracking";

import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { toNorwegianDateFormat } from "../../../../common/formatting";
import { PrimaryButton } from "../../../../components/Button";
import { DocumentDto } from "../../../../model/gen";
import { IHttpError } from "../../../../model/http.typer";
import { DokumentListe } from "./DokumentListe";
import { DokumentRad } from "./DokumentListe/DokumentListeRad";

interface PropTypes {
    dokumenter: DocumentDto[];
    error?: IHttpError | null;
    tømFilter?: (event: React.MouseEvent) => void;
}

export function DokumentOversiktListe({ dokumenter, tømFilter, error }: PropTypes) {
    const kolonner = ["Dato", "Produkt", "Avtalenummer", "Dokumenttype", ""];

    const rader = useMemo(
        () =>
            dokumenter
                .filter((dokument) => (dokument.created ?? "").length !== 0)
                .map((dokument: DocumentDto) => {
                    const formatertDato =
                        dokument["created"] !== undefined ? toNorwegianDateFormat(dokument["created"]) ?? "" : "";

                    const produkt =
                        dokument.productNames && dokument.productNames.length === 1
                            ? dokument.productNames[0]
                            : "Skadeforsikringer";

                    const avtaleNummer =
                        (dokument.agreementNumbers ?? [])[0] === undefined
                            ? " "
                            : (dokument.agreementNumbers ?? []).length > 1
                            ? (dokument.agreementNumbers ?? []).length + " avtaler"
                            : (dokument.agreementNumbers ?? [])[0];

                    const dokumentType = dokument["name"] ?? "ukjent";

                    const radInnhold = [formatertDato, produkt, avtaleNummer, dokumentType];

                    const rad: DokumentRad = {
                        radInnhold: radInnhold,
                        key: dokument.id ?? "",
                        fakturaId: `${dokument.invoiceNumber}`,
                        tracking: dokumentType,
                        indeks: dokument.id ?? ""
                    };

                    return rad;
                }),
        [dokumenter]
    );

    if (error) {
        return (
            <ErrorMessageBox title="Oi, litt tekniske problemer her">
                <span data-testid="dokumenter-warning">Prøv en gang til eller kom tilbake senere.</span>
            </ErrorMessageBox>
        );
    }

    if (!rader.length) {
        return (
            <ErrorMessageBox title="Ingen dokumenter passer innstillingene dine">
                <span data-testid="dokumenter-warning" className="dokument-liste__warning">
                    Prøv å endre datovisning eller nullstill innstillingene dine.
                </span>
                <PrimaryButton
                    track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "dokumentliste-tøm-filter-ingen-treff" }}
                    dataTestautoid="dokument-nullstill-filter"
                    onClick={tømFilter}
                >
                    Tøm filter
                </PrimaryButton>
            </ErrorMessageBox>
        );
    }

    return <DokumentListe kolonner={kolonner} rader={rader} />;
}
