import { useMutation } from "@tanstack/react-query";

import { httpPost } from "../../common/api";
import { PrimaryButton } from "../../components/Button";
import { Side, SideHeader, SideInnhold } from "../../components/Side";
import { Toast } from "../../components/Toast";
import { updateTask, useTasks } from "../../features/tasks/tasks.queries";
import { Typography } from "../../components/Typography";
import { useBruker } from "../../features/bruker/queries";
import { ReelleRettighetshavereTabell } from "../../features/rrh/components/ReelleRettighetshavereContent";
import { RRHExpandableLovkrav } from "../../features/rrh/components/RRHExpandableLovkrav";
import { useReelleRettighetshavere } from "../../features/rrh/queries";
import { Trackingkey } from "../../tracking";

export function ReelleRettighetshavere() {
    const { valgtOrganisasjon } = useBruker();
    // fetch tasklist and find task within task.subjects named "Reelle rettighetshavere":
    const taskList = useTasks();
    const rrhTask = taskList.data?.find((task) => task.subjects?.includes("Reelle rettighetshavere"));

    const query = useReelleRettighetshavere();
    const bekreftRRH = (organisasjonsnummer: string) =>
        httpPost(organisasjonsnummer + "/reellerettighetshavere/bekreft");

    const confirmMutation = useMutation({ mutationFn: bekreftRRH });

    return (
        <Side>
            <SideHeader
                title="Reelle rettighetshavere"
                lead={
                    <>
                        <Typography className="jkl-spacing-m--bottom">
                            Ifølge hvitvaskingslovens § 14 er vi pålagt å innhente informasjon om såkalte reelle
                            rettighetshavere, hos bedrifter og stiftelser som har et avtaleforhold hos oss. Vennligst
                            bekreft at informasjonen under stemmer. Disse opplysningene kan du når som helst endre i
                            portalen.
                        </Typography>

                        <RRHExpandableLovkrav />
                    </>
                }
            />
            <SideInnhold
                loadingText="Henter liste over reelle rettighetshavere"
                query={query}
                className="bedrift-oversikt"
            >
                {query.data && (
                    <>
                        <ReelleRettighetshavereTabell rows={query.data.reelleRettighetshavere} />

                        <div className="jkl-spacing-2xl--top">
                            <Typography variant="heading-3">Bekreft reelle rettighetshavere</Typography>
                            <Typography className="jkl-spacing-l--bottom">
                                Jeg bekrefter at listen over reelle rettighetshavere er korrekt og fullstendig.
                            </Typography>

                            <PrimaryButton
                                onClick={() => {
                                    confirmMutation.mutate(valgtOrganisasjon!.organisasjonsnummer, {
                                        onSuccess: () => {
                                            rrhTask?.taskId && updateTask(rrhTask.taskId, "utført");
                                        }
                                    });
                                }}
                                dataTestautoid="bedrift-oversikt-knapp-bekreft-reel-rettighetshaver-liste"
                                track={{
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "bedrift-oversikt-knapp-bekreft-reel-rettighetshaver-liste"
                                }}
                                loader={{
                                    showLoader: confirmMutation.isPending,
                                    textDescription: "bekrefter reelle rettighetshavere"
                                }}
                                role="button"
                            >
                                Bekreft
                            </PrimaryButton>

                            <Typography variant="body" className="jkl-spacing-32--top">
                                {query.data.bekreftet || confirmMutation.isSuccess
                                    ? "Reelle rettighetshavere har blitt bekreftet."
                                    : "Reelle rettighetshavere har ikke blitt bekreftet."}
                            </Typography>
                        </div>
                        <Toast
                            severity="success"
                            open={confirmMutation.isSuccess}
                            onClose={confirmMutation.reset}
                            title="Du har bekreftet listen over reelle rettighetshavere"
                        >
                            Du kan fortsatt legge til rettighetshavere i listen.
                        </Toast>

                        <Toast
                            severity="error"
                            open={confirmMutation.isError}
                            onClose={confirmMutation.reset}
                            title="Vi har gjort en feil"
                        >
                            Beklager, vi har en feil i våre systemer. Prøv igjen senere eller kontakt oss på telefon.
                        </Toast>
                    </>
                )}
            </SideInnhold>
        </Side>
    );
}
