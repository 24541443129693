import { SecondaryButton } from "@fremtind/jkl-button-react";
import { useQuery } from "@tanstack/react-query";
import { API_ROOT } from "../../common/api";
import { track, Trackingkey } from "../../tracking";
import { downloadFile } from "../../common/utils";

const trackingID: Record<ExportEndpoint, string> = {
    ansattliste: "ansatte",
    medlemsliste: "medlemmer",
    avtaleoversikt: "avtaler",
    kjoretoy: "kjoretoy",
    "fakturaoversikt/BEDRIFT": "fakturaer-bedrift",
    "fakturaoversikt/FORENING_FORBUND": "fakturaer-forening"
};

export type ExportEndpoint =
    | "ansattliste"
    | "medlemsliste"
    | "avtaleoversikt"
    | "kjoretoy"
    | "fakturaoversikt/BEDRIFT"
    | "fakturaoversikt/FORENING_FORBUND";

interface Props {
    export: ExportEndpoint;
    postfix?: string;
    className?: string;
    handler?: () => Promise<any>;
}

export const ExcelExportLink = (props: Props) => {
    const handleDownload = () =>
        downloadFile(
            `${API_ROOT}exceluttrekk/${props.export}${props.postfix ?? ""}`,
            (res) => res.headers.get("content-disposition")!.split(";")[1].split("filename=")[1]
        );

    const query = useQuery({
        queryKey: [`exceluttrekk-${props.export}`],
        queryFn: props.handler ? props.handler : handleDownload,
        enabled: false
    });

    return (
        <SecondaryButton
            density="compact"
            onClick={() => {
                query.refetch();

                track({
                    hendelse: Trackingkey.Knappetrykk,
                    knappeId: `excel-export-${trackingID[props.export]}`
                });
            }}
            loader={{ showLoader: query.isFetching, textDescription: "laster ned excel uttrekk" }}
            className={props.className}
        >
            Eksporter til Excel
        </SecondaryButton>
    );
};
