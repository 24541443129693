import { useMemo, useRef, useState } from "react";
import { SuccessMessageBox } from "@fremtind/jkl-message-box-react";
import { useClickOutside } from "@fremtind/jkl-react-hooks";
import classnames from "classnames";
import { useQueryClient } from "@tanstack/react-query";
import { formatFodselsnummer } from "@fremtind/jkl-formatters-util";
import { toFormattedOrganisasjonsnummer } from "../../../../common/formatting";
import { SecondaryButton, TertiaryButton } from "../../../../components/Button";
import { TilgangerEndre } from "../TilgangerEndre";
import { TilgangerFjern } from "../TilgangerFjern";
import { getRolleFromTilgjengeligeTilganger } from "../../../../common/rolle";
import "./TilgangerKort.scss";
import { tilgangerQueryKeys, useDeleteTilganger, usePutTilganger } from "../../queries";
import { Rolle } from "../../../../model/Rolle";
import { Trackingkey } from "../../../../tracking";
import { Toast } from "../../../../components/Toast";
import { OrganisasjonTilgang, PersonTilgang, Tilganger, TilgjengeligTilgang } from "../../models";

const isPerson = (tilgang: PersonTilgang | OrganisasjonTilgang): tilgang is PersonTilgang => {
    return (tilgang as PersonTilgang).person !== undefined;
};

const isOrganisasjon = (tilgang: PersonTilgang | OrganisasjonTilgang): tilgang is OrganisasjonTilgang => {
    return (tilgang as OrganisasjonTilgang).organisasjon !== undefined;
};

type ObjektType = "person" | "organisasjon";
export type KortMode = "endre" | "fjerne" | null;
interface Props {
    tilgangsObjekt: PersonTilgang | OrganisasjonTilgang;
    objektType: ObjektType;
    rolleRader: number;
    visLoader?: boolean;
    apentKort: boolean;
    mode?: KortMode;
    onClick: (mode: KortMode | null) => void;
    tilgjengeligePersonTilganger: TilgjengeligTilgang[];
    tilgjengeligeOrganisasjonsTilganger: TilgjengeligTilgang[];
}

export const TilgangerKort = ({
    tilgangsObjekt,
    rolleRader,
    objektType,
    apentKort,
    mode,
    onClick,
    tilgjengeligePersonTilganger,
    tilgjengeligeOrganisasjonsTilganger
}: Props) => {
    const kortRef = useRef<HTMLElement>(null);
    const resetMode = () => onClick(null);

    const kortClassName = classnames("tilgang-kort", {
        "tilgang-kort--expanded": apentKort,
        "tilgang-kort--expanded-endre": mode === "endre" && apentKort,
        "tilgang-kort--expanded-fjerne": mode === "fjerne" && apentKort
    });

    const clickOutsideRef = useRef(null);

    const onClickOutside = () => {
        if (apentKort) {
            onClick(null);
        }
    };

    const content = useMemo(() => {
        if (isPerson(tilgangsObjekt)) {
            return {
                id: tilgangsObjekt.id,
                fodselsnummer: tilgangsObjekt.person.fodselsnummer,
                heading: tilgangsObjekt.person.fornavn + " " + tilgangsObjekt.person.etternavn,
                fornavn: tilgangsObjekt.person.fornavn,
                subHeading: formatFodselsnummer(tilgangsObjekt.person.fodselsnummer),
                roller: tilgangsObjekt.roller,
                automatiskOpprettet: tilgangsObjekt.automatisk,
                aktivBruker: tilgangsObjekt.aktivBruker,
                type: "person"
            };
        } else {
            return {
                id: tilgangsObjekt.organisasjon.organisasjonsnummer,
                heading: tilgangsObjekt.organisasjon.navn,
                subHeading: toFormattedOrganisasjonsnummer(tilgangsObjekt.organisasjon.organisasjonsnummer),
                roller: tilgangsObjekt.roller,
                type: "bedrift"
            };
        }
    }, [tilgangsObjekt]);

    useClickOutside(clickOutsideRef, onClickOutside);

    const queryClient = useQueryClient();

    const fjerneMutation = useDeleteTilganger();
    const endreMutation = usePutTilganger();

    const [showOppdatertPrompt, setShowOppdatertPrompt] = useState(false);

    const handleFjernKort = () => {
        if (isPerson(tilgangsObjekt)) {
            fjerneMutation.mutate({ id: tilgangsObjekt.id });
        }

        if (isOrganisasjon(tilgangsObjekt)) {
            fjerneMutation.mutate({ id: tilgangsObjekt.organisasjon.organisasjonsnummer, bedrift: true });
        }
    };

    const handleEndreKort = (roller: Rolle[]) => {
        const successHandler = (data: Tilganger) => {
            onClick(null);
            setShowOppdatertPrompt(true);
            queryClient.setQueryData(tilgangerQueryKeys.all, data);
            setTimeout(() => {
                setShowOppdatertPrompt(false);
            }, 3000);
        };

        if (isPerson(tilgangsObjekt)) {
            endreMutation.mutate({ id: tilgangsObjekt.id, data: { roller } }, { onSuccess: successHandler });
        }

        if (isOrganisasjon(tilgangsObjekt)) {
            endreMutation.mutate(
                { id: tilgangsObjekt.organisasjon.organisasjonsnummer, data: { roller }, bedrift: true },
                { onSuccess: successHandler }
            );
        }
    };

    if (fjerneMutation.isSuccess) {
        const text = isPerson(tilgangsObjekt)
            ? `Du har fjernet tilgangene til ${tilgangsObjekt.person.fornavn} ${tilgangsObjekt.person.etternavn}.`
            : `Du har fjernet tilgangene til ${tilgangsObjekt.organisasjon.navn}.`;

        return (
            <SuccessMessageBox
                className="tilgang-kort__message"
                data-testautoid="tilgang-kort-fjernet-melding"
                data-testid="tilgang-kort-fjernet-melding"
                title="Tilgang fjernet"
                dismissAction={{
                    handleDismiss: () => queryClient.setQueryData(tilgangerQueryKeys.all, fjerneMutation.data)
                }}
            >
                {text}
            </SuccessMessageBox>
        );
    }

    return (
        <span
            className="tilgang-kort__wrapper"
            ref={kortRef}
            style={{ minHeight: `${kortRef.current?.clientHeight}px` ?? 0 }}
        >
            <article
                key={content.id}
                className={kortClassName}
                aria-label={content.heading}
                ref={clickOutsideRef}
                aria-live="polite"
            >
                <header className="tilgang-kort__header">
                    <h3 aria-label={content.heading}>{content.heading}</h3>
                    <span className="jkl-sr-only">{content.type === "person" ? "Født" : "Organisasjonsnummer"}</span>
                    {content.subHeading}
                </header>

                <div className="tilgang-kort__content">
                    {!apentKort && (
                        <>
                            {showOppdatertPrompt ? (
                                <p
                                    data-testid="rollerad"
                                    className={`tilgang-kort__roller tilgang-kort__roller--${rolleRader}  tilgang-kort__roller--success jkl-spacing-m--top jkl-spacing-m--bottom`}
                                >
                                    Oppdatert
                                </p>
                            ) : tilgangsObjekt.roller.length === 1 ? (
                                tilgangsObjekt.roller.length && (
                                    <p
                                        data-testid="rollerad"
                                        className={`tilgang-kort__roller tilgang-kort__roller--${rolleRader} jkl-spacing-m--top jkl-spacing-m--bottom`}
                                    >
                                        {
                                            getRolleFromTilgjengeligeTilganger(
                                                tilgangsObjekt.roller[0],
                                                tilgjengeligePersonTilganger
                                            )?.label
                                        }
                                    </p>
                                )
                            ) : tilgangsObjekt.roller.length === 3 ? (
                                <p
                                    data-testid="rollerad"
                                    className={`tilgang-kort__roller tilgang-kort__roller--${rolleRader} jkl-spacing-m--top jkl-spacing-m--bottom`}
                                >
                                    Alle forsikringer
                                </p>
                            ) : (
                                <ul
                                    data-testid="rollerad"
                                    className={`tilgang-kort__roller tilgang-kort__roller--${rolleRader}`}
                                >
                                    {tilgangsObjekt.roller.map((rolle) => (
                                        <li key={rolle}>
                                            {
                                                getRolleFromTilgjengeligeTilganger(rolle, tilgjengeligePersonTilganger)
                                                    ?.label
                                            }
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                    {mode === "fjerne" && apentKort && (
                        <TilgangerFjern
                            objektType={objektType}
                            person={content.fornavn}
                            organisasjon={content.heading}
                            avbryt={resetMode}
                            bekreft={handleFjernKort}
                            mutation={fjerneMutation}
                        />
                    )}
                    {mode === "endre" && apentKort && (
                        <TilgangerEndre
                            tilgangsObjekt={tilgangsObjekt}
                            tilgjengeligeRoller={
                                objektType === "person"
                                    ? tilgjengeligePersonTilganger
                                    : tilgjengeligeOrganisasjonsTilganger
                            }
                            avbryt={resetMode}
                            bekreft={handleEndreKort}
                            mutation={endreMutation}
                        />
                    )}
                </div>
                {!apentKort && (
                    <footer
                        className={`tilgang-kort__footer ${content.aktivBruker ? "tilgang-kort__footer--short" : ""}`}
                    >
                        {content.type === "person" ? (
                            content.aktivBruker ? (
                                "Du kan ikke endre dine egne tilganger"
                            ) : (
                                <>
                                    {content.automatiskOpprettet ? null : (
                                        <SecondaryButton
                                            className="jkl-spacing-m--right"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onClick("endre");
                                            }}
                                            density="compact"
                                            data-testid="tilganger-endre-person"
                                            dataTestautoid="tilganger-endre-person"
                                            track={{
                                                hendelse: Trackingkey.Knappetrykk,
                                                knappeId: "tilganger-endre-person"
                                            }}
                                        >
                                            Endre tilganger
                                        </SecondaryButton>
                                    )}
                                    <TertiaryButton
                                        track={{
                                            hendelse: Trackingkey.Knappetrykk,
                                            knappeId: "tilganger-fjern-person"
                                        }}
                                        data-testid="tilganger-fjern-person"
                                        dataTestautoid="tilganger-fjern-person"
                                        density="compact"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onClick("fjerne");
                                        }}
                                    >
                                        Fjern tilgang
                                    </TertiaryButton>
                                </>
                            )
                        ) : (
                            <>
                                <SecondaryButton
                                    className="jkl-spacing-m--right"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClick("endre");
                                    }}
                                    density="compact"
                                    data-testid="tilganger-endre-bedrift"
                                    dataTestautoid="tilganger-endre-bedrift"
                                    track={{
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "tilganger-endre-bedrift"
                                    }}
                                >
                                    Endre tilganger
                                </SecondaryButton>
                                <TertiaryButton
                                    track={{
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "tilganger-fjern-bedrift"
                                    }}
                                    data-testid="tilganger-fjern-bedrift"
                                    dataTestautoid="tilganger-fjern-bedrift"
                                    density="compact"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClick("fjerne");
                                    }}
                                >
                                    Fjern tilgang
                                </TertiaryButton>
                            </>
                        )}
                    </footer>
                )}

                <Toast
                    open={fjerneMutation.isError}
                    onClose={fjerneMutation.reset}
                    severity="error"
                    title="Endring mislyktes"
                >
                    Vi kunne ikke fjerne tilgangen nå. Prøv en gang til eller kom tilbake senere.
                </Toast>

                <Toast
                    open={endreMutation.isError}
                    onClose={endreMutation.reset}
                    severity="error"
                    title="Endring mislyktes"
                >
                    Vi kunne ikke endre tilgangen nå. Prøv en gang til eller kom tilbake senere.
                </Toast>
            </article>
        </span>
    );
};
