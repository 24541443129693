import { useForm } from "react-hook-form";
import { Rolle } from "../../../../model/Rolle";
import { Kvittering } from "./Kvittering";
import { TilgangerLeggTilSkjema } from "./TilgangerLeggTilSkjema";
import { SkjemaFlyt, Steg } from "../../../../components/SkjemaFlyt";
import { Folkeregister } from "./Folkeregister";

export interface FormDataPerson {
    fornavn: string;
    etternavn: string;
    fodselsnummer: string;
    roller: Rolle[];
    tilgang: "administrator" | "endre";
}

const stegListe: Steg[] = [
    {
        element: Folkeregister,
        stegnavn: "folkeregister"
    },
    {
        element: TilgangerLeggTilSkjema,
        stegnavn: "velg-tilgang"
    },
    {
        element: Kvittering,
        stegnavn: "kvittering"
    }
];

export const SkjemaKontroller = () => {
    const formMethods = useForm<FormDataPerson>();

    return <SkjemaFlyt flytnavn="tilganger/legg-til-person" formMethods={formMethods} stegListe={stegListe} />;
};
