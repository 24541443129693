/* eslint-disable react/no-unescaped-entities */
import { LenkeId, useExternalLinks } from "src/common/lenker";
import { Side, SideInnhold } from "src/components/Side";
import { Trackingkey, track } from "src/tracking";
import { useTasks } from "@features/tasks/tasks.queries";
import { TaskList } from "@features/tasks/components/TaskList/TaskList";
import { Tilbakemelding } from "@components/TilbakeMelding";
import { Typography } from "@components/Typography";
import { useBruker } from "@features/bruker/queries";
import { Await } from "react-router-dom";
import { NavCard } from "@fremtind/jkl-card-react";
import { Link } from "react-router-dom";
import { AlertSection } from "./components/AlertSection";
import { HomeHeader } from "./components/header/HomeHeader";
import "./Hjem.scss";

export function Hjem() {
    const { valgtOrganisasjon, bruker } = useBruker();

    const taskList = useTasks();
    const privatForsikringLink = useExternalLinks().getLinkByLinkId(LenkeId.privatforsikring)?.href;
    const pensjonLink = useExternalLinks().getLinkByLinkId(LenkeId.pensjon)?.href;
    const sendClaimLink = useExternalLinks().getLinkByLinkId(LenkeId.meldSkade)?.href;

    return (
        <Side>
            <HomeHeader
                organizationName={valgtOrganisasjon?.navn ?? ""}
                organizationNumber={valgtOrganisasjon?.organisasjonsnummer ?? ""}
            />
            <SideInnhold className="bm-home" footerProps={{ feedback: false }}>
                <Await resolve={taskList}>
                    {(tasks) => (
                        <TaskList
                            heading={<Typography variant="heading-3">Dine oppgaver</Typography>}
                            className="oppgaver"
                            category="Oppgave"
                            status={["sendt", "lest"]}
                            tasks={tasks.data}
                            sort={{ sortBy: "createdDate", order: "desc" }}
                        />
                    )}
                </Await>
                {bruker && <AlertSection bruker={bruker} />}
                <section className="shortcuts">
                    <Typography variant="heading-3">Snarveier</Typography>
                    <ul>
                        <li>
                            <NavCard
                                title="Dine forsikringer"
                                description="Avtalene du har hos oss i dag"
                                component={Link}
                                to="/forsikringer"
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "forsikringer",
                                        type: "forside"
                                    });
                                }}
                            />
                        </li>
                        <li>
                            <NavCard
                                title="Faktura"
                                description="Se alle fakturaer for bedriften din"
                                component={Link}
                                to="/faktura"
                            />
                        </li>
                        <li>
                            <NavCard
                                title="Dokumenter"
                                description="Informasjon vi har sendt bedriften din"
                                component={Link}
                                to="/dokumenter"
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "dokumenter",
                                        type: "forside"
                                    });
                                }}
                            />
                        </li>
                        <li>
                            <NavCard
                                title="Kjøp forsikring"
                                description="Se alle forsikringene vi tilbyr"
                                component={Link}
                                to="/forsikringer/kjop-forsikring"
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "kjøp forsikring",
                                        type: "forside"
                                    });
                                }}
                            />
                        </li>
                        <NavCard
                            title="Dine skadesaker"
                            description="Dine pågående og tidligere skadesaker"
                            component={Link}
                            to="/skadesaker"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: "skadesaker",
                                    type: "forside"
                                });
                            }}
                        />
                        {sendClaimLink && (
                            <NavCard
                                title="Meld skade"
                                description="Start en ny skadesak"
                                href={sendClaimLink}
                                target="_blank"
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "meld-skade",
                                        type: "forside"
                                    });
                                }}
                            />
                        )}
                    </ul>
                </section>
                <Await resolve={taskList}>
                    {(tasks) => (
                        <TaskList
                            className={"aktuelt"}
                            heading={<Typography variant="heading-3">Aktuelt for deg</Typography>}
                            category="Aktuelt"
                            status={["sendt", "lest"]}
                            tasks={tasks.data}
                            sort={{ sortBy: "createdDate", order: "desc" }}
                            showImages={true}
                            showOffers={true}
                        ></TaskList>
                    )}
                </Await>
                <section className="other">
                    <Typography variant="heading-3">Ser du etter disse?</Typography>
                    {pensjonLink && (
                        <NavCard
                            title="Pensjon"
                            description="Oversikt over pensjonssparingen din"
                            href={pensjonLink}
                            target="_blank"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: "pensjon",
                                    type: "forside"
                                });
                            }}
                        />
                    )}
                    {privatForsikringLink && (
                        <NavCard
                            title="Dine privatforsikringer"
                            description="Forsikringer registrert på deg som person"
                            href={privatForsikringLink}
                            target="_blank"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: "privatforsikring",
                                    type: "forside"
                                });
                            }}
                        />
                    )}
                </section>
                <Tilbakemelding
                    label="Hva syns du om den nye forsiden?"
                    tracking={
                        {
                            hendelse: Trackingkey.TilbakemeldingScore,
                            spørsmål: "Hva syns du om den nye forsiden?",
                            side: "forside"
                        } as any
                    }
                />
            </SideInnhold>
        </Side>
    );
}
