import { Suspense, useEffect, useMemo } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { LenkeId, useExternalLinks } from "src/common/lenker";
import { visAnsattLenke } from "src/common/rolle";
import { AgreementOverviewResponse } from "src/model/gen";
import { AnsattbrosjyreButton } from "src/sider/forsikringer/components/AnsattbrosjyreButton";
import { registerTrackingProps, track, Trackingkey } from "src/tracking";

import { ExcelExportLink } from "@components/ExcelExportLink";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { model, utils } from "@features/agreements";
import { asAvtaleProduktId } from "@features/agreements/avtale.utils";
import { NavLink } from "@fremtind/jkl-core";
import { Loader } from "@fremtind/jkl-loader-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { Forsikringer } from "./AgreementsOverviewComposer";

const ErrorBoundary = () => {
    return (
        <ErrorMessageBox title="Oi, litt tekniske problemer her">
            Vi klarer ikke hente avtalene dine. Prøv en gang til eller kom tilbake senere.
        </ErrorMessageBox>
    );
};

export const AgreementsOverview = () => {
    const { agreementOverviewResponse } = useLoaderData() as { agreementOverviewResponse: AgreementOverviewResponse };

    const { getLinkByLinkId } = useExternalLinks();
    const privatForsikringLink = getLinkByLinkId(LenkeId.privatforsikring)?.href;
    const meldSkadeLink = getLinkByLinkId(LenkeId.meldSkade);
    const hideFooter = agreementOverviewResponse?.agreements?.length === 0;

    const showAnsatteListe = useMemo(
        () =>
            agreementOverviewResponse?.agreements?.some((avtale) =>
                Object.values(model.AvtaleProduktID).includes(asAvtaleProduktId(avtale.productCode))
            ) && visAnsattLenke(agreementOverviewResponse.agreements),
        [agreementOverviewResponse]
    );

    useEffect(() => {
        if (!agreementOverviewResponse.agreements) {
            return;
        }

        registerTrackingProps({ harAnsattListe: showAnsatteListe });
    }, [showAnsatteListe, agreementOverviewResponse]);

    useEffect(() => {
        if (!agreementOverviewResponse.agreements) {
            return;
        }

        registerTrackingProps({
            harFlåte: agreementOverviewResponse.agreements.some((avtale) =>
                utils.isFlateAvtale(asAvtaleProduktId(avtale.productCode))
            )
        });
    }, [agreementOverviewResponse]);

    return (
        <Side className="agreement-overview">
            <SideHeader
                toolbar={
                    <>
                        {meldSkadeLink && (
                            <a
                                href={meldSkadeLink.href}
                                className="jkl-button jkl-button--primary"
                                data-density="compact"
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "meld-skade"
                                    });
                                }}
                            >
                                {meldSkadeLink.label}
                            </a>
                        )}
                        <AnsattbrosjyreButton />
                        <ExcelExportLink export="avtaleoversikt" />
                    </>
                }
                title="Dine forsikringer"
            />
            <SideInnhold
                hideFooter={hideFooter}
                footerProps={{ feedbackProps: { label: "Hvor fornøyd er du med kundeløsningen?" } }}
            >
                <Suspense fallback={<Loader textDescription="Henter forsikringer" />}>
                    <Await resolve={agreementOverviewResponse} errorElement={<ErrorBoundary />}>
                        {(data: AgreementOverviewResponse) => (
                            <>
                                <Forsikringer
                                    agreements={data.agreements ?? []}
                                    errorMessages={data.clientErrorMessages}
                                />
                                {privatForsikringLink && (
                                    <NavLink
                                        href={privatForsikringLink}
                                        target="_blank"
                                        onClick={() => {
                                            track({
                                                hendelse: Trackingkey.LenkeTrykk,
                                                lenkeId: "forsikringsoversikt privatforsikring"
                                            });
                                        }}
                                    >
                                        Gå til dine privatforsikringer
                                    </NavLink>
                                )}
                            </>
                        )}
                    </Await>
                </Suspense>
            </SideInnhold>
        </Side>
    );
};
