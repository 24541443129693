import React, { HTMLProps } from "react";
import "./Snarveier.scss";

type SnarveierProps = {
    alignment?: "horizontal" | "vertical";
    heading?: React.ReactNode;
} & HTMLProps<HTMLDivElement>;

export const SnarveierFlexible = ({ alignment = "vertical", heading, children, ...props }: SnarveierProps) => {
    const snarveier = React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
            return;
        }

        return <li key={index}>{child}</li>;
    });

    const snarveiCount = snarveier?.length;

    return (
        <div className="snarveier" {...props}>
            {heading && heading}
            <div className={`snarveier__wrapper snarveier__wrapper--alignment-${alignment}`}>
                {snarveiCount ? (
                    <>
                        {snarveiCount > 0 && <ul>{snarveier?.slice(0, 5)}</ul>}
                        {snarveiCount >= 5 && <ul>{snarveier?.slice(5, 9)}</ul>}
                    </>
                ) : null}
            </div>
        </div>
    );
};
