import { useContext, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Link as JklLink } from "@fremtind/jkl-core";
import { css } from "@emotion/react";
import { SkeletonAnimation, SkeletonElement } from "@fremtind/jkl-loader-react";
import { useBruker } from "@features/bruker/queries";
import { useInvoiceReminders } from "@features/invoice/invoice.queries";
import {
    calculateInvoiceReminderState,
    getMostCriticalInvoice,
    getTagForStatus,
    mapInvoiceReminderStateToInvoiceEvent
} from "@features/invoice/components/InvoiceReminder/utils";
import { HeaderContext } from "src/context/HeaderContext";
import { SecondaryButton, SecondaryLinkButton } from "src/components/Button";
import { isRadgiver } from "src/common/rolle";
import ThemeContext from "src/context/ThemeContext";
import { toFormattedOrganisasjonsnummer } from "src/common/formatting";
import { track, Trackingkey } from "src/tracking";
import { useExternalLinks } from "src/common/lenker";
import { agreementStatusIsRenewal } from "@features/agreements/avtale.utils";
import { useAgreementList } from "@features/agreements/agreements.queries";
import { InfoTag } from "@fremtind/jkl-tag-react";
import { LinkListe } from "../../LinkListe";
import "./SideMeny.scss";
import { MenySchema, useMenySchema } from "../schema";

export function SideMeny() {
    const { visOrganisasjon } = useContext(HeaderContext);
    const { valgtOrganisasjon, bruker } = useBruker();
    const clickOutsideRef = useRef<HTMLElement>(null);
    const { distributor } = useContext(ThemeContext);
    const navigate = useNavigate();
    const { links } = useExternalLinks();
    const location = useLocation();
    const [menySchema, isLoadingMenySchema] = useMenySchema();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const { data: invoiceReminderData } = useInvoiceReminders();
    const avtaleFetcher = useAgreementList();

    const agreementHasFornyelse = avtaleFetcher.data?.agreements.some(agreementStatusIsRenewal);

    const FakturaWarning = useMemo(() => {
        const invoiceReminder = getMostCriticalInvoice(invoiceReminderData?.entity.invoiceReminders ?? []);
        if (invoiceReminder) {
            const invoiceReminderState = calculateInvoiceReminderState(invoiceReminder);
            if (invoiceReminderState) {
                const invoiceEvent = mapInvoiceReminderStateToInvoiceEvent(invoiceReminderState);
                const [Tag] = getTagForStatus("UBETALT", invoiceReminder.invoiceDueDate, invoiceEvent);
                return Tag;
            }
        }

        return null;
    }, [invoiceReminderData?.entity.invoiceReminders]);

    useEffect(() => {
        if (menuIsOpen) {
            setMenuIsOpen(false);
        }
    }, [history.length]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (menuIsOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [menuIsOpen]);

    if (!bruker) {
        return null;
    }

    if (!bruker.loggetInn) {
        return null;
    }

    const wrapperClassname = cx("sidemeny", {
        "sidemeny--with-brandheader": distributor !== "meg",
        "sidemeny--bedriftsvelger": bruker.harTilgangTilFlereOrganisasjoner && !visOrganisasjon,
        "sidemeny--open": menuIsOpen
    });

    const ActionButtons = () => {
        return (
            <div
                className="buttons"
                css={css`
                    display: flex;
                    gap: 1rem;
                `}
            >
                {!isRadgiver(bruker) && (
                    <SecondaryButton
                        density="compact"
                        className="sidemeny__loggut"
                        track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "loggut-desktop" }}
                        onClick={() => navigate("/loggut")}
                        dataTestautoid="header-loggut-desktop"
                    >
                        Logg ut
                    </SecondaryButton>
                )}
                {bruker.valgtOrganisasjon && (
                    <SecondaryLinkButton
                        to={"/"}
                        density="compact"
                        className="sidemeny__loggut"
                        hidden={!bruker.harTilgangTilFlereOrganisasjoner}
                        data-testid="knapp-bytt-bedrift"
                        data-testautoid="knapp-bytt-bedrift"
                        onClick={() => {
                            setMenuIsOpen(false);
                        }}
                    >
                        Bytt bedrift
                    </SecondaryLinkButton>
                )}
            </div>
        );
    };

    const ExternalLinks = () => (
        <div className="sidemeny__meny-bottom">
            <LinkListe>
                {links
                    .filter((link) => link.showInMegameny)
                    .map((props) => (
                        <li key={props.lenkeId}>
                            <JklLink
                                href={props.href}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-testautoid={props.dataTestautoid}
                                data-lenkeid={props.lenkeId}
                                onClick={() =>
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: props.lenkeId,
                                        type: "hovednavigasjon",
                                        tilgjengeligeValg: menySchema
                                            .map((block) => block.items.map((item) => item.to))
                                            .flat()
                                    })
                                }
                            >
                                {props.label}
                            </JklLink>
                        </li>
                    ))}
            </LinkListe>
        </div>
    );

    const MenuItems = ({ menuBlock }: { menuBlock: MenySchema }) => {
        return (
            <>
                {menuBlock.items.map((menuItem) => {
                    const pathElements = location.pathname.split("/");
                    const otherMatches = menuBlock.items.filter((item) => item.to === location.pathname);

                    // Dersom vi en underside av en side også er med i menyen, og vi er på den siden, skal ikke foreldresiden være aktiv
                    const isCurrentPath =
                        menuItem.to === location.pathname ||
                        (otherMatches.length === 0 && pathElements.length > 1 && menuItem.to === `/${pathElements[1]}`);

                    return (
                        <li key={menuBlock.heading + menuItem.label}>
                            <Link
                                to={menuItem.to}
                                className={`jkl-link ${isCurrentPath ? "jkl-link--active" : ""}`}
                                data-testautoid={`megameny-${menuItem.to.replace("/", "")}`}
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: menuItem.to.replace("/", ""),
                                        type: "hovednavigasjon",
                                        tilgjengeligeValg: menySchema
                                            .map((block) => block.items.map((item) => item.to))
                                            .flat()
                                    });
                                }}
                            >
                                {menuItem.label}{" "}
                                {agreementHasFornyelse && menuItem.label === "Forsikringer" ? (
                                    <InfoTag density="compact" style={{ float: "right" }}>
                                        Fornyelse
                                    </InfoTag>
                                ) : FakturaWarning && menuItem.label === "Faktura" ? (
                                    <FakturaWarning density="compact" style={{ float: "right" }}>
                                        Ubetalt
                                    </FakturaWarning>
                                ) : null}
                            </Link>
                        </li>
                    );
                })}
            </>
        );
    };

    return (
        <aside className={wrapperClassname} ref={clickOutsideRef} data-testid="megameny" data-testautoid="megameny">
            <div className="sidemeny__header">
                <div className="sidemeny__org">
                    {isLoadingMenySchema && !bruker.harTilgangTilFlereOrganisasjoner && !valgtOrganisasjon ? (
                        <SkeletonAnimation role="none presentation">
                            <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%" }}>
                                <SkeletonElement width={"100%"} height={16} />
                                <SkeletonElement width={"60%"} height={16} />
                            </div>
                        </SkeletonAnimation>
                    ) : (
                        <>
                            <p>{valgtOrganisasjon?.navn}</p>
                            <p>{toFormattedOrganisasjonsnummer(valgtOrganisasjon?.organisasjonsnummer ?? "")}</p>
                        </>
                    )}
                </div>
                <SecondaryButton
                    id="hamburger"
                    onClick={(e) => {
                        e.stopPropagation();
                        setMenuIsOpen(!menuIsOpen);
                    }}
                    track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "åpne-lukk-sidemeny", dropdown: true }}
                    density="compact"
                    aria-controls="sidemeny"
                    className="sidemeny__hamburger"
                    dataTestautoid="sidemeny-åpne-lukk"
                >
                    <span className="jkl-sr-only">{menuIsOpen ? "Lukk meny" : "Åpne meny"}</span>
                    {menuIsOpen ? "Lukk" : "Meny"}
                </SecondaryButton>
            </div>

            <div className={`sidemeny__content sidemeny__content--${menuIsOpen ? "open" : "closed"}`}>
                {valgtOrganisasjon && (
                    <nav
                        className="sidemeny__meny"
                        data-testid="megameny-nav"
                        data-testautoid="megameny-nav"
                        id="sidemeny"
                    >
                        {isLoadingMenySchema ? (
                            <SkeletonAnimation
                                role="none presentation" // Kun for eksempelet, ellers blir det mange alerts p� siden
                            >
                                <div style={{ display: "flex", flexDirection: "column", gap: 20, marginBottom: 40 }}>
                                    <SkeletonElement width={"100%"} height={16} />
                                    <SkeletonElement width={"100%"} height={16} />
                                    <SkeletonElement width={"100%"} height={16} />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                                    <SkeletonElement width={"100%"} height={16} />
                                    <SkeletonElement width={"100%"} height={16} />
                                    <SkeletonElement width={"100%"} height={16} />
                                </div>
                            </SkeletonAnimation>
                        ) : (
                            <>
                                {menySchema.length > 0 && (
                                    <>
                                        <ul className="sidemeny__meny-list">
                                            {menySchema.map((menuBlock) => (
                                                <li key={menuBlock.heading} className={menuBlock.class}>
                                                    <LinkListe>
                                                        <MenuItems menuBlock={menuBlock} />
                                                    </LinkListe>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </>
                        )}
                    </nav>
                )}
                <div className="sidemeny__bottom">
                    <ActionButtons />
                    <ExternalLinks />
                </div>
            </div>
        </aside>
    );
}
