import { queryClient } from "src/common/queryClient";
import { http } from "@features/core";
import { EntityResponse } from "@features/core/http";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { safeClone } from "src/common/utils";
import { cloneDeep } from "lodash";
import { OpprettKAMTilgangRequest } from "../../model/gen";
import { TTPKam, TilgangerTredjePart, TTPBedrift, TTPOversiktResponse } from "./models";

const url = "tredjeparts-tilgang";

export const ttpQueryKeys = {
    all: ["tilganger-tredje-part"]
};

const toKamList = (bedrifter: TTPBedrift[]): TTPKam[] => {
    const kamer: any[] = [];

    bedrifter.forEach((bedrift) => {
        bedrift.kamer.forEach((kam) => {
            const index = kamer.findIndex(({ kam: entry }) => entry.fodselsnummer === kam.kam.fodselsnummer);

            if (index >= 0) {
                kamer[index].bedrifter.add(bedrift);
            } else {
                const entry = { ...kam, bedrifter: new Set() };
                entry.bedrifter.add(bedrift);
                kamer.push(entry);
            }
        });
    });

    return kamer.map((kam) => ({ ...kam, bedrifter: Array.from(kam.bedrifter) }));
};

const transformResponse = (res: TTPOversiktResponse): TilgangerTredjePart => {
    const bedrifter = [...res.bedrifter].sort((a, b) => a.bedrift.navn.localeCompare(b.bedrift.navn));

    const kamer = toKamList(bedrifter).sort((a, b) => a.kam.fornavn.localeCompare(b.kam.fornavn));

    return { bedrifter, kamer };
};

const getTilgangerTredjePart = async () => {
    return await http.getEntity<TTPOversiktResponse>(url);
};

export const useTilgangerTredjePart = () => {
    const query = useQuery({
        queryKey: [ttpQueryKeys.all],
        queryFn: getTilgangerTredjePart,
        staleTime: 1000 * 1,
        refetchOnWindowFocus: false,
        select: ({ entity, feilmelding }) => ({ tilganger: transformResponse(entity), feilmelding })
    });

    return query;
};

export const TTPQuery = () => {
    return queryClient.fetchQuery({
        queryKey: [ttpQueryKeys.all],
        queryFn: getTilgangerTredjePart,
        staleTime: 1000 * 1
    });
};

const addTilgangerTredjePart = async (payload: OpprettKAMTilgangRequest) => {
    return http.post(`${url}/opprett`, payload);
};

export const useAddTilgangerTredjePart = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: addTilgangerTredjePart,
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: [ttpQueryKeys.all]
            })
    });

    return mutation;
};

const removeTilgangerTredjePart = async (body: { fodselsnummer: string; organisasjonsnummer: string }) => {
    return http.del(`${url}/slett`, body);
};

export const useRemoveTilgangerTredjePart = () => {
    const mutation = useMutation({ mutationFn: removeTilgangerTredjePart });

    return mutation;
};

export const removeTilgangFromCache = (
    data: EntityResponse<TTPOversiktResponse>,
    organisasjonsnummer: string,
    fodselsnummer: string
): EntityResponse<TTPOversiktResponse> => {
    let dataClone: EntityResponse<TTPOversiktResponse> | undefined = safeClone(data);

    if (typeof structuredClone !== "undefined") {
        dataClone = structuredClone(data);
    } else {
        dataClone = cloneDeep(data);
    }

    if (!dataClone) {
        return data;
    }

    const bedrifter = dataClone.entity.bedrifter;
    const relevantBedriftIndex = bedrifter.findIndex(
        (entity) => entity.bedrift.organisasjonsnummer === organisasjonsnummer
    )!;

    const updatedKamer = bedrifter[relevantBedriftIndex].kamer.filter(
        (entity) => entity.kam.fodselsnummer !== fodselsnummer
    );

    bedrifter[relevantBedriftIndex].kamer = updatedKamer;

    return dataClone;
};
