/* eslint-disable react/no-unescaped-entities */
import { FaqList } from "@features/faq/components";
import { faq } from "../../../features/faq/faq.object";
import { FaqCategory, FaqSchemaItem } from "../../../features/faq/faq.model";

const faqMappedByCategory = faq.reduce((list, question) => {
    const addToList = (key: FaqCategory, value: FaqSchemaItem) => {
        if (!list[key]) {
            list[key] = [];
        }

        list[key].push(value);
    };

    if (Array.isArray(question.category)) {
        for (const category of question.category) {
            addToList(category, question);
        }
    } else {
        addToList(question.category, question);
    }

    return list;
}, {} as Record<FaqCategory, FaqSchemaItem[]>);

const faqSchema = Object.entries(faqMappedByCategory).map(([category, items]) => ({
    heading: category as FaqCategory,
    items
}));

export function Faq() {
    return <FaqList categories={faqSchema} />;
}
