import { useQuery } from "@tanstack/react-query";
import { queryClient } from "src/common/queryClient";
import { Organisasjon } from "src/model/gen";
import { http } from "@features/core";

const organizationQueryKey = ["organisasjon"];

const fetchOrganizations = async () => {
    const res = await http.get<Organisasjon[]>("organisasjoner");
    return res;
};

export const organizationQuery = () => {
    return queryClient.fetchQuery({
        queryKey: [organizationQueryKey],
        queryFn: fetchOrganizations
    });
};

export const useOrganizations = () => {
    return useQuery({ queryKey: [organizationQueryKey], queryFn: fetchOrganizations });
};
