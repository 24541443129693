import { useState } from "react";
import { SecondaryButton } from "@fremtind/jkl-button-react";
import { useNavigate } from "react-router";
import { FolkeregisterSjekk } from "../../../../components/FolkeregisterSjekk";
import { TilgangerTredjePart, PersonEntity } from "../../models";
import { TTPNyTilgang } from "../TTPNyTilgang";
import { track, Trackingkey } from "../../../../tracking";

interface Props {
    tilganger: TilgangerTredjePart;
}

export const TTPNyBedriftsansvarlig = ({ tilganger }: Props) => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [person, setPerson] = useState<PersonEntity>();

    if (step === 2) {
        return (
            <TTPNyTilgang
                flytnavn="ny bedriftsansvarlig"
                subject={person!}
                options={tilganger.bedrifter.map(({ bedrift }) => bedrift)}
                backButton={<SecondaryButton onClick={() => setStep((prev) => prev - 1)}>← Forrige</SecondaryButton>}
                doneLinkTo="/tilganger-andre-bedrifter?tabIndex=1"
                tilganger={tilganger}
            />
        );
    }

    return (
        <div>
            <FolkeregisterSjekk
                header="Hvem skal få tilgang?"
                fremButtonText="Neste →"
                avbrytButtonText="Avbryt"
                onAvbryt={() => {
                    track({
                        hendelse: Trackingkey.Skjemaflyt,
                        flytnavn: "ny bedriftsansvarlig",
                        stegnavn: "folkeregister",
                        programmatisk: false,
                        handling: "avbryt"
                    });
                    navigate("/tilganger-andre-bedrifter?tabIndex=1");
                }}
                onBekreftet={(res) => {
                    const { fornavn, etternavn, fodselsnummer, fodselsdato, alder } = res;
                    setPerson({ fornavn, etternavn, fodselsnummer, fodselsdato, alder });
                    setStep((prev) => prev + 1);
                    track({
                        hendelse: Trackingkey.Skjemaflyt,
                        flytnavn: "ny bedriftsansvarlig",
                        stegnavn: "folkeregister",
                        programmatisk: false,
                        handling: "neste"
                    });
                }}
                validerPerson={(person) => {
                    if (tilganger.kamer.some(({ kam }) => kam.fodselsnummer === person.fodselsnummer)) {
                        return {
                            title: `${person.fornavn} ${person.etternavn} er bedriftsansvarlig`,
                            message:
                                'Personen du prøver å gi tilgang til er allerede opprettet som bedriftsansvarlig. Gå til visning "Bedriftsansvarlig" og søk opp personen der.'
                        };
                    }

                    return undefined;
                }}
                defaultValues={person}
            />
        </div>
    );
};
