import { Typography } from "@components/Typography";
import type { AgreementDetails } from "src/model/gen";
import cx from "classnames";

import styles from "./AgreementObjectList.module.scss";
import { AgreementObjectCard } from "./AgreementObjectCard/AgreementObjectCard";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementObjectList = ({ agreementDetails }: Props) => {
    return (
        <section>
            <Typography variant="heading-2" className="jkl-spacing-l--bottom">
                Forsikringsobjekter
            </Typography>
            <div className={cx(styles.objektListe, "jkl-spacing-2xl--bottom")}>
                {agreementDetails.objects.map((detail, index, arr) => (
                    <AgreementObjectCard
                        key={index}
                        object={detail}
                        agreementDetails={agreementDetails}
                        objectNumber={index + 1}
                        objectsSum={arr.length}
                    />
                ))}
            </div>
        </section>
    );
};
