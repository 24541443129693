import "./DokumentFiltrering.scss";

import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { DatePicker, DatePickerChangeEventHandler, formatInput } from "@fremtind/jkl-datepicker-react";
import { Typography } from "@components/Typography";
import { Select } from "@fremtind/jkl-select-react";
import { Tab, TabList, TabPanel, Tabs } from "@fremtind/jkl-tabs-react";

import { SecondaryButton } from "../../../../components/Button";
import { DocumentDto } from "../../../../model/gen";
import { track, Trackingkey } from "../../../../tracking";

interface PropTypes {
    alleDokumenter: DocumentDto[];
    tømFilter?: () => void;
    datoFra: Date;
    datoTil: Date;
    initiellDatoFra: Date;
    initiellDatoTil: Date;
    setDatoFra: (date: Date) => void;
    setDatoTil: (date: Date) => void;
    produktFilter: string;
    avtaleFilter: string;
    dokumentFilter: string;
    setProduktFilter: (filter: string) => void;
    setAvtaleFilter: (filter: string) => void;
    setDokumentFilter: (filter: string) => void;
    liste: string;
    setListe: Dispatch<SetStateAction<string>>;
    harForeningsDokumenter: boolean;
    harBedriftsDokumenter: boolean;
}

export function DokumentFiltrering({
    alleDokumenter,
    tømFilter,
    datoFra,
    datoTil,
    initiellDatoFra,
    initiellDatoTil,
    setDatoFra,
    setDatoTil,
    produktFilter,
    avtaleFilter,
    dokumentFilter,
    setProduktFilter,
    setAvtaleFilter,
    setDokumentFilter,
    liste,
    setListe,
    harBedriftsDokumenter,
    harForeningsDokumenter
}: PropTypes) {
    const produkter = useMemo(
        () =>
            [
                ...new Set(
                    alleDokumenter
                        .filter((dokument: DocumentDto) => dokument.productNames?.[0] !== undefined)
                        .reduce((acc: string[], dokument) => {
                            return [...acc, ...(dokument.productNames ?? [])];
                        }, [])
                )
            ]
                .sort((a, b) => a.localeCompare(b))
                .map((produktNavn) => {
                    return { value: produktNavn, label: produktNavn };
                }),
        [alleDokumenter]
    );

    const avtalenummer = [
        ...new Set(
            alleDokumenter
                .filter((dokument: DocumentDto) => dokument.agreementNumbers !== undefined)
                .reduce((acc: string[], dokument) => {
                    return [...acc, ...(dokument.agreementNumbers ?? [])];
                }, [])
        )
    ]
        .sort((a, b) => (a > b ? 1 : -1))
        .map((avtalenummere) => {
            const avtale = avtalenummere;
            return { value: avtale, label: avtale };
        });

    const dokumenttyper = useMemo(
        () =>
            alleDokumenter
                .filter((dokument: DocumentDto) => dokument.name !== "")
                .filter((type, i, dokument) => dokument.findIndex((d) => d.name === type.name) === i)
                .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
                .map((dokument: DocumentDto) => {
                    const dokumenttyper = dokument.name!;
                    return { value: dokumenttyper, label: dokumenttyper };
                }),
        [alleDokumenter]
    );

    //Nullstill hvert enkelt filter
    useEffect(() => {
        produkter.unshift({ value: "", label: "Alle" });
        avtalenummer.unshift({ value: "", label: "Alle" });
        dokumenttyper.unshift({ value: "", label: "Alle" });
    }, [produkter, avtalenummer, dokumenttyper]);

    const ifDate =
        (cb: (dato: Date) => any): DatePickerChangeEventHandler =>
        (e, dato?) => {
            if (dato) {
                cb(dato);
            }
        };

    return (
        <>
            {harBedriftsDokumenter && harForeningsDokumenter && (
                <Tabs
                    defaultTab={liste === "forening" ? 0 : 1}
                    onChange={(e) => {
                        setListe(["forening", "bedrift"][e]);
                        tømFilter?.();
                        track({
                            hendelse: Trackingkey.Tab,
                            tab: ["Forening og forbund", "bedrift"][e],
                            tilgjengeligeTabs: ["Forening og forbund", "bedrift"]
                        });
                    }}
                >
                    <TabList>
                        <Tab>Forening og forbund</Tab>
                        <Tab>Bedrift</Tab>
                    </TabList>
                    <TabPanel>
                        <Typography className="jkl-spacing-16--top" style={{ maxWidth: "55ch" }}>
                            Her ser du dokumentene for de forsikringene som dekker medlemmene dine.
                        </Typography>
                    </TabPanel>
                    <TabPanel>
                        <Typography className="jkl-spacing-16--top" style={{ maxWidth: "55ch" }}>
                            Her ser du dokumentene for de forsikringene som dekker noe annet en medlemmene dine.
                        </Typography>
                    </TabPanel>
                </Tabs>
            )}
            <div className="dokument-filtrering">
                <DatePicker
                    labelProps={{ variant: "small" }}
                    label="Fra dato"
                    extended
                    value={formatInput(datoFra) || formatInput(initiellDatoFra)}
                    onChange={ifDate(setDatoFra)}
                    disableAfterDate={formatInput(new Date())}
                    data-testautoid="dokumentfilter-fra-dato"
                />
                <DatePicker
                    labelProps={{ variant: "small" }}
                    label="Til dato"
                    extended
                    value={formatInput(datoTil) || formatInput(initiellDatoTil)}
                    onChange={ifDate(setDatoTil)}
                    disableBeforeDate={formatInput(datoFra)}
                    data-testautoid="dokumentfilter-til-dato"
                />
                <Select
                    name="produktfilter"
                    value={produktFilter}
                    searchable
                    defaultPrompt={produktFilter || "Velg"}
                    label="Produkt"
                    items={produkter}
                    onChange={(e) => {
                        track({
                            hendelse: Trackingkey.Filter,
                            label: "Produkt",
                            valg: e.target.value,
                            tilgjengeligeValg: produkter.map((p) => p.value)
                        });
                        setProduktFilter(e.target.value || "");
                    }}
                    data-testautoid="dokumentfilter-produkt-dato"
                />
                <Select
                    name="avtalefilter"
                    value={avtaleFilter}
                    defaultPrompt={avtaleFilter || "Velg"}
                    searchable
                    label="Avtalenummer"
                    items={avtalenummer}
                    onChange={(e) => {
                        setAvtaleFilter(e.target.value || "");
                        track({
                            hendelse: Trackingkey.Filter,
                            label: "Avtalenummer",
                            valg: e.target.value,
                            tilgjengeligeValg: avtalenummer.map((a) => a.value)
                        });
                    }}
                    data-testautoid="dokumentfilter-avtalenummer-dato"
                />
                <Select
                    name="dokumentfilter"
                    value={dokumentFilter}
                    defaultPrompt={dokumentFilter || "Velg"}
                    searchable
                    label="Dokumenttype"
                    items={dokumenttyper}
                    onChange={(e) => {
                        setDokumentFilter(e.target.value || "");
                        track({
                            hendelse: Trackingkey.Filter,
                            label: "Dokumenttype",
                            valg: e.target.value,
                            tilgjengeligeValg: dokumenttyper.map((d) => d.value)
                        });
                    }}
                    data-testautoid="dokumentfilter-dokumenttype-dato"
                />
                <SecondaryButton
                    onClick={tømFilter}
                    track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "dokumentliste-tøm-filter" }}
                    dataTestautoid="dokument-nullstill-filter"
                >
                    Tøm filter
                </SecondaryButton>
            </div>
        </>
    );
}
