import { BMRoute } from "src/sider";
import { velgOrganisasjon, byttOrganisajon, byttKonsesjon } from "./views";

export const organisasjonRoutes: BMRoute[] = [
    {
        index: true,
        loader: velgOrganisasjon.loader,
        element: <velgOrganisasjon.VelgOrganisasjon />
    },
    {
        path: "bytt-organisasjon",
        element: <byttOrganisajon.ByttOrganisasjon />,
        loader: byttOrganisajon.loader,
        children: [
            {
                path: ":organisasjonsnummer",
                element: <byttOrganisajon.ByttOrganisasjon />
            }
        ]
    },
    {
        path: "bytt-konsesjon",
        element: <byttKonsesjon.ByttKonsesjon />,
        loader: byttKonsesjon.loader
    }
];
