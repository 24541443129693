import { SideHeader } from "@components/Side";
import { toFormattedOrganisasjonsnummer } from "src/common/formatting";

interface HomeHeaderProps {
    organizationName: string;
    organizationNumber: string;
}

export function HomeHeader(props: HomeHeaderProps) {
    const { organizationName, organizationNumber } = props;

    return (
        <header className="bm-home-header">
            <SideHeader
                title={organizationName}
                lead={`Organisasjonsnummer: ${toFormattedOrganisasjonsnummer(organizationNumber)}`}
            />
        </header>
    );
}
