import { useContext } from "react";
import { SideHeader } from "@components/Side";
import { SnarveierFlexible } from "@components/Snarveier";
import { Typography } from "@components/Typography";
import ThemeContext from "src/context/ThemeContext";
import { Link } from "react-router-dom";
import { Link as JklLink } from "@fremtind/jkl-core";
import { LenkeId, useExternalLinks } from "src/common/lenker";
import { AgreementDetails } from "src/model/gen";
import { track, Trackingkey } from "src/tracking";
import { useMarkdown } from "src/common/hooks/useMarkdown";
import { useAgreementInfo } from "@features/agreements/hooks/useAgreementInfo";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementDetailsHeader = ({ agreementDetails }: Props) => {
    const hasLagringsforsikring = agreementDetails.stateDescription === "Lagringsforsikring";
    const product = useAgreementInfo(hasLagringsforsikring ? "Lagring" : agreementDetails.productCode);
    const { distributor } = useContext(ThemeContext);
    const sendClaimLink = useExternalLinks().getLinkByLinkId(LenkeId.meldSkade)?.href;

    return (
        <SideHeader
            lagringsforsikring={hasLagringsforsikring}
            title={agreementDetails.productName}
            lead={useMarkdown(product?.description)}
        >
            <SnarveierFlexible heading={<Typography variant="heading-5">Lenker</Typography>}>
                <JklLink
                    external
                    target="_blank"
                    href={sendClaimLink}
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.LenkeTrykk,
                            lenkeId: LenkeId.meldSkade
                        });
                    }}
                >
                    Meld skade
                </JklLink>
                <Link
                    className="jkl-link"
                    to={`/faktura?avtalenr=${agreementDetails.agreementId}`}
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.LenkeTrykk,
                            lenkeId: "snarveier-lenke-faktura"
                        });
                    }}
                >
                    Faktura
                </Link>
                <Link
                    className="jkl-link"
                    to={`/dokumenter`}
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.LenkeTrykk,
                            lenkeId: "snarveier-lenke-dokumenter"
                        });
                    }}
                >
                    Alle dokumenter
                </Link>
                {product?.productInfo && (
                    <JklLink
                        external
                        target="_blank"
                        onClick={() => {
                            track({
                                hendelse: Trackingkey.LenkeTrykk,
                                lenkeId: product.productInfo!.id
                            });
                        }}
                        href={distributor === "sb1" ? product.productInfo.href.sb1 : product.productInfo.href.dnb}
                    >
                        {product.productInfo.label}
                    </JklLink>
                )}
                {product?.externalLinks &&
                    product.externalLinks.map((link) => (
                        <JklLink
                            key={link.id}
                            external
                            target="_blank"
                            href={distributor === "sb1" ? link.href.sb1 : link.href.dnb}
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: link.id
                                });
                            }}
                        >
                            {link.label}
                        </JklLink>
                    ))}
            </SnarveierFlexible>
        </SideHeader>
    );
};
