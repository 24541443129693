import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { asAvtaleProduktId } from "@features/agreements/avtale.utils";
import { AgreementOverview } from "src/model/gen";

export function onlyFlateAvtaler(avtaler: AgreementOverview[]) {
    const FlateProduktIDer = [
        AvtaleProduktID.FLATE_BIL,
        AvtaleProduktID.FLATE_LASTEBIL,
        AvtaleProduktID.FLATE_TILHENGER,
        AvtaleProduktID.FLATE_ARBEIDSMASKIN
    ];

    return avtaler.filter((avtale) => FlateProduktIDer.includes(asAvtaleProduktId(avtale.productCode)));
}
