import { ProtectedRoute } from "@components/ProtectedRoute";
import { BMRoute } from "src/sider";
import { ClaimsOverviewComposer } from "./views";
import { claimsOverviewLoader } from "./views/ClaimsOverview/loader";
import { ClaimsDetailsComposer } from "./views/ClaimsDetails/ClaimsDetailsComposer";
import { claimsDetailsLoader } from "./views/ClaimsDetails/loader";

export const claimsRoutes: BMRoute[] = [
    {
        path: "/skadesaker",
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute>
                        <ClaimsOverviewComposer />
                    </ProtectedRoute>
                ),
                loader: claimsOverviewLoader,
                breadcrumbLabel: "Skadesaker"
            },
            {
                path: ":claimId",
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute>
                                <ClaimsDetailsComposer />
                            </ProtectedRoute>
                        ),
                        loader: claimsDetailsLoader,
                        breadcrumbLabel: "Skadesak"
                    }
                ]
            }
        ]
    }
];
