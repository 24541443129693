import { isAfter } from "date-fns";
import { ErrorTag, SuccessTag } from "@fremtind/jkl-tag-react";
import { DataTable } from "@fremtind/jkl-table-react";
import { formatValuta } from "@fremtind/jkl-formatters-util";
import { asDate, toCapitalizedLowerCase, toNorwegianDateFormat } from "src/common/formatting";
import { Typography } from "src/components/Typography";
import { AgreementOverview, Vehicle } from "src/model/gen";
import { Link } from "react-router-dom";

interface KjoretoyRadData extends Vehicle {
    avtalenummer: number;
    agreementIndex?: string;
}

type KjoretoyProps = {
    kjoretoy?: Array<KjoretoyRadData>;
    isTilhenger: boolean;
    avtale?: AgreementOverview;
};

export function KjoretoyTabell({ kjoretoy, isTilhenger }: KjoretoyProps) {
    if (!kjoretoy) {
        return null;
    }

    const rows = getRows(isTilhenger, kjoretoy);

    return (
        <>
            <div>
                <DataTable collapseToList columns={getColumns(isTilhenger)} rows={rows} />
                {rows.length < 1 && (
                    <div
                        style={{
                            height: 400,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Typography>Ingen kjøretøy matcher filteret ditt</Typography>
                    </div>
                )}
            </div>
        </>
    );
}

function getColumns(isTilhenger: boolean) {
    const columns = [
        "Merke og modell",
        "Kjennemerke",
        "Avtalenummer",
        "Registreringsår",
        "Innmeldingsdato",
        "Utmeldingsdato"
    ];

    if (!isTilhenger) {
        columns.push("Trafikkforsikringsavgift");
    }

    return columns;
}

function getRows(isTilhenger: boolean, kjoretoy: Array<KjoretoyRadData>) {
    return kjoretoy
        .sort((a, b) => a.brand.localeCompare(b.brand))
        .map((vehicle) => [
            toCapitalizedLowerCase(`${vehicle.brand} ${vehicle.model}`, true),
            vehicle.registrationNumber,
            <Link className="jkl-link" key={vehicle.registrationNumber} to={`/forsikringer/${vehicle.agreementIndex}/`}>
                {vehicle.avtalenummer}
            </Link>,
            vehicle.registrationYear,
            vehicle.enrollmentDateFrom && isAfter(asDate(vehicle.enrollmentDateFrom), new Date()) ? (
                <>
                    {toNorwegianDateFormat(vehicle.enrollmentDateFrom)}{" "}
                    <SuccessTag className="jkl-spacing-s--left">Innmeldt</SuccessTag>
                </>
            ) : (
                toNorwegianDateFormat(vehicle.enrollmentDateFrom)
            ),
            vehicle.enrollmentDateTo ? (
                <>
                    {toNorwegianDateFormat(vehicle.enrollmentDateTo)}{" "}
                    <ErrorTag className="jkl-spacing-s--left">Utmeldt</ErrorTag>
                </>
            ) : null,
            !isTilhenger ? formatValuta(vehicle.trafikkForsikringsAvgift ?? 0) : null
        ]);
}
