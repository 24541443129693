import "./GlobalFeedback.scss";

import React from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { Trackingkey } from "src/tracking";

import { FeedbackMeta, Tilbakemelding } from "@components/TilbakeMelding";

export type FeedbackProps = {
    label?: string;
    page?: string;
    className?: string;
    withContact?: boolean;
    product?: string;
    formFlowName?: string;
    formStepName?: string;
};

export const GlobalFeedback: React.FC<FeedbackProps> = ({
    label = "Fikk du gjort det du skulle?",
    page,
    withContact = true,
    product,
    className,
    formFlowName,
    formStepName
}) => {
    const { pathname } = useLocation();

    const classes = classNames("fbm-global-feedback", className);

    const meta = {
        side: page ?? pathname
    } as FeedbackMeta;

    if (product) {
        meta.produktkode = product;
    }

    return (
        <Tilbakemelding
            className={classes}
            withContact={withContact}
            label={label}
            meta={meta}
            tracking={{
                side: page ?? pathname,
                hendelse: Trackingkey.TilbakemeldingScore,
                spørsmål: label,
                formFlowName,
                formStepName,
                meta
            }}
        />
    );
};
