import { useState } from "react";
import { SecondaryButton, PrimaryButton, TertiaryButton } from "@fremtind/jkl-button-react";
import { AnimatePresence, motion } from "framer-motion";
import { FjernFlytNavn, track, Trackingkey } from "../../tracking";

interface Props {
    onConfirm: () => void;
    label: string;
    name?: FjernFlytNavn;
}

export const Confirm = ({ onConfirm, label, name }: Props) => {
    const [showConfirm, setShowConfirm] = useState(false);

    return (
        <AnimatePresence mode="wait">
            <motion.div
                key={showConfirm.toString()}
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: !showConfirm ? -20 : 20 }}
                exit={{ opacity: 0, y: !showConfirm ? -20 : 20 }}
                transition={{ duration: 0.15 }}
            >
                {showConfirm ? (
                    <>
                        <SecondaryButton
                            className="jkl-spacing-l--right"
                            density="compact"
                            role="button"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowConfirm(false);
                                if (name) {
                                    track({
                                        hendelse: Trackingkey.FjernFlyt,
                                        handling: "avbryt",
                                        type: name
                                    });
                                }
                            }}
                        >
                            Avbryt
                        </SecondaryButton>
                        <PrimaryButton
                            density="compact"
                            role="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                onConfirm();
                                if (name) {
                                    track({
                                        hendelse: Trackingkey.FjernFlyt,
                                        handling: "bekreft",
                                        type: name
                                    });
                                }
                            }}
                        >
                            Bekreft
                        </PrimaryButton>
                    </>
                ) : (
                    <TertiaryButton
                        density="compact"
                        role="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowConfirm(true);
                            if (name) {
                                track({
                                    hendelse: Trackingkey.FjernFlyt,
                                    handling: "start",
                                    type: name
                                });
                            }
                        }}
                    >
                        {label}
                    </TertiaryButton>
                )}
            </motion.div>
        </AnimatePresence>
    );
};
