import { useAgreementList } from "@features/agreements/agreements.queries";
import { utils } from "@features/agreements";
import { useBruker } from "@features/bruker/queries";
import { brukerHarRolle } from "src/common/rolle";
import { BrukerRolle } from "src/model/bruker.typer";
import { SecondaryLinkButton } from "../../../components/Button";

export function AnsattbrosjyreButton() {
    const { bruker } = useBruker();
    const avtalerQuery = useAgreementList();

    if (
        utils.includesAnsattbrosjyreAvtaler(avtalerQuery.data?.agreements || []) &&
        brukerHarRolle(bruker, [BrukerRolle.ADMINISTRATOR, BrukerRolle.PERSONAL, BrukerRolle.RADGIVER])
    ) {
        return (
            <SecondaryLinkButton density="compact" to="/ansattbrosjyre" lenkeId="ansattbrosjyreknapp">
                Lag ansattbrosjyre
            </SecondaryLinkButton>
        );
    }

    return null;
}
