import {
    SkeletonAnimation,
    SkeletonElement,
    SkeletonTable,
    SkeletonTableHeader,
    SkeletonTableRow
} from "@fremtind/jkl-loader-react";

export const InvoiceDetailsLoader = () => {
    return (
        <SkeletonAnimation>
            <div className="bm-invoice-details">
                <div className="bm-invoice-details__header">
                    <div style={{ width: "660px", display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <SkeletonElement width="100%" height="72px" />
                        <SkeletonElement width="100%" height="72px" />
                        <SkeletonElement width="100%" height="72px" />
                        <SkeletonElement width="100%" height="72px" />
                    </div>
                    <div className="bm-invoice-details__header__details">
                        {Array.from({ length: 4 }).map((_, i) => (
                            <div key={i} style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                <SkeletonElement width="120px" height="24px" />
                                <SkeletonElement width="180px" height="32px" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="bm-invoice-details__content">
                    <SkeletonTable>
                        <SkeletonTableHeader>
                            <div style={{ width: 115 }}>
                                <SkeletonElement width={115} height={16} />
                            </div>
                            <div style={{ width: 250 }}>
                                <SkeletonElement width={115} height={16} />
                            </div>
                            <div style={{ width: 115 }}>
                                <SkeletonElement width={115} height={16} />
                            </div>
                            <div style={{ width: 115 }}>
                                <SkeletonElement width={115} height={16} />
                            </div>
                            <div style={{ width: 115 }}>
                                <SkeletonElement width={115} height={16} />
                            </div>
                        </SkeletonTableHeader>
                        {Array.from({ length: 5 }).map((_, i) => (
                            <SkeletonTableRow key={i}>
                                <div style={{ width: 115 }}>
                                    <SkeletonElement width={160} height={24} />
                                </div>
                                <div style={{ width: 250 }}>
                                    <SkeletonElement width={i % 2 ? 115 : 185} height={19} />
                                </div>
                                <div style={{ width: 115 }}>
                                    <SkeletonElement width={102} height={24} />
                                </div>
                                <div style={{ width: 115 }}>
                                    <SkeletonElement width={102} height={24} />
                                </div>
                                <div style={{ width: 115 }}>
                                    <SkeletonElement width={80} height={24} />
                                </div>
                            </SkeletonTableRow>
                        ))}
                    </SkeletonTable>
                </div>
            </div>
        </SkeletonAnimation>
    );
};
