import { AgreementDetails } from "src/model/gen";

export const GetFormData = (agreementDetails: AgreementDetails) => {
    const productCode = agreementDetails.productCode;
    const leasing = agreementDetails.additionalInfo?.find((info) => info?.description === "Leasing")
        ?.value as unknown as boolean;

    const coverages = agreementDetails?.objects?.find((coverageTable) => coverageTable?.coverageTable);
    const agreementObjects = agreementDetails?.objects.find((f) => f.fields);
    const regState = agreementObjects?.fields?.find(
        (f) => f.description === "Reg.nummer" || "Registreringsnummer"
    )?.value;

    const coverage = coverages?.coverageTable?.rows[0]?.row[0]?.value;

    const lagringsforsikring =
        agreementDetails?.stateDescription === "Lagringsforsikring" && coverage === "Avregistrering";

    //dekning: Brann/tyveri ureg. bil
    const uregDelkasko: boolean = coverage === "Brann/tyveri ureg. bil" && regState === "UREG";

    // List of kansellerbare agreements
    const vehicleProductCodes = ["V40", "V05", "V20", "V50", "V56", "V09", "V30"];
    const vehicleProductAvailable = vehicleProductCodes.includes(productCode);

    // List of drift og eiendom products with extra annulation cause: Objekt er solgt skal med
    const driftOgEiendomProductsCustom = ["T10", "T20", "T30", "Y04", "Y06", "Y19", "Y24", "Y30", "Y35", "Y36", "Y60"];
    // List of drift og eiendom products with default annulation cause
    const driftOgEiendomProductsDefault = ["Y16", "Y25", "Y40", "Y41", "Y42", "Y45", "Y47", "Y48", "Y49", "Y50"];
    const driftOgEiendomProducts = driftOgEiendomProductsCustom.concat(driftOgEiendomProductsDefault);
    const driftOgEiendomProductAvailable = driftOgEiendomProducts.includes(productCode);

    const returnObject = {
        showCancelForm: false,
        isVehicle: false,
        annulationCause: ["Byttet forsikringsselskap", "Annen grunn"]
    };

    // Check if the agreement is available for cancellation
    if (!vehicleProductAvailable && !driftOgEiendomProductAvailable) {
        return { annulationCause: [], showCancelForm: false, isVehicle: false };
    } else {
        if (vehicleProductAvailable) {
            const agreementState =
                leasing === true
                    ? "leasing"
                    : lagringsforsikring === true
                      ? "lagringsforsikring"
                      : uregDelkasko === true
                        ? "uregDelkasko"
                        : "default";

            const annulationCauses: any = {
                V05: {
                    annulationCauses: {
                        leasing: ["Avsluttet leasingforhold"],
                        lagringsforsikring: ["Har ikke behov for forsikringen", "Bilen er solgt"],
                        uregDelkasko: ["Har ikke behov for forsikringen"]
                    }
                },
                V20: {
                    annulationCauses: {
                        leasing: ["Avsluttet leasingforhold"],
                        lagringsforsikring: ["Har ikke behov for forsikringen", "Lastebilen er solgt"],
                        uregDelkasko: ["Har ikke behov for forsikringen"]
                    }
                },
                V40: {
                    annulationCauses: {
                        leasing: ["Avsluttet leasingforhold"],
                        lagringsforsikring: ["Traktoren/arbeidsmaskinen er solgt", "Har ikke behov for forsikringen"]
                    }
                },
                V50: {
                    annulationCauses: {
                        leasing: ["Avsluttet leasingforhold"],
                        default: ["Hengeren er solgt", "Har ikke behov for forsikringen"]
                    }
                },
                V56: {
                    annulationCauses: {
                        default: ["Båten er solgt"]
                    }
                },
                V09: {
                    annulationCauses: {
                        default: ["Levert skilter til Statens vegvesen"]
                    }
                },
                V30: {
                    annulationCauses: {
                        leasing: ["Avsluttet leasingforhold"],
                        lagringsforsikring: ["Har ikke behov for forsikringen", "Kjøretøyet er solgt"]
                    }
                }
            };

            returnObject.isVehicle = true;
            returnObject.showCancelForm = true;
            returnObject.annulationCause =
                annulationCauses[productCode].annulationCauses[agreementState] === undefined
                    ? []
                    : annulationCauses[productCode].annulationCauses[agreementState].concat(
                          returnObject.annulationCause
                      );
        } else if (driftOgEiendomProductAvailable) {
            returnObject.isVehicle = false;
            returnObject.showCancelForm = true;
            returnObject.annulationCause = ["Har ikke behov for forsikringen"].concat(returnObject.annulationCause);
            // check if the product code is in the list with extra annulation cause
            if (driftOgEiendomProductsCustom.includes(productCode)) {
                returnObject.annulationCause = ["Objektet er solgt"].concat(returnObject.annulationCause);
            }
        }
        return returnObject;
    }
};
