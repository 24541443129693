import { useRef } from "react";
import { NumberBasedAgreementDto } from "src/model/gen";
import { track, Trackingkey } from "src/tracking";

import { Dialog } from "@components/Dialog";
import { Typography } from "@components/Typography";
import { AntallCard } from "@features/antallsliste/components";
import { useBruker } from "@features/bruker/queries";
import { formatTelefonnummer } from "@fremtind/jkl-formatters-util";
import { ModalInstance } from "@fremtind/jkl-modal-react";

interface Props {
    avtaler: Array<NumberBasedAgreementDto>;
}

export function AntallslisteComposer(props: Props) {
    const dialogRef = useRef<ModalInstance | null>();
    const { avtaler } = props;

    const changableAgreements = avtaler
        .filter((avtale) => avtale.changeable)
        .sort((a, b) => a.productName.localeCompare(b.productName));

    const unchangableAgreements = avtaler.filter((avtale) => !avtale.changeable);

    const { bruker } = useBruker();

    if (!bruker) {
        return null;
    }
    const navn = bruker.radgiverKontaktInfo?.navn;
    const mobil = bruker.radgiverKontaktInfo?.mobil;
    const epost = bruker.radgiverKontaktInfo?.epost;

    track([
        {
            hendelse: Trackingkey.SeListe,
            type: "avtaler som kan endres selvbetjent",
            antall: changableAgreements.length,
            liste: changableAgreements.map((avtale) => avtale.productCode)
        },
        {
            hendelse: Trackingkey.SeListe,
            type: "avtaler som ikke kan endres selvbetjent",
            antall: unchangableAgreements.length,
            liste: unchangableAgreements.map((avtale) => avtale.productCode)
        }
    ]);

    return (
        <div className="antallsliste">
            <ul className="antallsliste">
                {(changableAgreements ?? []).map((avtale) => {
                    return (
                        <li key={avtale.id} className="antallsliste__item">
                            <AntallCard avtale={avtale} />
                        </li>
                    );
                })}
                {(unchangableAgreements ?? []).map((avtale) => {
                    return (
                        <li key={avtale.id} className="antallsliste__item unchangable">
                            <AntallCard avtale={avtale} />
                        </li>
                    );
                })}
            </ul>

            <Dialog
                title="Kontakt din rådgiver for endring"
                dialogRef={(instance) => {
                    dialogRef.current = instance;
                }}
                onConfirm={() => {
                    dialogRef.current?.hide();
                }}
                onCancel={() => {
                    dialogRef.current?.hide();
                }}
            >
                <>
                    {navn && <Typography>{navn}</Typography>}

                    {mobil && <Typography>{formatTelefonnummer(mobil, { countryCode: "47" })}</Typography>}

                    {epost && <Typography>{epost}</Typography>}
                </>
            </Dialog>
        </div>
    );
}
