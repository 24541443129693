import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { KundeFeilmelding } from "../../model/feilkoder.object";
import { FolkeregisterPersonVerifikasjonStatus } from "../../model/folkeregister.enumer";
import {
    FolkeregisterPersonVerifikasjon,
    FolkeregisterPersonVerifikasjonResponse
} from "../../model/folkeregister.typer";
import { httpPost } from "../api";

function verifiserPersonMotFolkeregister(personData: {
    fornavn: string;
    etternavn: string;
    fodselsnummer: string;
}): Promise<FolkeregisterPersonVerifikasjonResponse> {
    return httpPost<FolkeregisterPersonVerifikasjonResponse>("folkeregister", JSON.stringify([personData]));
}

interface FormValues {
    fornavn: string;
    etternavn: string;
    fodselsnummer: string;
}

const isFolkeregisterPersonVerifikasjonStatus = (
    status: FolkeregisterPersonVerifikasjonStatus | FolkeregisterPersonVerifikasjon
): status is FolkeregisterPersonVerifikasjonStatus => {
    return typeof status === "number";
};

const isFolkeregisterPersonVerifikasjon = (
    status: FolkeregisterPersonVerifikasjonStatus | FolkeregisterPersonVerifikasjon
): status is FolkeregisterPersonVerifikasjon => {
    return (status as FolkeregisterPersonVerifikasjon).indeks !== undefined;
};

const getFolkeregisterPersonVerifikasjonStatuskode = (
    status: FolkeregisterPersonVerifikasjonStatus | FolkeregisterPersonVerifikasjon
) => {
    if (isFolkeregisterPersonVerifikasjonStatus(status)) {
        return status;
    }

    return status.statusKode;
};

const genererErrorFraVerifikasjon = (
    status: FolkeregisterPersonVerifikasjonStatus | FolkeregisterPersonVerifikasjon
): KundeFeilmelding | undefined => {
    const statusKode = getFolkeregisterPersonVerifikasjonStatuskode(status);

    switch (statusKode) {
        case 2:
            return {
                title: "Navn og fødselsnummer passer ikke sammen.",
                message: "Har det lurt seg inn en skrivefeil?"
            };

        case 3:
            if (isFolkeregisterPersonVerifikasjon(status) && status.nyttFodselsnummer) {
                return {
                    title: "Personen har fått nytt fødselsnummer",
                    // ikke formattere fodselsnummer for å forenkle å kopiere nummeret inn i inputfeltet
                    message: `Alle innmeldinger og endringer må gjøres på det nye fødselsnummeret: ${status.nyttFodselsnummer}`
                };
            } else {
                return {
                    title: "Personen har fått nytt fødselsnummer",
                    message: `Alle innmeldinger og endringer må gjøres på det nye fødselsnummeret.`
                };
            }

        case 4:
        default:
            return {
                title: "Oi, litt tekniske problemer her!",
                message:
                    "Prøv en gang til eller kom tilbake senere. Sjekk gjerne at du har skrevet riktig navn og fødselsnummer før du prøver på nytt."
            };
    }
};

interface Args {
    validerPerson?: (person: FolkeregisterPersonVerifikasjon) => KundeFeilmelding | undefined;
    onSuccess: (person: FolkeregisterPersonVerifikasjon, formValues: FormValues) => void;
    defaultValues?: FormValues;
}

export function useFolkeregisterSjekk(args: Args) {
    const form = useForm<FormValues>({ defaultValues: args.defaultValues });

    const [error, setError] = useState<KundeFeilmelding>();

    function clearError() {
        setError(undefined);
    }

    const mutation = useMutation({
        mutationFn: verifiserPersonMotFolkeregister
    });

    const formData = form.watch();

    const prevFormData = useRef(formData);

    // Fjern custom feilmelding når skjemaet endres
    useEffect(() => {
        const diff = Object.values(formData).some((value) => !Object.values(prevFormData.current).includes(value));

        if (error && diff) {
            setError(undefined);
        }

        prevFormData.current = formData;
    }, [error, formData]);

    async function onSubmit(formValues: FormValues) {
        mutation.mutate(formValues, {
            onSuccess: (data) => {
                const person = data.find((fetchedPerson) => fetchedPerson.fodselsnummer === formValues.fodselsnummer);
                if (person) {
                    if (person.verifisertOk === true) {
                        if (args.validerPerson) {
                            const externalValidateResult = args.validerPerson(person);

                            if (externalValidateResult) {
                                setError(externalValidateResult);
                            } else {
                                if (error) setError(undefined);

                                args.onSuccess(person, formValues);
                            }
                        } else {
                            args.onSuccess(person, formValues);
                        }
                    } else {
                        const error = genererErrorFraVerifikasjon(person);

                        if (error) {
                            setError(error);
                        }
                    }
                } else if (data.length) {
                    const error = genererErrorFraVerifikasjon(data[0]);

                    if (error) {
                        setError(error);
                    }
                } else {
                    // manuelt hent "noe gikk galt"-feilmelding
                    const error = genererErrorFraVerifikasjon(4);

                    if (error) {
                        setError(error);
                    }
                }
            },
            onError: () => {
                // manuelt hent "noe gikk galt"-feilmelding
                const error = genererErrorFraVerifikasjon(4);

                setError(error);
            }
        });
    }

    return {
        form,
        error,
        clearError,
        onSubmit,
        mutation
    };
}
