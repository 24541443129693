import { http } from "@features/core";
import { EntityResponse } from "@features/core/http";
import { useMutation, useQuery } from "@tanstack/react-query";
import { TilbudResponse, TilbudSigneringResponse } from "../../model/gen";
import { IHttpError } from "../../model/http.typer";
import { useBruker } from "../bruker/queries";

export const signeringQueryKey = {
    all: "signering-tilbud"
};

function getTilbud(orgNr: string) {
    return http.getEntity<TilbudResponse[]>(`${orgNr}/tilbud`);
}

export function useGetTilbud() {
    const { valgtOrganisasjon } = useBruker();

    return useQuery({
        queryKey: [signeringQueryKey.all],
        queryFn: () => getTilbud(valgtOrganisasjon!.organisasjonsnummer),
        enabled: !!valgtOrganisasjon,
        staleTime: Infinity
    });
}

function startSignering(tilbud: TilbudResponse) {
    return http.post<EntityResponse<TilbudSigneringResponse>>(
        `${tilbud.kundeOrgNr}/tilbud/${tilbud.tilbudsId}/dokumenter/${tilbud.dokumentId}`
    );
}

export function useStartSignering() {
    return useMutation<http.EntityResponse<TilbudSigneringResponse>, IHttpError, TilbudResponse>({
        mutationFn: startSignering
    });
}
