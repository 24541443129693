import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { utils } from "@features/navneliste";
import { CrossListItem, List } from "@fremtind/jkl-list-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { getFeilmelding } from "../../../../common/utils";
import { SecondaryButton, SecondaryLinkButton } from "../../../../components/Button";
import { Kvittering } from "../../../../components/Kvittering";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { Typography } from "../../../../components/Typography";
import { track, Trackingkey } from "../../../../tracking";
import { FormState, StegProps } from "./";

interface ReceiptProps extends StegProps {
    oversiktLink: string;
}

export function Receipt({ mutation, onFerdig, ...props }: ReceiptProps) {
    const { getValues, reset } = useFormContext<FormState>();

    useEffect(() => {
        onFerdig();
    }, [onFerdig]);

    const insuredName = utils.resolvePersonName(getValues().insured);

    const [innmeldteAvtaler, ikkeInnmeldteAvtaler] = utils.partitionEndredeAvtaler(
        mutation.data?.entity.endringer?.[0].avtaler || []
    );

    const feilmelding =
        mutation.data?.feilkoder && mutation.data.feilkoder.length > 0
            ? getFeilmelding(mutation.data.feilkoder[0])
            : false;

    return (
        <div>
            {innmeldteAvtaler.length > 0 && (
                <>
                    <Kvittering
                        heading={`Flott! ${insuredName} ble meldt inn i:`}
                        positives={innmeldteAvtaler.map((avtale) => avtale.avtaleNavn)}
                    />
                    <Typography className="jkl-spacing-xl--top jkl-spacing-l--bottom">
                        Noen ganger tar det litt tid før innmeldingen blir synlig i oversikten.
                    </Typography>
                </>
            )}

            {ikkeInnmeldteAvtaler.length > 0 && (
                <ErrorMessageBox className="jkl-spacing-xl--bottom jkl-spacing-2xl-top" title="En feil har skjedd">
                    Beklager, det har skjedd en feil hos oss. {insuredName} ble ikke meldt inn i:
                    <List>
                        {ikkeInnmeldteAvtaler.map((avtale) => (
                            <CrossListItem key={avtale.avtaleNavn}>{avtale.avtaleNavn}</CrossListItem>
                        ))}
                    </List>
                    Prøv igjen senere eller kontakt oss på telefon.
                </ErrorMessageBox>
            )}

            {feilmelding && (
                <ErrorMessageBox className="jkl-spacing-xl--bottom jkl-spacing-2xl-top" title={feilmelding.title}>
                    {feilmelding.message}
                </ErrorMessageBox>
            )}

            <SkjemaFooter>
                <SecondaryLinkButton
                    to={props.oversiktLink}
                    data-testautoid="meld-inn-knapp-oppsumering-til-oversikt"
                    onClick={() =>
                        innmeldteAvtaler.map((avtale) =>
                            track({
                                hendelse: Trackingkey.Endre,
                                handling: "ferdig",
                                type: "meld inn ansatte",
                                avtale: avtale.avtaleNavn
                            })
                        )
                    }
                >
                    Ferdig
                </SecondaryLinkButton>

                <SecondaryButton
                    onClick={() => {
                        reset();
                        props.onTilbake(false, 0);
                    }}
                    track={{
                        hendelse: Trackingkey.Knappetrykk,
                        knappeId: "meld-inn-knapp-oppsummering-meld-inn-flere"
                    }}
                    dataTestautoid="meld-inn-knapp-oppsummering-meld-inn-flere"
                >
                    Meld inn flere
                </SecondaryButton>
            </SkjemaFooter>
        </div>
    );
}
