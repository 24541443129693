import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Side, SideHeader, SideInnhold } from "../../../components/Side";
import { useGetTilganger } from "../../../features/tilganger/queries";
import "./TilgangerLeggTil.scss";

export const TilgangerLeggTil = () => {
    const tilgangerQuery = useGetTilganger();

    const location = useLocation();

    const headerText = useMemo(() => {
        const { pathname } = location;

        if (pathname.includes("person")) {
            return "Gi tilgang til person";
        } else if (pathname.includes("bedrift")) {
            return "Gi tilgang til annen bedrift";
        } else {
            return "Gi tilgang";
        }
    }, [location]);

    return (
        <Side compact>
            <SideHeader title={headerText} />
            <SideInnhold loadingText="Henter avtaler og tilganger" query={tilgangerQuery}>
                <Outlet />
            </SideInnhold>
        </Side>
    );
};
