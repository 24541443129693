import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { http } from "@features/core";
import { useQuery } from "@tanstack/react-query";
import { IHttpError } from "src/model/http.typer";
import { useCustomerNumberCheck } from "./utils";

export interface Product {
    flowPath: string;
    productCode: string;
}

export type AvailableProduct =
    | {
          products: Array<Product>;
      }
    | {
          error: IHttpError;
      };

export interface GetKjopApi {
    products?: Array<Product>;
    error?: IHttpError;
    flowPath?: string;
    prodictPath?: string;
}

export const fetchAllProducts = async () => {
    const res = await http.get<Product[]>("kjop/customer/productsuggestions/flow");

    return res;
};

export const useAvailableProducts = () => {
    const hasNiceCustomerNumber = useCustomerNumberCheck();

    const query = useQuery({
        queryKey: ["kjop-products"],
        queryFn: fetchAllProducts,
        staleTime: 1000 * 60 * 30,
        enabled: hasNiceCustomerNumber
    });

    return {
        products: query.data,
        error: query.error as unknown as IHttpError,
        query,
        isLoading: query.isLoading
    };
};

export const useProductPath = (productCode: AvtaleProduktID) => {
    const { products } = useAvailableProducts();

    const productPath = products?.find((product) => product.productCode === productCode)?.flowPath;
    return productPath;
};
