import { useMutation, useQuery } from "@tanstack/react-query";
import { model } from "@features/navneliste";
import { http } from "@features/core";
import { useBruker } from "@features/bruker/queries";

export const queryKey = {
    all: ["medlemmer"],
    list: () => [...queryKey.all, "list"],
    detail: (id: string) => [...queryKey.all, id],
    oppdateringStatus: (indeks: string) => [...queryKey.all, "oppdateringStatus", indeks]
};

// ALLE MEDLEMMER //
async function getMedlemmer(organisasjonsnummer: string) {
    const res = await http.getEntity<model.MedlemListResponse>(`medlemmer/${organisasjonsnummer}`);
    return res;
}

export function useMedlemList() {
    const { valgtOrganisasjon } = useBruker();
    const query = useQuery({
        queryKey: queryKey.list(),
        queryFn: () => getMedlemmer(valgtOrganisasjon!.organisasjonsnummer),
        enabled: !!valgtOrganisasjon,
        staleTime: 3 * 60 * 1000
    });

    return query;
}

// MEDLEM DETALJER //
const getMedlemDetaljer = async (indeks: string) => {
    const res = await http.getEntity<[model.Detaljer]>(`medlemmer/detaljer?medlemIndeks=${indeks}`);

    return { entity: res.entity[0], feilmelding: res.feilmelding };
};

export const useMedlemDetaljer = (id: string, enabled: boolean) => {
    const query = useQuery({
        queryKey: queryKey.detail(id),
        queryFn: () => getMedlemDetaljer(id),
        enabled
    });

    return query;
};

// MEDLEM OPPDATERING STATUS //
const fetchMedlemOppdateringStatus = async (orgNr: string, medlemIndeks: string) => {
    const res = await http.get<model.OppdateringStatus[]>(
        `medlemmer/${orgNr}/oppdateringstatus?medlemIndeks=${medlemIndeks}`
    );

    // API returnerer alltid en liste med ett element.
    return res[0];
};

export const useMedlemOppdateringStatus = (medlemIndeks?: string) => {
    const { valgtOrganisasjon } = useBruker();

    return useQuery({
        queryKey: queryKey.oppdateringStatus(medlemIndeks!),
        queryFn: () => fetchMedlemOppdateringStatus(valgtOrganisasjon!.organisasjonsnummer, medlemIndeks!),
        enabled: !!medlemIndeks,
        staleTime: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false
    });
};

// OPPDATER MEDLEMMER //
type OppdaterMedlemmerArgs = {
    orgNr: string;
    endringRequest: model.OppdateringRequest;
};

const oppdaterMedlemmer = async ({ orgNr, endringRequest }: OppdaterMedlemmerArgs) =>
    http.post<model.MedlemOppdateringResponse>(`medlemmer/${orgNr}/oppdatermedlemmer`, endringRequest);

export const useOppdaterMedlemmer = () => {
    const { valgtOrganisasjon } = useBruker();

    const orgNr = valgtOrganisasjon!.organisasjonsnummer;

    return useMutation({
        mutationFn: (endringRequest: model.OppdateringRequest) => oppdaterMedlemmer({ orgNr, endringRequest })
    });
};
