import { Outlet, useLocation } from "react-router-dom";
import { Side, SideInnhold } from "../components/Side";
import { useBruker } from "../features/bruker/queries";
import "./FeilsideOppgradering.scss";
import { Typography } from "../components/Typography";

export const FeilsideOppgradering = () => {
    const { pathname } = useLocation();
    const { bruker } = useBruker();

    const showOutlet = ["/bytt-organisasjon", "/loggut"].some((path) => pathname.includes(path)) || pathname === "/";

    return (
        <div id="innhold">
            {showOutlet ? (
                <Outlet />
            ) : (
                <Side className="bm-feilside-oppgradering">
                    <SideInnhold hideFooter>
                        <Typography component="h1" variant="title">
                            Vi oppgraderer forsikringssidene våre
                        </Typography>
                        <p>
                            Kontakt din rådgiver, eller kom tilbake om en liten stund så burde alt være normalt igjen.
                            <br />
                            {bruker?.radgiverKontaktInfo && (
                                <a
                                    href={`mailto:${bruker.radgiverKontaktInfo.epost}`}
                                    className="jkl-button jkl-button--primary"
                                >
                                    Kontakt rådgiver
                                </a>
                            )}
                        </p>
                    </SideInnhold>
                </Side>
            )}
        </div>
    );
};
