import { agreementDetailsQuery } from "@features/agreements/agreements.queries";
import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";
import { LoaderFunction } from "react-router-dom";
import { queryClient } from "src/common/queryClient";
import { Bruker } from "src/model/bruker.typer";

export const loader: LoaderFunction = async ({ params }) => {
    const { agreementId } = params;

    const bruker = await queryClient.ensureQueryData<Bruker>({ queryKey: brukerQueryKey, queryFn: fetchBruker });

    if (bruker.gjeldendeOrganisasjonsnummer && agreementId) {
        const dataFetcher = await agreementDetailsQuery(bruker.gjeldendeOrganisasjonsnummer, agreementId);

        return {
            agreementDetailsResponse: dataFetcher
        };
    }

    return Promise.resolve();
};
