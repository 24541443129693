import React from "react";
import { Await, useLoaderData } from "react-router-dom";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { TTPQuery, useTilgangerTredjePart } from "../queries";
import { TTPFjernTilganger } from "../components/TTPFjernTilganger/TTPFjernTilganger";

export const Error = () => {
    return (
        <ErrorMessageBox title="Oi, litt tekniske problemer her">
            Vi klarer ikke å hente bedrifter og bedriftsansvarlige akkurat nå. Prøv en gang til eller kom tilbake
            senere.
        </ErrorMessageBox>
    );
};

export const loader = async () => ({
    tilganger: TTPQuery()
});

export const Composer = () => {
    const query = useTilgangerTredjePart();

    const loaderData = useLoaderData();

    return (
        <Side compact>
            <SideHeader title="Fjerne bedriftsansvarlig" />
            <SideInnhold loadingText="Henter tilganger" query={query} ignoreError>
                <React.Suspense>
                    <Await resolve={loaderData}>
                        {query.data && (
                            <TTPFjernTilganger
                                flytnavn="Fjerne bedriftsansvarlig"
                                options={query.data.tilganger.kamer}
                            />
                        )}
                    </Await>
                </React.Suspense>
            </SideInnhold>
        </Side>
    );
};
