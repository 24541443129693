import { SecondaryLinkButton } from "src/components/Button";
import { Typography } from "src/components/Typography";
import { CopyToClipboard } from "@features/invoice/components";
import { track, Trackingkey } from "../../../tracking";
import "./KontaktCard.scss";

interface Props {
    mobil?: string;
    epost?: string;
    btnTxt?: string;
}

export const KontaktCard = ({ mobil, epost, btnTxt }: Props) => {
    const contactTitle = mobil ? "Mobil" : epost ? "E-post" : "";
    const contactInfo = mobil ? mobil : epost ? epost : "";
    const buttonTxt = btnTxt ? btnTxt : mobil ? "Ring oss" : epost ? "Send e-post" : "Kontakt oss";
    const contactLink = mobil ? `tel:${mobil.replace(/\s/g, "")}` : epost ? `mailto:${epost}` : "";
    const trackingLenkeId = mobil ? "kontaktinfo/telefon" : epost ? "kontaktinfo/epost" : "";

    return (
        <div className="kontakt-card">
            <div>
                <Typography component="dt" variant="heading-4" className="jkl-spacing-xs--bottom">
                    {contactTitle}
                </Typography>
                <Typography variant="body">
                    <CopyToClipboard content={contactInfo?.toString()} actionLabel={contactTitle}>
                        {contactInfo}
                    </CopyToClipboard>
                </Typography>
            </div>
            <footer className="jkl-spacing-m--top">
                <SecondaryLinkButton
                    to={contactLink}
                    onClick={() => track({ hendelse: Trackingkey.LenkeTrykk, lenkeId: trackingLenkeId })}
                >
                    {buttonTxt}
                </SecondaryLinkButton>
            </footer>
        </div>
    );
};
