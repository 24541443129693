import { Typography } from "@components/Typography";
import { Trackingkey, track } from "src/tracking";
import "./AgreementCTA.scss";
import { Tag } from "@fremtind/jkl-tag-react";

type AgreementCTAProps = {
    carMake: string;
    registrationNumber: string;
    hasBeenPurchased: boolean;
};

export const AgreementCTA: React.FC<AgreementCTAProps> = ({ carMake, registrationNumber, hasBeenPurchased }) => {
    return (
        <section className="agreement-cta-card">
            <Tag>{hasBeenPurchased ? "Flyttes til oss" : "Forsikret i et annet selskap"}</Tag>
            <div>
                <Typography className="agreement-cta-card__title" variant="heading-2">
                    {carMake}
                </Typography>
                <Typography className="agreement-cta-card__description" variant="small">
                    {registrationNumber}
                </Typography>
            </div>
            {!hasBeenPurchased && (
                <a
                    href={"kjop/bil?registrationNumber=" + registrationNumber}
                    className="jkl-button jkl-button--secondary "
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.LenkeTrykk,
                            lenkeId: "Flytt bilforskring, inngang fra oversikt"
                        });
                    }}
                >
                    Se vårt tilbud
                </a>
            )}
        </section>
    );
};
