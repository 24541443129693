import React, { useContext, useMemo } from "react";
import { Navigate } from "react-router";
import { Flag, useFeatureflag } from "../../common/featureflag";
import { brukerHarTilgang } from "../../common/rolle";
import ThemeContext from "../../context/ThemeContext";
import { useBruker } from "../../features/bruker/queries";
import { BrukerRolle } from "../../model/bruker.typer";
import { Distributor } from "../../model/theme.typer";
import { IkkeFunnetFeil } from "../Feil/IkkeFunnetFeil/IkkeFunnetFeil";

interface Props {
    requiredRolle?: BrukerRolle | BrukerRolle[];
    requiredDistributor?: Distributor;
    redirectUrl?: string;
    featureflag?: Flag;
}

export const ProtectedRoute: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    requiredRolle,
    redirectUrl,
    requiredDistributor,
    featureflag
}) => {
    const { bruker } = useBruker();
    const { distributor } = useContext(ThemeContext);
    const featureIsEnabled = useFeatureflag(featureflag);

    const harTilgang = useMemo(() => {
        if (requiredDistributor && requiredDistributor !== distributor) {
            return false;
        }

        if (!requiredRolle) {
            return true;
        }

        if (!bruker) {
            return false;
        }

        return brukerHarTilgang(bruker, requiredRolle);
    }, [bruker, requiredRolle, requiredDistributor, distributor]);

    if (!harTilgang || (featureflag !== undefined && !featureIsEnabled)) {
        // bare redirect om bruker finnes og man har fått kjørt tilgangssjekk
        if (redirectUrl && harTilgang !== null) {
            return <Navigate to={redirectUrl} />;
        } else {
            return (
                <>
                    <IkkeFunnetFeil />
                </>
            );
        }
    }

    return <>{children}</>;
};
