import { useTasks } from "@features/tasks/tasks.queries";
import type { AgreementDetails as IAgreementDetails } from "src/model/gen";
import {
    AgreementButtons,
    AgreementDetailSection,
    AgreementClaimsSection,
    AgreementObjectList,
    AgreementRenewal,
    AgreementOnHold,
    AgreementTasksSection
} from "../../components";
import { TravelCardSection, SmartDelayMessage } from "../../components/AgreementSpecificComponents";

import styles from "./AgreementDetails.module.scss";

interface Props {
    agreementDetails: IAgreementDetails;
}

export const AgreementDetails = ({ agreementDetails }: Props) => {
    const tasks = useTasks();

    const filteredTasks = tasks.data?.filter((task) => task.agreementNumber === agreementDetails.agreementId);

    return (
        <>
            <AgreementButtons agreementDetails={agreementDetails} />
            <AgreementRenewal agreementDetails={agreementDetails} />
            <AgreementOnHold agreementDetails={agreementDetails} />
            <SmartDelayMessage agreementDetails={agreementDetails} />
            <AgreementClaimsSection agreementDetails={agreementDetails} />
            {filteredTasks && filteredTasks.length > 0 && <AgreementTasksSection agreementTasks={filteredTasks} />}
            <div className={styles.detailsWrapper}>
                <AgreementDetailSection agreementDetails={agreementDetails} />
                <TravelCardSection agreementDetails={agreementDetails} />
                <AgreementObjectList agreementDetails={agreementDetails} />
            </div>
        </>
    );
};
