import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useBruker } from "@features/bruker/queries";
import { track, Trackingkey } from "src/tracking";
import { Typography } from "@components/Typography";
import { TextArea } from "@fremtind/jkl-text-input-react";
import { PrimaryButton, TertiaryButton } from "@fremtind/jkl-button-react";
import { SkjemaFooter } from "@components/SkjemaFooter";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { FormDataChanges } from "../SkjemaKontroller";
import { useEndreAvtaledetaljer } from "../../agreementchanges.queries";
import { ContactInfoInputs } from "../../ContactInfoInputs";
import { FormProps } from "../FormProps";
import "../../AgreementChanges.scss";

export interface AvtaleTilEndring {
    annet?: string;
    epost: string;
    telefon?: string;
}

export type FieldValues = {
    avtale: AvtaleTilEndring;
};

export const StandardForm = (props: FormProps) => {
    useEffect(() => {
        props.onFerdig();
    }, [props]);
    const { valgtOrganisasjon } = useBruker();
    const [submitError, setSubmitError] = useState(false);

    const { register, formState, handleSubmit } = useFormContext<FormDataChanges>();

    const { errors } = formState;

    const [noChangesError, setNoChangesError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const endringerMutation = useEndreAvtaledetaljer();

    function checkForChanges(a?: string) {
        if (a) {
            setNoChangesError(false);
        }
    }

    // format the form values to a string
    function formatFormValues(formValues: FormDataChanges) {
        let returnstring = `Organisasjonsnummer: ${valgtOrganisasjon?.organisasjonsnummer}\nOrganisasjonsnavn: ${valgtOrganisasjon?.navn}\nAvtalenummer: ${props.avtaleDetaljer.agreementId}`;
        props.chosenObject && (returnstring += `\nValgt objekt: ${props.chosenObject}`);
        Object.entries(formValues).forEach(([key, value]) => {
            if (key === "annet" && value) {
                returnstring += `\nFritekst: ${value}`;
            }
        }, "");
        return returnstring;
    }

    function cancelForm() {
        props.dialogRef.current.hide();
        track({
            hendelse: Trackingkey.Skjemaflyt,
            flytnavn: "Endre avtale",
            handling: "avbryt",
            stegnavn: "Endre avtale"
        });
    }

    const onSubmit = async (formValues: FormDataChanges) => {
        if (formValues.annet) {
            if (props.avtaleDetaljer.id) {
                setIsLoading(true);
                try {
                    const res = await endringerMutation.mutateAsync({
                        id: props.avtaleDetaljer.id,
                        email: formValues.email,
                        phone: formValues.telefon,
                        message: formatFormValues(formValues) as string
                    });

                    if (!res) {
                        // commitEndringState("REJECTED");
                    } else {
                        // commitEndringState("RESOLVED");
                        props.onFrem();
                        setSubmitError(false);
                        setIsLoading(false);
                    }
                } catch (error) {
                    setSubmitError(true);
                }
            }
        } else {
            setNoChangesError(true);
        }

        track({
            hendelse: Trackingkey.Skjemaflyt,
            flytnavn: "Endre avtale",
            handling: "ferdig",
            stegnavn: "Endre avtale",
            avtale: props.avtaleDetaljer.productCode
        });

        track({
            hendelse: Trackingkey.Endre,
            type: "endre avtale",
            avtale: props.avtaleDetaljer.productCode,
            kommentar: formValues.annet
        });
    };

    props.onClose === true ? cancelForm() : null;

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="agreement-changes-form">
            <Typography className={"jkl-spacing-24--bottom agreement-changes-form__heading"} variant={"heading-3"}>
                Hva vil du endre?
            </Typography>

            <TextArea
                className="jkl-spacing-32--bottom"
                {...register("annet", {
                    required: false,
                    maxLength: {
                        value: 1000,
                        message: "Meldingen kan ikke være lengre enn 1000 tegn"
                    }
                })}
                startOpen
                label="Endringer"
                labelProps={{ variant: "medium" }}
                placeholder={"Beskriv det du vil endre"}
                onChange={(e) => {
                    checkForChanges(e.target.value);
                }}
                errorLabel={errors.annet?.message}
            />

            {noChangesError && (
                <div className="jkl-spacing-xl--top jkl-spacing-xl--bottom">
                    <ErrorMessageBox title="">Du må beskrive hva du ønsker å endre</ErrorMessageBox>
                </div>
            )}
            {submitError && (
                <div className="jkl-spacing-xl--top jkl-spacing-xl--bottom">
                    <ErrorMessageBox title="Endringen ble ikke lagret">
                        En teknisk feil har oppstått. Beklager!
                    </ErrorMessageBox>
                </div>
            )}
            <ContactInfoInputs />
            <SkjemaFooter>
                <PrimaryButton type="submit" loader={{ showLoader: isLoading, textDescription: "Lagrer endringer" }}>
                    Bekreft og lagre
                </PrimaryButton>
                <TertiaryButton type="button" aria-label="Avbryt endring" onClick={() => cancelForm()}>
                    Avbryt
                </TertiaryButton>
            </SkjemaFooter>
        </form>
    );
};
