import { InfoMessageBox } from "@fremtind/jkl-message-box-react";
import type { AgreementDetails } from "src/model/gen";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementOnHold = ({ agreementDetails }: Props) => {
    const isOnHold = agreementDetails.stateDescription === "Avtale på vent" && agreementDetails.status === "ACCEPT";

    if (!isOnHold) return null;

    return (
        <div style={{ maxWidth: "777px" }}>
            <InfoMessageBox
                title={"Avtalen er på vent"}
                fullWidth
                className="jkl-spacing-2xl--bottom"
                data-testid="agreement-onhold"
            >
                Avtalen trer automatisk i kraft når kjøretøyet er registrert på ny eier.
            </InfoMessageBox>
        </div>
    );
};
