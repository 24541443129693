import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Side, SideHeader, SideInnhold } from "../components/Side";
import { getDefaultFeilmelding, getFeilmelding } from "../common/utils";
import ThemeContext from "../context/ThemeContext";
import "./Feil.scss";
import "../App.scss";
import { Typography } from "@components/Typography";

export const Feil = () => {
    const [searchParams] = useSearchParams();
    const { BrandHeader } = useContext(ThemeContext);

    const feilMelding = getFeilmelding(searchParams.get("fId") ?? "") ?? getDefaultFeilmelding();

    return (
        <div className="page__wrapper bm-feil">
            <BrandHeader />

            <main>
                <Side>
                    <SideHeader title={feilMelding.title && feilMelding.title} />
                    <SideInnhold>
                        <Typography>{feilMelding.message}</Typography>
                    </SideInnhold>
                </Side>
            </main>
        </div>
    );
};
