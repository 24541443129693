/* eslint-disable react/no-unescaped-entities */
import { Link } from "react-router-dom";
import { ListItem, OrderedList, UnorderedList } from "@fremtind/jkl-list-react";
import { Typography } from "@components/Typography";
import { Distributor } from "../../model/theme.typer";
import { FaqCategory, FaqSchemaItem } from "./faq.model";

export const faq: FaqSchemaItem[] = [
    {
        requiresDistributor: Distributor.SB1,
        category: FaqCategory.ANSATTE_OG_MEDLEMMER,
        question: "Hvordan kan jeg melde inn eller ut en ny ansatt?",
        answer: (
            <p>
                Hvis de ansatte er registrert med navn på dine forsikringer går du til «Ansatte» i menyen. Der kan du
                velge «Meld inn ansatt» eller «Meld ut ansatt».
            </p>
        )
    },
    {
        requiresDistributor: Distributor.SB1,
        category: FaqCategory.ANSATTE_OG_MEDLEMMER,
        question: "Hvordan kan jeg endre antall ansatte eller årsverk?",
        answer: (
            <>
                <p>
                    Yrkesskade forholder seg til antall årsverk, mens de andre ansattforsikringene forholder seg til
                    antall ansatte.
                </p>
                <p>
                    Du kan endre antall mellom 0 og 60 dager før avtalens fornyelsestidspunkt. For endring på andre
                    tidspunkt må du kontakte din forsikringsrådgiver.
                </p>
                <p>
                    Det er ikke nødvendig å endre antall hvis det er mindre enn 10% endring av antall ansatte/årsverk.
                </p>
            </>
        )
    },
    {
        requiresDistributor: Distributor.DNB,
        category: FaqCategory.ANSATTE_OG_MEDLEMMER,
        question: "Hvordan kan jeg endre antall ansatte eller årsverk?",
        answer: (
            <>
                <p>Reiseforsikring forholder seg til antall ansatte.</p>
                <p>
                    Du kan endre antall mellom 0 og 60 dager før avtalens fornyelsestidspunkt. For endring på andre
                    tidspunkt må du kontakte din forsikringsrådgiver.
                </p>
                <p>Det er ikke nødvendig å endre antall hvis det er mindre enn 10% endring av antall ansatte.</p>
            </>
        )
    },
    {
        requiresDistributor: Distributor.SB1,
        category: FaqCategory.ANSATTE_OG_MEDLEMMER,
        question: "Hvordan kan jeg endre stillingsprosent og lønn for en ansatt?",
        answer: (
            <>
                <p>
                    Den ansattes lønn på er registrert på Sykelønnsforsikring. Lønnen kan når som helst endres under
                    «Ansatte» i menyen, deretter velger du «Endre lønn på sykelønn». Det er ikke nødvendig å registrere
                    lønn dersom lønnen er under 6G.
                </p>
                <p>
                    Stillingsprosent kan påvirke hvor mange årsverk som er registrert på yrkesskadeforsikring. Se
                    spørsmål over for hvordan du endre dette.
                </p>
            </>
        )
    },
    {
        requiresDistributor: Distributor.SB1,
        category: FaqCategory.ANSATTE_OG_MEDLEMMER,
        question: "Hvordan vet jeg hvilke ansatte som er forsikret?",
        answer: (
            <>
                <p>Noen forsikringer er registrert på antall, da ser du ikke navnet på hvem som er forsikret.</p>
                <p>
                    Andre forsikringer har registrert ansatte med navn og fødselsnummer. Oversikten over hvem som er
                    registrert med navn finner du under «Se ansatte i menyen».
                </p>
            </>
        )
    },
    {
        requiresDistributor: Distributor.DNB,
        category: FaqCategory.ANSATTE_OG_MEDLEMMER,
        question: "Hvordan vet jeg hvilke ansatte som er forsikret?",
        answer: <p>Våre forsikringer er registrert på antall, da ser du ikke navnet på hvem som er forsikret.</p>
    },
    {
        requiresDistributor: Distributor.SB1,
        category: FaqCategory.ANSATTE_OG_MEDLEMMER,
        question: "Hvordan kan jeg dele informasjon om ansattforsikringene med mine ansatte?",
        answer: (
            <p>
                Fra{" "}
                <Link className="jkl-link" to="/forsikringer">
                    forsikringsoversikten
                </Link>{" "}
                kan du lage en brosjyre for å informere dine ansatte om hvilke forsikringer de har. Velg «Lag
                ansattbrosjyre» til høyre for overskriften «Dine forsikringer» på forsikringsoversikten. Du kan velge
                hvilke ansattforsikringer som skal være med i brosjyren.
            </p>
        )
    },
    {
        category: FaqCategory.KJORETOY,
        question: "Hvordan kan jeg oppdatere kjørelengde eller kilometerstand?",
        answer: (
            <>
                <p>
                    Kjørelengde påvirker prisen på forsikringen, og for mulige skader er det viktig at vi har registrert
                    riktig kilometerstand. Det er derfor viktig at du oppdaterer kilometerstanden og kjørelengden hvis
                    du kjører mer eller mindre enn det som er registrert.
                </p>
                <p>
                    Vi jobber med at du selv kan endre kjørelengde og kilometerstand, men foreløpig må du kontakte
                    forsikringsrådgiver for å endre dette.
                </p>
            </>
        )
    },
    {
        category: FaqCategory.KJORETOY,
        question: "Hvordan kan jeg legge til et kjøretøy i flåtelisten?",
        answer: (
            <p>
                Vi jobber med en digital løsning hvor du kan legge til flere biler i flåteavtalen din. Nå kan du gi
                beskjed om dette i Kundeløsning BM, med behandling av rådgiver i etterkant. Velg «Se kjøretøy» i menyen
                og velg «Legg til kjøretøy». Fyll ut informasjon i e-posten som kommer opp.
            </p>
        )
    },
    {
        category: FaqCategory.KJORETOY,
        question: "Hvorfor vises utmeldte kjøretøy i biloversikten?",
        answer: (
            <p>
                Utmeldte kjøretøy vises i flåtelisten frem til neste hovedforfall. Dette er fordi fakturering først
                skjer ved avtaleperiodens slutt. Dette gjør faktureringsgrunnlaget enklere for deg. I flåtelisten er det
                tydelig merket under «Utmeldingsdato» hvilke kjøretøy som er utgått og hvilke som fremdeles er
                forsikret.
            </p>
        )
    },
    {
        category: FaqCategory.MITT_FORSIKRINGSFORHOLD,
        question: "Hvordan kjøper jeg flere forsikringer?",
        answer: (
            <>
                <p>
                    Du kan kjøpe nye bedriftsforsikringer hos din forsikringsrådgiver. Ta kontakt med vedkommende,
                    kontaktinformasjon finner du nederst til venstre på forsikringsoversikten.
                </p>
                <p>
                    Du kan også velge «Kjøp forsikring» i menyen. Fyll ut informasjonen vi spør om, og en
                    forsikringsrådgiver vil ta kontakt med deg.
                </p>
            </>
        )
    },
    {
        category: FaqCategory.MITT_FORSIKRINGSFORHOLD,
        question: "Hvordan administrerer jeg andre personers tilgang til bedriftens forsikringer?",
        answer: (
            <>
                <p>
                    Du administrerer tilganger via «Tilganger - Bedriftsnavn» i menyen. Du kan både gi, endre og fjerne
                    tilganger til en person.
                </p>
                <p>Det er tre roller i Brønnøysundregistrene i en bedrift som automatisk får tilgang:</p>
                <UnorderedList>
                    <ListItem>Daglig leder</ListItem>
                    <ListItem>Styreleder</ListItem>
                    <ListItem>Kontaktperson</ListItem>
                </UnorderedList>
                <p>
                    Når en person logger seg inn med BankID gjør vi automatisk oppslag mot offentlige registre, og gir
                    personene som har rollene over automatisk tilgang.
                </p>
            </>
        )
    },
    {
        category: FaqCategory.MITT_FORSIKRINGSFORHOLD,
        question:
            "Hvordan administrerer jeg andre selskapers tilgang til bedriftens forsikringer, for eksempel regnskapsbyrå eller morselskap?",
        answer: (
            <p>
                Du administrerer tilganger via «Tilganger - Bedriftsnavn» i menyen. Du kan både gi, endre og fjerne
                tilganger til en bedrift.
            </p>
        )
    },
    {
        category: FaqCategory.MITT_FORSIKRINGSFORHOLD,
        question: "Hvorfor vises ikke alle forsikringer i forsikringsoversikten?",
        answer: (
            <>
                <p>
                    I forsikringsoversikten skal alle forsikringer fra Fremtind som er registrert på organisasjonsnummer
                    vises.
                </p>
                <p>Liv- og pensjonsforsikringer kjøpt fra andre forsikringsselskap vises ikke her.</p>
                <p>Private forsikringer fra Fremtind registrert på et fødselsnummer vises ikke her.</p>
            </>
        )
    },
    {
        category: FaqCategory.SKADESAKER,
        question: "Hvordan melder jeg en ny skadesak?",
        answer: (
            <p>
                Du kan sende en ny skadesak fra oversikten over{" "}
                <Link className="jkl-link" to="/skadesaker">
                    skadesakene dine
                </Link>
                . Sakene sendes gjennom et annet system, men vil dukke opp på siden med status etter den er sendt.
            </p>
        )
    },
    {
        category: FaqCategory.SKADESAKER,
        question: "Hvordan ser jeg pågående og tidligere skadesaker?",
        answer: (
            <p>
                Vi har oversikt over{" "}
                <Link className="jkl-link" to="/skadesaker">
                    dine skadesaker
                </Link>{" "}
                her.
            </p>
        )
    },
    {
        category: FaqCategory.MITT_FORSIKRINGSFORHOLD,
        question: "Hvordan kan jeg endre omsetning på mine forsikringer?",
        answer: (
            <p>
                Endring av omsetning kan du dessverre ikke gjøre selv. Kontakt din forsikringsrådgiver for å oppdatere
                omsetning ved endring, slik at du er riktig dekket ved skade eller uhell.
            </p>
        )
    },
    {
        category: FaqCategory.MITT_FORSIKRINGSFORHOLD,
        question: "Hvordan kontakter jeg en forsikringsrådgiver?",
        requiresDistributor: Distributor.SB1,
        answer: (
            <>
                <p>
                    For henvendelser om dine forsikringsavtaler, ta kontakt med din forsikringsrådgiver.
                    Kontaktinformasjon står nede til venstre på forsikringsoversikten.
                </p>

                <p>
                    For skadehenvendelser, kontakt oss på telefon{" "}
                    <a href="tel:91502300" className="jkl-link">
                        915 02300
                    </a>
                    .
                </p>

                <p>
                    Hvis du foretrekker å sende brev er vår postadresse:
                    <br />
                    Fremtind Forsikring AS
                    <br />
                    Postboks 778 Sentrum
                    <br />
                    0106 OSLO
                </p>
            </>
        )
    },
    {
        category: FaqCategory.MITT_FORSIKRINGSFORHOLD,
        question: "Hvordan kontakter jeg en forsikringsrådgiver?",
        requiresDistributor: Distributor.DNB,
        answer: (
            <>
                <p>
                    For henvendelser om dine forsikringsavtaler, ta kontakt med din forsikringsrådgiver.
                    Kontaktinformasjon står nede til venstre på forsikringsoversikten.
                </p>

                <p>
                    For skadehenvendelser, kontakt oss på telefon{" "}
                    <a href="tel:91504800" className="jkl-link">
                        915 04800
                    </a>
                    .
                </p>

                <p>
                    Hvis du foretrekker å sende brev er vår postadresse:
                    <br />
                    Fremtind Forsikring AS
                    <br />
                    Postboks 778 Sentrum
                    <br />
                    0106 OSLO
                </p>
            </>
        )
    },
    {
        category: FaqCategory.MITT_FORSIKRINGSFORHOLD,
        question: "Hvordan kontakter jeg en forsikringsrådgiver?",
        requiresDistributor: Distributor.MEG,
        answer: (
            <>
                <p>
                    For henvendelser om dine forsikringsavtaler, ta kontakt med din forsikringsrådgiver.
                    Kontaktinformasjon står nede til venstre på forsikringsoversikten.
                </p>

                <p>
                    For skadehenvendelser, kontakt oss på telefon{" "}
                    <a href="tel:91502300" className="jkl-link">
                        915 02300
                    </a>
                    .
                </p>

                <p>
                    Hvis du foretrekker å sende brev er vår postadresse:
                    <br />
                    Fremtind Forsikring AS
                    <br />
                    Postboks 778 Sentrum
                    <br />
                    0106 OSLO
                </p>
            </>
        )
    },
    {
        category: FaqCategory.DOKUMENTER_OG_FORSIKRINGSBEVIS,
        question: "Hvordan kan jeg laste ned forsikringsdokumenter som PDF?",
        answer: (
            <>
                <p>Forsikringsdokumenter kan lastes ned ved å velge «Alle dokumenter» i menyen.</p>
                <p>
                    Listen viser deg dokumenter for de siste 12 månedene, men du har mulighet til å gå lengre tilbake i
                    tid ved å endre datofeltet. Du kan filtrere på produkt, avtalenummer og dokumenttype. Alle
                    dokumenter kan lastet ned som PDF. Du finner også forsikringsdokumenter for hver avtale ved å gå inn
                    på avtalene fra{" "}
                    <Link className="jkl-link" to="/forsikringer">
                        forsikringsoversikten
                    </Link>
                    .
                </p>
            </>
        )
    },
    {
        category: FaqCategory.DOKUMENTER_OG_FORSIKRINGSBEVIS,
        question: "Hvordan kan jeg få forsikringsbekreftelser eller forsikringsbevis?",
        answer: (
            <p>
                Du finner forsikringsbekreftelser og forsikringsbevis under «Alle dokumenter» i menyen. Under
                «Dokumenttype» kan du velge å kun se forsikringsbekreftelser. Ta kontakt med din forsikringsrådgiver
                dersom du ikke finner ønsket bekreftelse.
            </p>
        )
    },
    {
        category: FaqCategory.DOKUMENTER_OG_FORSIKRINGSBEVIS,
        question: "Hvordan kan jeg få grønt kort?",
        answer: (
            <>
                <p>
                    Grønt kort er et internasjonalt forsikringskort som bekrefter at kunden har gyldig ansvarsforsikring
                    på kjøretøyet sitt, med en varighet på inntil 3 måneder.
                </p>
                <p>
                    Grønt kort kan sendes på e-post og er gyldig elektronisk, men vi anbefaler at du tar med et fysisk
                    grønt kort du får av oss. Kontakt forsikringsrådgiver for å bestille grønt kort. Merk at det kan ta
                    inntil to uker før du mottar grønt kort i posten.
                </p>
            </>
        )
    },
    {
        category: FaqCategory.FAKTURA,
        question: "Hva er AvtaleGiro og EHF?",
        answer: (
            <>
                <p>
                    Avtalegiro og EHF er to betalingsmetoder som gjør at du får faktura fra oss rett inn i nettbanken
                    eller økonomisystemet ditt.
                    <br />
                    Hvis du ønsker å opprette AvtaleGiro/EHF med oss, les spørsmål «Hvordan oppretter jeg AvtaleGiro
                    eller EHF».
                </p>
                <p>
                    <b>AvtaleGiro</b>
                    <br />
                    AvtaleGiro er en betalingstjeneste fra banken som gjør at faste fakturaer blir betalt automatisk.
                    Faktura går rett fra oss til banken din.
                </p>
                <p>
                    <b>EHF</b>
                    <br />
                    EHF er et standardisert fakturaformat som gjør at faktura går rett inn i økonomisystemet ditt.
                    Informasjonen leses automatisk og blir brukt til både til betaling og regnskapsføring.
                </p>
            </>
        )
    },
    {
        category: FaqCategory.FAKTURA,
        question: "Hvordan oppretter jeg avtalegiro eller EHF?",
        answer: (
            <>
                <p>
                    <b>Avtalegiro</b>
                    <br />
                    Du oppretter Avtalegiro i nettbanken din. Du får ikke faktura i posten når du har valgt AvtaleGiro.
                    <br />
                    Du kan selv velge betalingsfrekvens, men forfallsdato må være mellom 15. og 25. i måneden.
                </p>

                <p>
                    <b>EHF</b>
                    <br />
                    EHF er et digitalt fakturaformat som gjør det mulig å sende fakturaer direkte fra oss til
                    økonomisystemet ditt. Det kommer ikke faktura i posten når du har valgt EHF.
                    <br />
                    For å få faktura i EHF-format må bedriften oppfylle noen kriterier:
                    <OrderedList>
                        <ListItem>
                            Bedriften må være registrert i ELMA-registeret. Dette kan du gjøre i de fleste
                            økonomisystemer.
                        </ListItem>
                        <ListItem>
                            Du må slette eventuell AvtaleGiro-avtale dere har med oss. Du kan slette den i nettbanken.
                        </ListItem>
                        <ListItem>
                            Dere må være registrert med betalingstype giro hos oss. Du kan gå inn på en faktura i
                            kundeløsningen for å se hvilken betalingstype som er registrert. Hvis det ligger feil type i
                            systemet, kan rådgiver endre dette for deg
                        </ListItem>
                    </OrderedList>
                </p>
            </>
        )
    },
    {
        category: FaqCategory.FAKTURA,
        question: "Vi er registrert i ELMA-registeret, men mottar ikke EHF, hva gjør vi?",
        answer: (
            <p>
                For at fakturaer skal bli sendt med EHF må det som oftest gjøres noe i nettbanken og i våre løsninger
                dersom du for eksempel har benyttet AvtaleGiro tidligere.
                <br />
                <br />
                For å få faktura i EHF-format må bedriften oppfylle noen kriterier:
                <OrderedList>
                    <ListItem>
                        Bedriften må være registrert i ELMA-registeret. Dette kan du gjøre i de fleste økonomisystemer.
                    </ListItem>
                    <ListItem>
                        Du må slette eventuell AvtaleGiro-avtale dere har med oss. Du kan slette den i nettbanken.
                    </ListItem>
                    <ListItem>
                        Dere må være registrert med betalingstype giro hos oss. Du kan gå inn på en faktura i
                        kundeløsningen for å se hvilken betalingstype som er registrert. Hvis det ligger feil type i
                        systemet, kan rådgiver endre dette for deg
                    </ListItem>
                </OrderedList>
            </p>
        )
    },
    {
        category: FaqCategory.FAKTURA,
        question: "Hva betyr trafikkforsikringsavgift (TFA)?",
        answer: (
            <>
                <p>
                    TFA het tidligere årsavgift, og blir krevd inn for alle registrerte kjøretøy med lovpålagt
                    ansvarsforsikring. Det er forsikringsselskapene som krever inn avgiften på vegne av staten.
                </p>
                <p>
                    Vi fakturerer derfor både forsikringspris og TFA, og gjør dette samtidig. På fakturaen er TFA
                    spesifisert for hvert kjøretøy, og beløpet deles opp i samme antall betalingsterminer som
                    forsikringsavtalen.
                </p>
            </>
        )
    },
    {
        category: FaqCategory.FAKTURA,
        question: "Hvorfor varierer månedsbeløpene ved månedlig betaling?",
        answer: (
            <>
                <p>
                    Hvis bedriften velger månedlig betaling, beregner vi de syv første månedlige terminbeløpene til 30
                    dager. De resterende fem månedlige terminbeløpene beregner vi til 31 dager. Til sammen utgjør dette
                    et forsikringsår på 365 dager. Dette gjør at de fem siste månedsbeløpene er litt høyere enn de syv
                    første.
                </p>
                <p>
                    Betaling for forsikringer skjer forskuddsvis, og derfor vil ofte første faktura, etter at du opprett
                    forsikringsavtalen, bestå av en lengre periode enn ordinær månedlig termin. Dette kommer fra av fra-
                    og til-dato i fakturaspesifikasjonen.
                </p>
                <p>
                    Hvis du gjør endringer på forsikringsavtalen som påvirker prisen, vil det føre til at månedspris
                    blir forandret etter endringene.
                </p>
            </>
        )
    },
    {
        category: FaqCategory.FAKTURA,
        question: "Hvorfor har vi mottatt flere fakturaer for samme periode?",
        answer: (
            <>
                <p>
                    Av og til kommer det en faktura som erstatter den opprinnelige. Dette skjer som oftest når det er
                    gjort endringer på forsikringen på ulike tidspunkt. I slike tilfeller skal du bare betale den nye
                    fakturaen. Det betyr at du ikke trenger å betale den opprinnelige fakturaen.
                </p>
                <p>Hvis du allerede har betalt opprinnelig faktura skal dette beløpet trekkes fra ny faktura.</p>
                <p>
                    På fakturasiden i kundeløsningen har du en oversikt over hvilke fakturaer som er betalt og hvilke
                    som ikke er betalt, eller annullert. Vi oppdaterer også beløp når du har gjort en innbetaling.
                </p>
            </>
        )
    },
    {
        category: FaqCategory.FAKTURA,
        question: "Hvorfor viser fakturaen en periode tilbake i tid?",
        answer: (
            <p>
                Når det skjer en endring på en forsikringsavtale, blir det registrert en dato endringen skal gjelde fra
                (fra-dato) og hvor lenge endringen skal gjelde til (til-dato, som regel ut forsikringsavtalens
                varighet). Hvis endringen skjer tilbake i tid, betyr dette at fra-dato viser denne datoen. Som regel
                skjer dette etter avtale med rådgiver.
            </p>
        )
    },
    {
        category: FaqCategory.FAKTURA,
        question:
            "Jeg har fått beskjed om at avtalene blir sagt opp fordi jeg ikke har betalt en faktura. Er jeg fremdeles forsikret?",
        answer: (
            <>
                <p>
                    Forsikringsavtaler som ikke blir betalt, blir sagt opp. Hvis du betaler avtalen etter at
                    forsikringen er sagt opp, må du kontakte rådgiver for å starte avtalene igjen. Dette skjer ikke
                    automatisk.
                </p>
                <p>Hvis du går til forsikringsoversikten, ser du hvilke forsikringer som er aktive akkurat nå.</p>
            </>
        )
    },
    {
        category: FaqCategory.FAKTURA,
        question: "Hvordan kan jeg betale fra utenlandsk bankkonto?",
        answer: (
            <p>
                For innbetaling til Fremtind Forsikring AS fra utenlandsk bankkonto trenger du følgende opplysninger:
                <UnorderedList>
                    <ListItem>IBAN-nummer: NO7290011700771</ListItem>
                    <ListItem>SWIFT/BIC: SHEDNO22XXX </ListItem>
                </UnorderedList>
            </p>
        )
    },
    {
        category: FaqCategory.PRIVAT,
        requiresDistributor: Distributor.SB1,
        question: "Jeg er ansatt i bedriften, hvordan får jeg tilgang til mine ansattforsikringer?",
        answer: (
            <Typography>
                De fleste ansattforsikringer vil være tilgjengelig under “Dine privatforsikringer“ (se relaterte
                tjenester). Dersom de ikke er det, ta kontakt med din nærmeste leder.
            </Typography>
        )
    },
    {
        category: FaqCategory.PRIVAT,
        requiresDistributor: Distributor.DNB,
        question: "Jeg er ansatt i bedriften, hvordan får jeg tilgang til mine ansattforsikringer?",
        answer: "Se din bedrifts intranett eller kontakt din nærmeste leder for informasjon om dine forsikringer."
    },
    {
        category: FaqCategory.PRIVAT,
        requiresDistributor: Distributor.SB1,
        question: "Hvor er reiseforsikringen jobben min betaler for?",
        answer: (
            <Typography>
                Reiseforsikringen og reisekort vil vanligvis være tilgjengelig under “Dine privatforsikringer“ (se
                relaterte tjenester). Dersom den ikke er det, ta kontakt med din nærmeste leder.
            </Typography>
        )
    },
    {
        category: FaqCategory.PRIVAT,
        requiresDistributor: Distributor.DNB,
        question: "Hvor er reiseforsikringen jobben min betaler for?",
        answer: "Se din bedrifts intranett eller kontakt din nærmeste leder for informasjon om reiseforsikringen din og reisekort."
    },
    {
        category: FaqCategory.TILGANGER,
        question: "Hvordan får jeg tilgang til en ny bedrift?",
        answer: "Du får automatisk tilgang med administratorrettigheter hvis du er registrert som daglig leder, styreleder eller kontaktperson i Brønnøysundregistrene. Hvis noen andre i bedriften er administrator, kan de gi deg tilgang om de logger inn. Forsikringsrådgiveren din kan også gi deg tilgang"
    },
    {
        category: FaqCategory.TILGANGER,
        question: "Hvordan gir jeg tilgang til en ny person eller bedrift?",
        answer: "Administratorer kan logge inn og gi tilgang til andre personer eller bedrifter gjennom “Tilganger”-siden."
    },
    {
        category: FaqCategory.TILGANGER,
        question: "Jeg har fått beskjed om å bekrefte reelle rettighetshavere, men har ikke tilgang til bedriften",
        answer: "Hvis du har mottatt brev om å bekrefte reelle rettighetshavere, men ikke har administratorrettigheter, ber vi deg om å videreformidle brevet til rett person i bedriften."
    }
];
