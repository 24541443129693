import { Navigate } from "react-router-dom";
import { IkkeFunnetFeil } from "./IkkeFunnetFeil/IkkeFunnetFeil";
import { IHttpError } from "../../model/http.typer";
import { UventetFeil } from "./UventetFeil/UventetFeil";

interface Props {
    error: IHttpError;
    redirectOnOk?: string;
    illustrasjon?: boolean;
}

enum Status {
    OK = 200,
    NOT_AUTHORIZED = 401,
    NOT_FOUND = 404
}

export function Feil({ error, redirectOnOk, illustrasjon = true }: Props) {
    // I noen tilfeller der brukeren ber om data den ikke skal se vil backend kaste et exception med status 200 OK,
    // og da skal brukeren redirectes til root eller annen passende side.
    switch (error.status) {
        case Status.OK:
            return <Navigate to={redirectOnOk === undefined ? "/" : redirectOnOk} />;

        case Status.NOT_AUTHORIZED:
            // Her blir man sendt til leverandør sin nettside for autorisering.
            // Vi returnerer null for å unngå blinking.
            // Koden finnes i @features/core/http.ts -> handleError()
            return null;

        case Status.NOT_FOUND:
            return <IkkeFunnetFeil />;

        default:
            return <UventetFeil errorToLog={error} illustrasjon={illustrasjon} />;
    }
}
