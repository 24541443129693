import "./SideHeader.scss";

import { ReactElement, ReactNode } from "react";
import classNames from "classnames";

import { css } from "@emotion/react";
import { useScreen } from "@fremtind/jkl-react-hooks";
import { InfoTag } from "@fremtind/jkl-tag-react";

import { BreadCrumbs } from "../BreadCrumbs";
import { SkeletonAnimation, SkeletonElement } from "../Skeleton";

interface SideHeaderNyProps {
    title?: string;
    lead?: string | ReactNode;
    indented?: boolean;
    compact?: boolean;
    children?: ReactNode;
    toolbar?: ReactElement;
    footer?: ReactNode;
    loading?: boolean;
    transparentBackground?: boolean;
    lagringsforsikring?: boolean;
    hideBreadcrumbs?: boolean;
}

/**
 * Bruk som header på sider. Underelementer vil dukke opp til høyre for teksten.
 */
export const SideHeader = (props: SideHeaderNyProps) => {
    const { isSmallDevice, isMediumDevice } = useScreen();
    const menuPreference = "mega";

    const wrapperClasses = classNames("side-header__wrapper", {
        "side-header__wrapper--with-megameny": menuPreference === "mega" || isSmallDevice || isMediumDevice,
        "side-header__wrapper--transparent-bg": props.transparentBackground
    });

    const contentClasses = classNames("side-header__content", {
        "side-header__content--indented": props.indented,
        "side-header__content--compact": props.compact
    });

    const textClasses = classNames("side-header__content__text", {
        "side-header__content__text--with-toolbar": props.toolbar
    });

    return (
        <div className={wrapperClasses}>
            <div className={contentClasses}>
                {!props.hideBreadcrumbs && <BreadCrumbs />}

                {!props.loading ? (
                    <>
                        {props.lagringsforsikring && (
                            <div
                                css={css`
                                    width: 100%;
                                    flex-grow: 1;
                                `}
                            >
                                <InfoTag>Lagringsforsikring</InfoTag>
                            </div>
                        )}

                        <div className={textClasses}>
                            {props.title && <h1>{props.title}</h1>}

                            {props.toolbar ? <div className="side-header__toolbar">{props.toolbar}</div> : null}

                            {props.lead && typeof props.lead === "string" ? (
                                <p className="side-header__content-wrapper">{props.lead}</p>
                            ) : props.lead ? (
                                <div className="side-header__content-wrapper">{props.lead}</div>
                            ) : null}
                        </div>
                        {props.children}
                        {props.footer && <div className="side-header__footer">{props.footer}</div>}
                    </>
                ) : (
                    <SkeletonAnimation>
                        <SkeletonElement style={{ marginBottom: 16 }} width={256} height={64} />
                        <SkeletonElement style={{ marginBottom: 12 }} width={512} height={20} />
                        <SkeletonElement style={{ marginBottom: 12 }} width={384} height={20} />
                        <SkeletonElement style={{ marginBottom: 12 }} width={256} height={20} />
                    </SkeletonAnimation>
                )}
            </div>
        </div>
    );
};
