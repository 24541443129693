import { AnsattBrosjyreAvtaleRequest, AnsattbrosjyreFormData } from "./Ansattbrosjyre";

function isChecked(value: Partial<AnsattBrosjyreAvtaleRequest>): value is AnsattBrosjyreAvtaleRequest {
    return [value.agreementId && value.agreementId && value.productId].filter((v) => !v).length < 1;
}

export function someChecked(data: AnsattbrosjyreFormData) {
    return data.checked.filter((avtale) => avtale.productId).length > 0;
}

export function onlyChecked(data: AnsattbrosjyreFormData): AnsattBrosjyreAvtaleRequest[] {
    return data.checked.filter(isChecked);
}

export function check(avtale: AnsattBrosjyreAvtaleRequest): Partial<AnsattBrosjyreAvtaleRequest> {
    return {
        productId: avtale.productId,
        objectId: avtale.objects?.length === 1 ? avtale.objects[0].objectId : undefined,
        agreementId: avtale.objects?.length === 1 ? avtale.objects[0].agreementId : undefined
    };
}

export function uncheck(avtale: Partial<AnsattBrosjyreAvtaleRequest>): Partial<AnsattBrosjyreAvtaleRequest> {
    return Object.fromEntries(Object.entries(avtale).map(([key]) => [key, undefined]));
}

export function getAvtaleDescription(avtaler: AnsattBrosjyreAvtaleRequest[], checked: AnsattBrosjyreAvtaleRequest) {
    const produkt = avtaler.find((produkt) => produkt.productId === checked.productId);
    const gruppe = produkt?.objects?.find(
        (gruppe) => gruppe.objectId === checked.objectId && gruppe.agreementId === checked.agreementId
    );

    const avtaleNavn = produkt?.productName;
    const gruppeNavn = produkt && produkt.objects!.length > 1 ? gruppe?.objectName ?? `Gruppe ${gruppe?.objectId}` : "";

    return `${avtaleNavn}${gruppeNavn && ` - ${gruppeNavn}`}`;
}

export function getKvitteringPositives(avtaler: AnsattBrosjyreAvtaleRequest[], formData: AnsattbrosjyreFormData) {
    return onlyChecked(formData).map((checked) => getAvtaleDescription(avtaler, checked));
}
