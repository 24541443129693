import { HTMLProps } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import classnames from "classnames";
import { Loader } from "@fremtind/jkl-loader-react";
import { IHttpError } from "../../model/http.typer";
import { FeilWrapper, FeilWrapperProps } from "../LoadOgFeilWrapper/FeilWrapper";
import { Typography } from "../Typography";
import { KundeFeilmelding } from "../../model/feilkoder.object";
import "./SideInnhold.scss";
import { FooterProps, SideFooter } from "./SideFooter";

function isHttpError(error: any): error is IHttpError {
    if (!error) return false;

    return "status" in error && "body" in error;
}

type WithFeilmelding = { feilmelding?: KundeFeilmelding };

function isWithFeilmelding(data: any): data is WithFeilmelding {
    if (typeof data === "object" && data) {
        return "feilmelding" in data;
    }

    return false;
}

interface Props extends HTMLProps<HTMLDivElement> {
    loadingText?: string;
    query?: UseQueryResult<any[] | unknown | any, Error | IHttpError>;
    showLoadingText?: boolean;
    errorProps?: FeilWrapperProps;
    compact?: boolean;
    className?: string;
    errorRedirectTo?: string;
    indented?: boolean;
    compactTextOnly?: boolean;
    disableLoader?: boolean;
    forceLoader?: boolean;
    ignoreError?: boolean;
    footerProps?: FooterProps;
    hideFooter?: boolean;
}

export const SideInnhold = ({
    children,
    loadingText,
    showLoadingText,
    query,
    className,
    errorProps,
    compact,
    errorRedirectTo,
    indented,
    compactTextOnly,
    disableLoader,
    forceLoader,
    ignoreError,
    footerProps,
    hideFooter,
    ...props
}: Props) => {
    const classes = classnames("side-innhold", className, {
        "side-innhold--compact": compact,
        "side-innhold--compact-text-only": compactTextOnly,
        "side-innhold--indented": indented
    });

    if (forceLoader || (query?.isLoading && !disableLoader)) {
        return (
            <div className={classes}>
                {showLoadingText && <Typography>{loadingText}</Typography>}
                <Loader textDescription={loadingText || "Henter nødvendig data"} />
            </div>
        );
    }

    if (!ignoreError && isHttpError(query?.error)) {
        return (
            <FeilWrapper errorRedirectTo={errorRedirectTo} error={query?.error} {...errorProps}>
                <div className={classes}>{children}</div>
            </FeilWrapper>
        );
    }

    return (
        <div {...props} className={classes}>
            {isWithFeilmelding(query?.data) && query?.data.feilmelding && (
                <ErrorMessageBox title={query?.data.feilmelding.title} className="jkl-spacing-2xl--bottom">
                    {query?.data.feilmelding.message}
                </ErrorMessageBox>
            )}

            {children}
            {!hideFooter && <SideFooter {...footerProps} />}
        </div>
    );
};
