import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { formatInput } from "@fremtind/jkl-datepicker-react";
import { queries, components } from "@features/navneliste";
import { Side, SideHeader, SideInnhold } from "../components/Side";
import { Steg, SkjemaFlyt, SkjemaFlytProps, FormTrackingMeta } from "../components/SkjemaFlyt";
import { Typography } from "../components/Typography";
import { Trackingkey } from "../tracking";

const { ChooseForsikring, ChooseInsured, MoreInfo, Receipt } = components.Utmelding;
const { queryKey, useAnsattList, useAnsattOppdateringStatus, useOppdaterAnsatte } = queries.ansatt;

type StegProps = components.Utmelding.StegProps;
type FormState = components.Utmelding.FormState;

const stegListe: Steg<StegProps>[] = [
    {
        stegnavn: "Velg ansatt",
        element: (props) => (
            <ChooseInsured
                textContent={{ lead: "Du kan velge én ansatt", listInstruction: "Velg en ansatt" }}
                searchFieldProps={{
                    placeholder: "Navn eller f.nr på ansatt"
                }}
                backLinkTo="/ansatte-navn"
                {...props}
            />
        )
    },
    {
        stegnavn: "Velg forsikring",
        element: (props) => <ChooseForsikring backLinkTo="/ansatte-navn" {...props} />
    },
    {
        stegnavn: "Siste detaljer",
        element: (props) => (
            <MoreInfo
                oversiktLink="/ansatte-navn"
                meldUtButtonProps={{
                    track: [
                        {
                            hendelse: Trackingkey.Knappetrykk,
                            knappeId: "meld-ut-mer-info-meld-ut-knapp"
                        }
                    ]
                }}
                {...props}
            />
        )
    },
    {
        stegnavn: "Kvittering",
        element: (props) => <Receipt oversiktLink="/ansatte-navn" {...props} />
    }
];

function AnsattUtmeldingSkjemaFlyt(
    props: Omit<StegProps, "onFrem" | "onTilbake" | "onAvbryt" | "onFerdig" | "onRestart"> & SkjemaFlytProps<StegProps>
) {
    return <SkjemaFlyt {...props} />;
}

export function AnsattUtmelding() {
    const queryClient = useQueryClient();
    const formMethods = useForm<FormState>({
        defaultValues: {
            sluttdato: formatInput(new Date()) as unknown as Date
        }
    });

    const [meta, setMeta] = useState<FormTrackingMeta>();

    const { indeks } = useParams<"indeks">();
    const insuredIndeks = formMethods.watch("insuredIndeks");
    const ansattIndeks = indeks || insuredIndeks;

    const mutation = useOppdaterAnsatte();
    const ansatteQuery = useAnsattList();
    const oppdateringStatusQuery = useAnsattOppdateringStatus(ansattIndeks);

    useEffect(() => {
        if (indeks) {
            formMethods.setValue("insuredIndeks", indeks);
        }
    }, [formMethods, indeks]);

    return (
        <Side>
            <SideHeader title="Meld ut ansatt" compact />
            <SideInnhold
                loadingText="Henter liste over ansatte"
                query={ansatteQuery}
                forceLoader={ansatteQuery.isFetching}
                footerProps={{ feedbackProps: { formFlowName: meta?.flowName, formStepName: meta?.stepName } }}
            >
                {ansatteQuery.data && ansatteQuery.data.entity.ansatte.length ? (
                    <>
                        <AnsattUtmeldingSkjemaFlyt
                            flytnavn="meld-ut-ansatt"
                            formMethods={formMethods}
                            setFormTrackingMeta={setMeta}
                            stegListe={stegListe.slice(indeks ? 1 : 0)}
                            insuredList={ansatteQuery.data.entity.ansatte}
                            availableAvtaler={
                                oppdateringStatusQuery.isFetching ? undefined : oppdateringStatusQuery.data?.avtaler
                            }
                            mutation={mutation}
                            onMutationSuccess={(data) => {
                                if (!data.feilkoder.length) {
                                    queryClient.setQueryData(queryKey.list(), {
                                        entity: data.entity.oversiktDto
                                    });
                                }
                            }}
                        />
                    </>
                ) : (
                    <Typography>Ingen ansatte er meldt inn enda...</Typography>
                )}
            </SideInnhold>
        </Side>
    );
}
