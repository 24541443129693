import { Suspense, useEffect, useMemo, useState } from "react";
import { useClaimsList } from "@features/claims/claims.queries";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { Loader } from "@fremtind/jkl-loader-react";
import { ErrorBoundary } from "@sentry/react";
import { Await, useLoaderData } from "react-router-dom";
import { AgreementDetails as IAgreementDetails, type ClaimOverview } from "src/model/gen";
import { track, Trackingkey } from "src/tracking";

import { AgreementDetails } from "./AgreementDetails";
import { AgreementDetailsHeader } from "../../components";

interface LoaderData {
    agreementDetailsResponse: IAgreementDetails;
}

export const AgreementDetailsComposer = () => {
    const agreementDetailsFetcher = useLoaderData() as LoaderData;
    const agreementDetails = agreementDetailsFetcher.agreementDetailsResponse;
    const [registeredMixpanelEvent, setRegisteredMixpanelEvent] = useState(false);
    const { data } = useClaimsList();

    const onGoingClaims = ((data as unknown as ClaimOverview[]) ?? []).filter(
        (d) => d.agreementNumber.includes(agreementDetails.agreementId) && d.status !== "Avsluttet"
    );

    useEffect(() => {
        if (!registeredMixpanelEvent) {
            setRegisteredMixpanelEvent(true);

            track({
                hendelse: Trackingkey.SeAvtaledetaljer,
                produkt: agreementDetails.productCode,
                kategori: agreementDetails.category,
                forsikringstype: agreementDetails.groupInsuranceType,
                kanEndres: agreementDetails.changeable,
                startdato: agreementDetails.startDate,
                sluttdato: agreementDetails.endDate,
                coreSystem: agreementDetails.coreSystem,
                objekter: agreementDetails.objects.map(
                    (o, index) => o.name ?? `Objekt ${index + 1} av ${agreementDetails.objects.length}`
                ),
                status: agreementDetails.status,
                nyAvtaleTilgjengelig: agreementDetails.nextVersion !== undefined,
                skadesaker: onGoingClaims.length,
                sistOppdatert: agreementDetails.lastUpdated
            });
        }
    }, [agreementDetails, onGoingClaims, registeredMixpanelEvent]);

    const footerProps = useMemo(() => {
        if (agreementDetailsFetcher.agreementDetailsResponse) {
            const agreementDetails = agreementDetailsFetcher.agreementDetailsResponse;

            return { feedbackProps: { product: `${agreementDetails.productName} - ${agreementDetails.productCode}` } };
        }

        return undefined;
    }, [agreementDetailsFetcher]);

    return (
        <Side>
            <Suspense fallback={<SideHeader title="Henter forsikringer..." />}>
                <Await resolve={agreementDetailsFetcher}>
                    {(data: LoaderData) => <AgreementDetailsHeader agreementDetails={data.agreementDetailsResponse} />}
                </Await>
            </Suspense>
            <SideInnhold footerProps={footerProps}>
                <Suspense fallback={<Loader textDescription="Henter forsikringer" />}>
                    <Await resolve={agreementDetailsFetcher} errorElement={<ErrorBoundary />}>
                        {(data: LoaderData) => <AgreementDetails agreementDetails={data.agreementDetailsResponse} />}
                    </Await>
                </Suspense>
            </SideInnhold>
        </Side>
    );
};
