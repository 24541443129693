import { Side, SideHeader, SideInnhold } from "@components/Side";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { useTilgangerTredjePart } from "../queries";
import { TTPNyBedriftsansvarlig } from "../components/TTPNyBedriftsansvarlig";

export const GiTilgangNyBedriftsansvarlig = () => {
    const query = useTilgangerTredjePart();

    return (
        <Side compact>
            <SideHeader title="Ny bedriftsansvarlig" />
            <SideInnhold loadingText="Henter tilganger" query={query} ignoreError>
                {query.error ? (
                    <ErrorMessageBox title="Oi, litt tekniske problemer her">
                        Vi klarer ikke å hente bedrifter og bedriftsansvarlige akkurat nå. Prøv en gang til eller kom
                        tilbake senere.
                    </ErrorMessageBox>
                ) : (
                    <TTPNyBedriftsansvarlig tilganger={query.data!.tilganger} />
                )}
            </SideInnhold>
        </Side>
    );
};
