import { Typography } from "@components/Typography";
import { Trackingkey, track } from "src/tracking";
import "./AgreementCTA.scss";
import { NavCard } from "@fremtind/jkl-card-react";

type AgreementCTAProps = {
    title: string;
    body: string;
    href: string;
    isInternal?: boolean;
};

export const GenericAgreementCta: React.FC<AgreementCTAProps> = ({ title, body, href, isInternal }) => {
    const link: {
        to?: string;
        href?: string;
    } = {};

    if (isInternal) {
        link["to"] = href;
    } else {
        link["href"] = href;
    }
    return (
        <NavCard
            title={title}
            {...link}
            padding="0"
            className="agreement-cta-card--generic agreement-card"
            onClick={() => {
                track({
                    hendelse: Trackingkey.LenkeTrykk,
                    lenkeId: "Flytt bilforskring, inngang fra oversikt, generisk"
                });
            }}
        >
            <Typography className="agreement-cta-card__description" variant="small">
                {body}
            </Typography>
        </NavCard>
    );
};
