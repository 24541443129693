import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { Side, SideHeader, SideInnhold } from "../../../../components/Side";
import { TTPOversikt } from "../../components/TTPOversikt";
import { useTilgangerTredjePart } from "../../queries";

export const TilgangerTredjePart = () => {
    const query = useTilgangerTredjePart();

    return (
        <Side>
            <SideHeader
                title="Tilganger - Andre bedrifter"
                lead="Som administrator kan du styre hvilke bedriftsansvarlige som skal ha tilgang til ulike bedrifter. Tilganger blir automatisk fjernet hvis de ikke brukes i løpet av 13 måneder."
            />
            <SideInnhold
                hideFooter={!query.data}
                compact
                loadingText="Henter tilganger"
                query={query}
                ignoreError
                style={{ marginTop: 0 }}
            >
                {(query.error || query.data?.feilmelding) && (
                    <ErrorMessageBox title="Oi, litt tekniske problemer her" className="jkl-spacing-xl--top">
                        Vi klarer ikke å hente bedrifter og bedriftsansvarlige akkurat nå. Prøv en gang til eller kom
                        tilbake senere.
                    </ErrorMessageBox>
                )}

                {query.data && <TTPOversikt tilganger={query.data.tilganger} />}
            </SideInnhold>
        </Side>
    );
};
