import { useEffect, useMemo, useState } from "react";
import { RadioButton, RadioButtonGroup } from "@fremtind/jkl-radio-button-react";
import { Checkbox } from "@fremtind/jkl-checkbox-react";
import { SecondaryButton, PrimaryButton } from "../../../../components/Button";
import { FieldGroup } from "@fremtind/jkl-input-group-react";
import { useForm } from "react-hook-form";
import { usePutTilganger } from "../../queries";
import { Rolle } from "../../../../model/Rolle";
import { adminRoller, findAdminRolle } from "../../../../common/rolle";
import { Trackingkey } from "../../../../tracking";
import { OrganisasjonTilgang, PersonTilgang, TilgjengeligTilgang } from "../../models";
import "./TilgangerEndre.scss";

const isPerson = (tilgang: PersonTilgang | OrganisasjonTilgang): tilgang is PersonTilgang => {
    return (tilgang as PersonTilgang).person !== undefined;
};

type TilgangType = "administrator" | "endre";

interface Props {
    avbryt: () => void;
    bekreft: (roller: Rolle[]) => void;
    tilgangsObjekt: OrganisasjonTilgang | PersonTilgang;
    tilgjengeligeRoller: TilgjengeligTilgang[];
    mutation: ReturnType<typeof usePutTilganger>;
}

export const TilgangerEndre = ({ avbryt, bekreft, tilgangsObjekt, mutation, tilgjengeligeRoller }: Props) => {
    const { handleSubmit, register, watch, setValue } = useForm({
        defaultValues: {
            roller: tilgangsObjekt.roller
        }
    });

    const brukerRoller = useMemo(
        () =>
            tilgjengeligeRoller
                .filter(({ rolle }) => !adminRoller.includes(rolle))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [tilgjengeligeRoller]
    );

    const adminRolle = findAdminRolle(tilgjengeligeRoller.map(({ rolle }) => rolle));

    const [tilgangType, setTilgangType] = useState(
        tilgangsObjekt.roller.includes("ADMINISTRATOR") ||
            tilgangsObjekt.roller.includes("ADMINISTRATOR_DNB") ||
            tilgangsObjekt.roller.includes("Administrator")
            ? "administrator"
            : "endre"
    );

    const [rollerInvalid, setRollerInvalid] = useState(false);

    const rollerValue = watch("roller");

    useEffect(() => {
        if (rollerValue?.length) {
            setRollerInvalid(false);
        }
    }, [rollerValue]);

    // automatisk velg rolle om det bare er én tilgjengelig
    useEffect(() => {
        if (tilgangType === "endre" && brukerRoller.length === 1) {
            setValue("roller", [brukerRoller[0].rolle]);
        }
    }, [tilgangType, brukerRoller, setValue]);

    const onSubmit = (formValues: { roller: Rolle[] }) => {
        if (tilgangType === "endre" && !formValues.roller.filter((r) => !adminRoller.includes(r)).length) {
            setRollerInvalid(true);
            return;
        }

        if (formValues.roller && !Array.isArray(formValues.roller)) {
            formValues.roller = [formValues.roller];
        }

        bekreft(tilgangType === "administrator" && adminRolle ? [adminRolle] : formValues.roller);
    };

    return (
        <form className="tilgang-kort__content--endre" onSubmit={handleSubmit(onSubmit)}>
            <RadioButtonGroup
                legend={
                    isPerson(tilgangsObjekt)
                        ? `Hvilke tilganger skal ${tilgangsObjekt.person.fornavn} ${tilgangsObjekt.person.etternavn} ha tilgang til?`
                        : `Hvilke tilganger skal ${tilgangsObjekt.organisasjon.navn} ha?`
                }
                onChange={(e) => setTilgangType(e.target.value as TilgangType)}
                value={tilgangType}
                density="compact"
                labelProps={{ srOnly: true }}
            >
                {isPerson(tilgangsObjekt) && adminRolle && (
                    <div className="tilgang-kort__endre-form-field">
                        <RadioButton
                            label="Administrator"
                            data-testid="gi-tilgang-radio-administrator"
                            aria-label="Administrator"
                            name="tilgang"
                            value="administrator"
                            aria-describedby="administrator-help-text"
                        />
                        <p id="administrator-help-text" className="jkl-small radio-label">
                            Administratorer kan se og endre alle forsikringer og er de eneste som kan gi tilganger og
                            samtykke på vegne av bedriften.
                        </p>
                    </div>
                )}

                <div className="tilgang-kort__endre-form-field">
                    {isPerson(tilgangsObjekt) && (
                        <RadioButton
                            label="Kun se og endre forsikringer"
                            data-testid="gi-tilgang-radio-endre"
                            aria-label="Kun se og endre forsikringer"
                            name="tilgang"
                            value="endre"
                            aria-describedby="endre-help-text"
                        />
                    )}
                    <p id="endre-help-text" className="jkl-small radio-label jkl-spacing-m--bottom">
                        {isPerson(tilgangsObjekt)
                            ? `Velg hvilke forsikringstyper ${tilgangsObjekt.person.fornavn} ${tilgangsObjekt.person.etternavn} skal ha tilgang til.`
                            : `Velg forsikringstyper`}
                    </p>

                    {tilgangType === "endre" && (
                        <FieldGroup
                            legend={
                                isPerson(tilgangsObjekt)
                                    ? `Velg hvilke forsikringstyper ${tilgangsObjekt.person.fornavn} ${tilgangsObjekt.person.etternavn} skal ha tilgang til.`
                                    : `Velg forsikringstyper`
                            }
                            labelProps={{ srOnly: true }}
                            errorLabel={rollerInvalid ? "Du må velge forsikringstype" : undefined}
                        >
                            {brukerRoller.map(({ rolle, label }) => (
                                <Checkbox
                                    key={rolle}
                                    invalid={rollerInvalid}
                                    {...register("roller")}
                                    density="compact"
                                    value={rolle}
                                >
                                    {label}
                                </Checkbox>
                            ))}
                        </FieldGroup>
                    )}
                </div>

                <footer className="tilgang-kort__endre-form-field tilgang-kort__endre-form-field--footer">
                    <SecondaryButton
                        onClick={avbryt}
                        type="button"
                        density="compact"
                        className="jkl-spacing-m--right"
                        data-testid="tilganger-endre-avbryt"
                        dataTestautoid="tilganger-endre-avbryt"
                        track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "endre-tilgang-avbryt" }}
                    >
                        Avbryt
                    </SecondaryButton>
                    <PrimaryButton
                        data-testid="tilganger-endre-lagre"
                        dataTestautoid="tilganger-endre-lagre"
                        track={[
                            { hendelse: Trackingkey.Knappetrykk, knappeId: "endre-tilgang-lagre" },
                            {
                                hendelse: Trackingkey.Tilgang,
                                handling: "endre",
                                tilgang: isPerson(tilgangsObjekt) ? "person" : "organisasjon"
                            }
                        ]}
                        loader={{ showLoader: mutation.isPending, textDescription: "Endrer tilgang" }}
                        density="compact"
                    >
                        Lagre
                    </PrimaryButton>
                </footer>
            </RadioButtonGroup>
        </form>
    );
};
