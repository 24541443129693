import React, { useState } from "react";

import { useScreen } from "@fremtind/jkl-react-hooks";
import { CopyIcon } from "@fremtind/jkl-icons-react";
import { handleCopyToClipboard } from "src/common/utils";
import { Trackingkey, track } from "src/tracking";

export const CopyToClipboard = ({
    content,
    children,
    actionLabel
}: {
    content: string | undefined;
    children: React.ReactNode;
    actionLabel: string;
}) => {
    const [showCopiedText, setShowCopiedText] = useState(false);

    const { isSmallDevice } = useScreen();

    if (!content) return null;

    return (
        <>
            <button
                className={`bm-invoice-details__header__copy-to-clipboard`}
                data-testid="copy-to-clipboard"
                onClick={() =>
                    handleCopyToClipboard(content, () => {
                        track({
                            hendelse: Trackingkey.KopierKnapp,
                            tekst: content,
                            knappeId: actionLabel,
                            side: window.location.pathname
                        });
                        setShowCopiedText(true);

                        setTimeout(() => {
                            setShowCopiedText(false);
                        }, 1500);
                    })
                }
                title="Kopier til utklippstavle"
            >
                {isSmallDevice && actionLabel && (
                    <span className="bm-invoice-details__header__copy-to-clipboard__small-device-label">
                        {actionLabel}
                    </span>
                )}
                <span className="bm-invoice-details__header__copy-to-clipboard__content">
                    {children}
                    {showCopiedText ? (
                        <span className="bm-invoice-details__header__copy-to-clipboard--copied-text">Kopiert!</span>
                    ) : (
                        <>
                            <CopyIcon />
                            <span className="jkl-sr-only">Kopier til utklippstavle</span>
                        </>
                    )}
                </span>
            </button>
        </>
    );
};
