import {
    SkeletonAnimation,
    SkeletonElement,
    SkeletonTable,
    SkeletonTableHeader,
    SkeletonTableRow
} from "@fremtind/jkl-loader-react";

export const InvoiceOverviewLoader = () => {
    return (
        <SkeletonAnimation>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", gap: "60px", flexWrap: "wrap" }} className="jkl-spacing-104--bottom">
                    <div>
                        <SkeletonElement width={184} height={25} className="jkl-spacing-16--bottom" />
                        <SkeletonElement width={210} height={20} className="jkl-spacing-32--bottom" />
                        <SkeletonElement width={400} height={108} className="jkl-spacing-24--bottom" />
                        <SkeletonElement width={400} height={108} className="jkl-spacing-24--bottom" />
                    </div>
                    <div>
                        <SkeletonElement width={184} height={25} className="jkl-spacing-40--bottom" />
                        <SkeletonTable>
                            {Array.from(Array(4)).map((_, index) => (
                                <SkeletonTableRow key={index}>
                                    <div style={{ width: 590 }}>
                                        <SkeletonElement
                                            width={580}
                                            height={19}
                                            className="jkl-spacing-4--top jkl-spacing-4--bottom"
                                        />
                                    </div>
                                </SkeletonTableRow>
                            ))}
                        </SkeletonTable>
                    </div>
                </div>

                <SkeletonElement width={184} height={25} className="jkl-spacing-24--bottom" />
                <div style={{ display: "flex", gap: "16px" }} className="jkl-spacing-40--bottom">
                    <div>
                        <SkeletonElement width={95} height={20} className="jkl-spacing-8--bottom" />
                        <SkeletonElement width={193} height={40} />
                    </div>
                    <div>
                        <SkeletonElement width={95} height={20} className="jkl-spacing-8--bottom" />
                        <SkeletonElement width={193} height={40} />
                    </div>
                </div>

                <SkeletonTable>
                    <SkeletonTableHeader>
                        <div style={{ width: 215 }}>
                            <SkeletonElement width={50} height={16} />
                        </div>
                        <div style={{ width: 215 }}>
                            <SkeletonElement width={50} height={16} />
                        </div>
                        <div style={{ width: 215 }}>
                            <SkeletonElement width={50} height={16} />
                        </div>
                        <div style={{ width: 215 }}>
                            <SkeletonElement width={50} height={16} />
                        </div>
                    </SkeletonTableHeader>
                    {Array.from(Array(5)).map((_, index) => {
                        const width = index % 2 ? 100 : 150;
                        return (
                            <SkeletonTableRow key={index}>
                                <div style={{ width: 215 }}>
                                    <SkeletonElement width={width} height={19} />
                                </div>
                                <div style={{ width: 215 }}>
                                    <SkeletonElement width={width} height={19} />
                                </div>
                                <div style={{ width: 215 }}>
                                    <SkeletonElement width={width} height={19} />
                                </div>
                                <div style={{ width: 215 }}>
                                    <SkeletonElement width={width} height={19} />
                                </div>
                            </SkeletonTableRow>
                        );
                    })}
                </SkeletonTable>
            </div>
        </SkeletonAnimation>
    );
};
