import { useFormContext } from "react-hook-form";
import { track, Trackingkey } from "src/tracking";

import { utils } from "@features/navneliste";
import { CrossListItem, List } from "@fremtind/jkl-list-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { SecondaryLinkButton } from "../../../../components/Button";
import { Kvittering } from "../../../../components/Kvittering";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { Typography } from "../../../../components/Typography";
import { FormState, StegProps } from "./";

interface ReceiptProps extends StegProps {
    oversiktLink: string;
}

export function Receipt({ mutation, ...props }: ReceiptProps) {
    const form = useFormContext<FormState>();

    const insuredName = form.getValues().utmeldtInsuredName;

    const [utmeldteAvtaler, ikkeUtmeldteAvtaler] = utils.partitionEndredeAvtaler(
        mutation.data?.entity.endringer?.[0].avtaler ?? []
    );

    return (
        <div>
            {utmeldteAvtaler.length > 0 && (
                <>
                    <Kvittering
                        heading={`Flott! ${insuredName} ble utmeldt fra:`}
                        positives={utmeldteAvtaler.map((avtale) => avtale.avtaleNavn)}
                    />

                    <Typography className="jkl-spacing-xl--top jkl-spacing-l--bottom">
                        Noen ganger tar det litt tid før utmeldingen blir synlig i oversikten.
                    </Typography>
                </>
            )}

            {ikkeUtmeldteAvtaler.length > 0 && (
                <ErrorMessageBox
                    className="jkl-spacing-xl--bottom jkl-spacing-2xl-top"
                    title="En feil har skjedd"
                    data-testid="bm-utmelding-receipt-error"
                >
                    Beklager, det har skjedd en feil hos oss. {insuredName} ble ikke meldt ut av:
                    <List>
                        {ikkeUtmeldteAvtaler.map((avtale) => (
                            <CrossListItem key={avtale.avtaleNavn}>{avtale.avtaleNavn}</CrossListItem>
                        ))}
                    </List>
                    Prøv igjen senere eller kontakt oss på telefon.
                </ErrorMessageBox>
            )}

            <SkjemaFooter>
                <SecondaryLinkButton
                    to={props.oversiktLink}
                    data-testautoid="meld-ut-knapp-oppsummering-til-oversikt"
                    onClick={() =>
                        utmeldteAvtaler.map((avtale) =>
                            track({
                                hendelse: Trackingkey.Endre,
                                handling: "ferdig",
                                type: "meld ut ansatte",
                                avtale: avtale.avtaleNavn
                            })
                        )
                    }
                >
                    Ferdig
                </SecondaryLinkButton>
            </SkjemaFooter>
        </div>
    );
}
