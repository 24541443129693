import Cookie from "js-cookie";
import { Method } from "../model/http.typer";
import packageJson from "../../package.json";
import { getFeilmelding } from "./utils";

export const API_ROOT = packageJson.homepage + "/api/";

const apiFetcher = async <responsetype>(request: Request): Promise<responsetype> => {
    const res = await fetch(request);
    const contentType = res.headers.get("content-type");

    if (!res.ok) {
        if (contentType?.includes("application/json")) {
            const data = await res.json();
            throw { status: res.status, ok: false, body: data };
        } else {
            const resText = await res.text();
            throw {
                status: res.status,
                ok: false,
                body: resText
            };
        }
    }

    if (contentType?.includes("application/json")) {
        const d: any = await res.json();
        if (d.httpResponseStatus && d.requestId) {
            throw {
                status: res.status,
                ok: false,
                timestamp: Date.now(),
                body: d
            };
        } else {
            return Promise.resolve(d);
        }
    } else {
        return Promise.resolve(undefined as unknown as responsetype); // En respons kan være OK uten data
    }
};

export const httpGet = async <T>(path: string, args: RequestInit = { method: Method.GET }): Promise<T> => {
    // return apiFetcher<T>(new Request(API_ROOT + path, args));

    const res = await fetch(API_ROOT + path, args);
    const contentType = res.headers.get("content-type");

    if (!res.ok) {
        if (contentType?.includes("application/json")) {
            const data = await res.json();
            throw { status: res.status, ok: false, body: data };
        } else {
            const resText = await res.text();
            throw {
                status: res.status,
                ok: false,
                body: resText
            };
        }
    }

    if (contentType?.includes("application/json")) {
        const d: any = await res.json();
        if (d.httpResponseStatus && d.requestId) {
            throw {
                status: res.status,
                ok: false,
                timestamp: Date.now(),
                body: d
            };
        } else {
            return Promise.resolve(d);
        }
    } else {
        return Promise.resolve(undefined as unknown as T); // En respons kan være OK uten data
    }
};

export const httpPost = async <T>(
    path: string,
    body?: string,
    args: RequestInit = { method: Method.POST, body }
): Promise<T> => {
    const xsrfToken = Cookie.get("XSRF-TOKEN");
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": String(xsrfToken)
    };

    /* return apiFetcher<T>(
        new Request(API_ROOT + path, {
            ...args,
            headers,
            credentials: "include"
        })
    );*/

    const res = await fetch(API_ROOT + path, {
        ...args,
        headers,
        credentials: "include"
    });

    const contentType = res.headers.get("content-type");

    if (!res.ok) {
        if (contentType?.includes("application/json")) {
            const data = await res.json();
            throw { status: res.status, ok: false, body: data };
        } else {
            const resText = await res.text();
            throw {
                status: res.status,
                ok: false,
                body: resText
            };
        }
    }

    if (contentType?.includes("application/json")) {
        const d: any = await res.json();
        if (d.httpResponseStatus && d.requestId) {
            throw {
                status: res.status,
                ok: false,
                timestamp: Date.now(),
                body: d
            };
        } else {
            return Promise.resolve(d);
        }
    } else {
        return Promise.resolve(undefined as unknown as T); // En respons kan være OK uten data
    }
};

export const httpDelete = <T>(path: string, args: RequestInit = {}): Promise<T> => {
    const finalArgs = { ...args, method: Method.DELETE };
    const xsrfToken = Cookie.get("XSRF-TOKEN");
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": String(xsrfToken)
    };
    return apiFetcher<T>(
        new Request(API_ROOT + path, {
            ...finalArgs,
            headers,
            credentials: "include"
        })
    );
};

export const httpPut = <T>(path: string, body?: any, args: RequestInit = { method: Method.PUT, body }): Promise<T> => {
    const xsrfToken = Cookie.get("XSRF-TOKEN");
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": String(xsrfToken)
    };

    return apiFetcher<T>(
        new Request(API_ROOT + path, {
            ...args,
            headers,
            credentials: "include"
        })
    );
};

export interface APIResponse<T> {
    entity: T;
    feilkoder: string[];
}

export class Retriever {
    static async get<T>(endpoint: string) {
        const { entity, feilkoder } = await httpGet<APIResponse<T>>(endpoint);

        let feilmelding;

        if (feilkoder.length) {
            feilmelding = getFeilmelding(feilkoder[0]);
        }

        return { entity, feilmelding };
    }
}
