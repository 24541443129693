import { useMemo } from "react";
import { useBruker } from "../../../features/bruker/queries";
import "./IngenBedriftFeil.scss";

export function IngenBedriftFeil() {
    const { bruker, valgtOrganisasjon } = useBruker();

    const message = useMemo(
        () =>
            bruker?.antallOrganisasjoner !== undefined
                ? "Velg bedrift i menyen"
                : "Du har ikke tilgang til noen organisasjoner",
        [bruker]
    );
    return bruker && !valgtOrganisasjon ? <p className="ingen-bedrift-feil">{message}</p> : null;
}
